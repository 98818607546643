import React, { Component } from "react";
import YouTube from "react-youtube";
import { connect } from "react-redux";

class IntroVideo extends Component {
  componentDidMount = () => {
    localStorage.setItem("playIntro", true);
  };
  playVideo = () => {
    this.videoObj.playVideo();
  };
  onReady = (e) => {
    //alert('ready');
    this.videoObj = e.target;
    this.playVideo();
  };
  onClickVideo = () => {
    this.setState({ hideBanner: true });
    this.videoObj.playVideo();
    // console.log(this.videoObj)
    // this.videoObj.playVideo();
  };
  pauseVideo = async () => {
    this.videoObj.pauseVideo();
    //this.setState({ hideBanner: false });
  };
  onEndVideo = () => {
    console.log("on End Video");
    return this.props.history.push("/Index");
  };
  render() {
    const opts = {
      // height: '390',
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        playsinline: 1,
        autoplay: 1,
        controls: 1,
        rel: 0,
        modestbranding: 0,
        showinfo: 0,
        autohide: 1,
        enablejsapi: 1,
        html5: 1,
        ecver: 2,
        allowfullscreen: 1,
      },
    };
    return (
      <div className="videoIntro">
        <YouTube
          opts={opts}
          videoId={"2FheFp7i6KM"}
          onReady={this.onReady}
          onPause={this.pauseVideo}
          onEnd={this.onEndVideo}
          onPlay={this.onClickVideo}
        />
      </div>
    );
  }
}
export default connect(null, {})(IntroVideo);
