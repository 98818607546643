import React, { Component } from "react";
import { Link } from "react-router-dom";

class NameworkVideo extends Component {
  render() {
    var { name, reps, sets, isPlaying, currentIndex, onDemo } = this.props;
    return (
      <div className="exercise_name">
        <div className="name_workout fadeIn">
          <p>{name}</p>
          <span>{reps}</span>
          {onDemo ? null : <span>{sets} {sets > 1 ? "Sets" : "Set"}</span>}
        </div>
        <div className="button btnworkouts">
          <button type="button" onClick={this.props.onClick}>
            START
          </button>

          {currentIndex > 0 ? (
            <Link to="#" onClick={this.props.onBack}>
              Back
            </Link>
          ) : null}
        </div>
      </div>
    );
  }
}

export default NameworkVideo;
