import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { nextExercise, countProgress } from "../../store/actions/Exercise";
import ModalwithTimer from "../Modal/ModalwithTimer";
import Moment from "moment";
import Resttime from "../Resttime";
import ProgressBar from "../ProgressBar";
class ExerciseTimer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      modalShow: false,
      seconds: 9,
      isCounting: false,
      progressCircle: 295,
      interval: null,
      timerInterval: null,
      steps: 0,
      isDone: false
    };
  }

  componentDidMount = async () => {
    let { exerciseReps, index, videos } = this.props;
    index = exerciseReps.currentIndex + 1;
    await this.props.nextExercise(index);
    let progressStatus = await this.countLength(index, videos.length);
    await this.props.countProgress(progressStatus);
    let { steps, progressCircle, seconds } = this.state;
    steps = progressCircle / seconds;
    this.setState({ steps: steps });
    let { percent } = this.state;
    percent = (1 / videos.length) * 100;
    this.setState({ percent: percent });
  };
  modalClose = () => this.setState({ modalShow: false });

  startTimer = () => {
    let { isCounting, interval, isDone, timerInterval } = this.state;
    this.setState({ isCounting: !isCounting });
    if (!isCounting) {
      interval = setInterval(this.countDown, 40);
      this.setState({ interval });
    } else {
      isDone ? this.onDone() : clearInterval(interval);
    }
  };

  countDown = () => {
    let { seconds, steps, progressCircle } = this.state;
    if (seconds > 0) {
      seconds = seconds - 1 / 25;
      seconds = seconds >= 0 ? seconds : 0;
      progressCircle -= steps / 25;
      this.setState({ seconds, progressCircle: progressCircle });
    } else {
      this.setState({ isDone: true });
    }
  };
  countTimer = () => {
    let { seconds, progressCircle, steps } = this.state;
    if (seconds > 0) {
      progressCircle -= steps / 50;
      this.setState({ progressCircle });
    } else {
      this.setState({ isDone: true });
    }
  };
  onDone = () => {
    this.onClick();
  };
  onSkip = () => {
    this.onClick();
  };
  onClick = () => {
    let { exerciseReps } = this.props;
    this.props.history.push(`/video/${exerciseReps.workoutId}`);
  };
  countLength = (index, length) => {
    if (index <= length) {
      return Math.floor((index / length) * 100);
    }
  };
  render() {
    const { exerciseReps } = this.props;
    const { seconds, isCounting, progressCircle, isDone } = this.state;
    const { progress, videos } = this.props;
    const { percent } = this.state;
    return (
      <div className="exerciseTimer">
        <div className="progress prgess-complete">
          {videos.length > 0
            ? videos.map((v, i) => {
                return (
                  <Fragment key={i}>
                    <ProgressBar i={i} percent={percent} progress={progress} />
                  </Fragment>
                );
              })
            : null}
        </div>
        <div className="timerCount">
          <svg viewBox="0 0 100 100">
            <defs>
              <pattern
                id="pattern"
                patternUnits="userSpaceOnUse"
                width="100"
                height="100"
              >
                <image
                  href={require("../../images/bg_gradient.jpg")}
                  x="0"
                  y="0"
                  width="100"
                  height="100"
                />
              </pattern>
            </defs>
            <path
              className="circleLine"
              d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
              stroke="#eee"
              strokeWidth="1"
              fillOpacity="0"
            />
            <path
              className="circleGradient"
              d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
              // stroke="url(#pattern)"
              strokeDasharray="295"
              strokeDashoffset={progressCircle > 0 ? progressCircle : 0}
            />
          </svg>
          <span className="timeExercise">
            {Moment.utc(seconds * 1000).format("mm:ss")}
          </span>
        </div>
        <div className="exercise_name">
          {/* <div className="name_workout fadeIn">
            <p>Bodyweight Air Squats </p>
            <span>10-12 Reps </span>
            <span>3 Sets</span>
            <div className="desc">
              Lorem ipsum dolor sit amet, tempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus
              commodo viverra maecenas accumsan lacus vel facilisis.
            </div>
          </div>  */}
          <div className="name_workout fadeIn">
            <p> {exerciseReps.name} </p>
            <span> {exerciseReps.reps} </span>
            <span> {exerciseReps.sets} Sets</span>
            <div className="desc">{exerciseReps.description}</div>
          </div>
        </div>
        <div className="button">
          <button onClick={this.startTimer} type="button">
            {isDone ? "DONE" : isCounting ? "STOP" : "START"}
          </button>
          {/* seconds <= 0.? null : <Link to="#" onClick={() => this.setState({ modalShow: true })}>
            SKIP
          </Link> */}
          <Link
            to="#"
            className={seconds <= 0.7 ? "hiddenSkip" : ""}
            onClick={() => this.setState({ modalShow: true })}
          >
            SKIP
          </Link>
        </div>
        <ModalwithTimer
          show={this.state.modalShow}
          backdrop="static"
          onHide={this.modalClose}
          onSkip={this.onSkip}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.exerciseReducer,
    ...state.videosReducer
  };
};
export default connect(
  mapStateToProps,
  { nextExercise, countProgress }
)(ExerciseTimer);
