import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllAssessment } from "../../store/actions/Profile";
import ReadMoreAndLess from "react-read-more-less";
import ModalAssessmentListDetail from "./ModalAssessmentListDetail";
import Moment from "moment";
class ModalAssessmentItem extends Component {
  state = {
    modalShowListAssessmentDetail: false
  };
  modalCloseListAssessmentDetail = () =>
    this.setState({ modalShowListAssessmentDetail: false });
  onClick = () => {
    console.log(".....");
  };
  render() {
    const {
      message,
      reply,
      date,
      beforePicture,
      afterPicture,
      profile_picture
    } = this.props;
    let maxLength = 120;
    let maxMessage;
    if (message.length > maxLength) {
      maxMessage = message.substring(0, maxLength) + "...";
    } else {
      maxMessage = message;
    }

    //alert(maxMessage)
    return (
      <Fragment>
        <li>
          <Link
            onClick={() =>
              this.setState({ modalShowListAssessmentDetail: true })
            }
          >
            <div>
              <p>
                {Moment(date).format("DD MMMM YYYY")}{" "}
                {reply ? null : <span>Waiting</span>}
              </p>
              {maxMessage}
            </div>
            <div className="arrow">
              <img src={require("../../images/ic_arrow.svg")} alt="" />
            </div>
          </Link>
        </li>
        <ModalAssessmentListDetail
          show={this.state.modalShowListAssessmentDetail}
          backdrop="static"
          onHide={this.modalCloseListAssessmentDetail}
          message={message}
          reply={reply}
          date={date}
          beforePicture={beforePicture}
          afterPicture={afterPicture}
          profile_picture={profile_picture}
        />
      </Fragment>
    );
  }
}
export default ModalAssessmentItem;
