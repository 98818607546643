import React, { Component } from "react";

class DaysSessions extends Component {
  render() {
    var {
      img,
      name,
      days,
      months,
      txtnamemoths,
      txtnamedays,
      altDay
    } = this.props;
    return (
      <div className="box-info-days-sessions">
        <div className="info-days">
          <div className="icon-days">
            <img src={require("../images/" + img)} alt={altDay} />
          </div>
          <div className="name">{name}</div>
          <div className="days">
            <span>{months}</span>
            {txtnamemoths}
          </div>
          <div className="days">
            <span>{days}</span>
            {txtnamedays}
          </div>
        </div>
      </div>
    );
  }
}

export default DaysSessions;
