import * as actionTypes from "../actions/actionTypes";
import { API } from "../../config/config";
import { headers, uploadHeaders } from "../../ultils/helpers";

export const getAllEvents = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_EVENTS_API, {
      method: "GET",
      headers: headers
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: actionTypes.GET_ALL_EVENTS,
          data: data
        })
        resolve(data)
      })
      .catch(err => reject(err))
  })

}