import React, { Component } from "react";
import Back from "../Button/Back";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ListWorkoutContent from "../Workout/ListWorkoutContent";
import { getChallengeWorkoutExercises } from "../../store/actions/Challenge";
import { getStartTime } from "../../store/actions/Diary";
import { sendMessage } from "../../ultils/ultils";
import {
  MAXIMUM_TARGET_WORKOUT_ALLOWED,
  MENU,
  WEBVIEW_ACTION,
} from "../../config/config";
import Moment from "moment";
class ChallengeExercise extends Component {
  state = {
    workoutExercises: [],
  };
  backToWorkout = () => {
    console.log(this.props.location.state);
    this.props.history.push({
      pathname: `/ChallengeWorkout`,
      state: this.props.location.state,
    });
    // const { workOutList } = this.props;
    // workOutList
    //   ? this.props.history.push("/workout")
    //   : this.props.history.push("/index");
  };
  goToVideos = () => {
    this.props.history.push("/video");
  };

  getStartTime = async () => {
    const { id } = this.props.match.params;
    const { workoutItem, week } = this.props.location.state;
    let startTime = Moment().format("YYYY-MM-DD HH:mm:ss");
    this.props.getStartTime(startTime, workoutItem.name, week);
    sendMessage({ type: WEBVIEW_ACTION.KEEP_AWAKE, status: true });
    this.props.history.push({
      pathname: `/Video/${id}`,
    });
  };
  componentDidMount = async () => {
    console.log(this.props.location.state);
    const { id } = this.props.match.params;
    const workoutExercises = await this.props.getChallengeWorkoutExercises(id);
    this.setState({ workoutExercises: workoutExercises });
    console.log({ workoutExercises });
  };
  render() {
    const { workoutItem, week } = this.props.location.state;
    const { workoutExercises } = this.state;
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <Back onClick={this.backToWorkout} />
          <h4>{week >= 1 ? `Week ${week}` : `FREE Trial Week`}</h4>

          <a className="icon" />
        </div>
        <div className="page-content" style={{ paddingBottom: 55 }}>
          <div className="list-workout" style={{ marginTop: 0 }}>
            <h3 className="title-main" style={{ marginBottom: 4 }}>
              {workoutItem.name}
            </h3>
            <p className="duration">
              {" "}
              <span>Duration</span>
              {workoutItem.duration} Min
            </p>

            <ul>
              {workoutExercises
                ? workoutExercises.map((item) => (
                    <ListWorkoutContent
                      key={item.index}
                      images="w1.jpg"
                      workoutName={item.name}
                      id={item.id}
                      reps={item.reps}
                      sets={item.sets}
                      img={item.gif}
                      workoutItem={item}
                    />
                  ))
                : null}
            </ul>
          </div>
        </div>
        <div className="letgo-button">
          <div className="button">
            <button to="#" onClick={this.getStartTime} className="button-w220">
              LET'S GO!
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  { getChallengeWorkoutExercises, getStartTime }
)(ChallengeExercise);
