import React, { Component } from "react";
import { Link } from "react-router-dom";
import Back from "../Button/Back";
import YoutubeWorkout from "../Workout/YoutubeWorkout";
import { connect } from "react-redux";
class Challenge extends Component {
  onStartButtonClick = () => {
    this.props.history.push(`/ChallengeProgramme`);
  };
  backToChallenges = () => {
    this.props.history.push("/Challenges");
  };
  render() {
    const { Challenges, SelectedChallenge } = this.props;
    const item = Challenges.find((m) => m.id == SelectedChallenge);
    console.log(this.props);
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <Back onClick={this.backToChallenges} />
          <h4>{item.name}</h4>
          <a className="icon" />
        </div>
        <div className="page-content page-content-workout">
          <div className="WorkoutChallenage">
            <img src={item.thumb} />
            {/* <p>{item.name}</p> */}
            <YoutubeWorkout videoId={item.youtubeId} />
            <div className="button_2">
              <button type="button" onClick={this.onStartButtonClick}>
                START
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.challenge,
  };
};
export default connect(
  mapStateToProps,
  {}
)(Challenge);
