import { API } from "../../config/config";
import { headers } from "../../ultils/helpers";
import * as actionTypes from "./actionTypes";

export const getVideo = (
  id,
  isFullBodyWorkout = false,
  isCustomWorkout = false
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    !isFullBodyWorkout
      ? fetch(API.GET_WORKOUT_VIDEO_API, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ id: id }),
        })
          .then((res) => res.json())
          .then((data) => {
            dispatch({
              type: actionTypes.GET_VIDEOS,
              data: data,
            });
            resolve(data);
          })
          .catch((err) => reject(err))
      : fetch(API.GET_FULL_BODY_WORKOUT_EXERCISE_BY_CATEGORY_API, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            categoryId: id,
            isCustomWorkout: isCustomWorkout,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            dispatch({
              type: actionTypes.GET_VIDEOS,
              data: data,
            });
            resolve(data);
          })
          .catch((err) => reject(err));
  });
};

export const setDemoWorkoutURL = (url) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_DEMOWORKOUT_URL,
    data: url,
  });
};

export const getVideoExerciseById = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_VIDEO_EXERCISE_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ id }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
