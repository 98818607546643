import React, { Component } from "react";
import Back from "../Button/Back";
import YoutubeWorkout from "../Workout/YoutubeWorkout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ChallengeSliderWorkout from "./ChallengeSliderWorkout";
class ChallengeWorkout extends Component {
  backToChallengeProgramme = () => {
    console.log({ state: this.props.location.state });
    this.props.history.push({
      pathname: "/ChallengeProgramme",
      state: this.props.location.state,
    });
  };
  render() {
    const { week, workoutList, message } = this.props.location.state;
    const videoId = "";
    console.log({ week, workoutList });
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <Back onClick={this.backToChallengeProgramme} />
          <h4>Week {week}</h4>

          <a className="icon" />
        </div>
        <div className="page-content page-content-workout">
          {message ? <YoutubeWorkout videoId={message.video} /> : null}
          <div className="width_workout_slider">
            <h3 className="title-main">Programme</h3>
            <ChallengeSliderWorkout workoutList={workoutList} week={week} />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  null
)(ChallengeWorkout);
