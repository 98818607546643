import React, { Component } from 'react'

export class Thanks extends Component {
  render() {
    return (
      <div className="page thanks_page fadeIn">

        <img src={require("../images/thanks.svg")} alt="like" />
        <div className="title_thanks">
          {/* <h3>
            Thank you <span>for signing up!  </span>
          </h3>
          <p>Time to kickstart  your fitness journey </p> */}
          <p><span>You’re a step closer to kickstarting <br />your fitness journey!</span></p>
        </div>
        <div class="button_2"><button type="button">START</button></div>
      </div>
    )
  }
}

export default Thanks
