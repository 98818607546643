import React, { Component } from "react";

export class Player extends Component {
  render() {
    var { img, altplayer } = this.props;
    return (
      <div>
        <a className={this.props.className}>
          <img
            src={require("../images/" + img)}
            alt={altplayer}
            onClick={this.props.onClick}
          />
        </a>
      </div>
    );
  }
}

export default Player;
