import React, { Component } from "react";
import { Link } from "react-router-dom";
import Back from "./Button/Back";
import { connect } from "react-redux";
import { showProfile, updateProfile } from "../store/actions/Profile";
import { MENU, WEBVIEW_ACTION } from "../config/config";
import UserInfo from "./UserInfo/UserInfo";
import { TYPES, ERRORS } from "../config/config";
import DateTime from "react-datetime";
import Moment from "moment";
import { sendMessage } from "../ultils/ultils";
export class EditProfile extends Component {
  state = {
    username: "",
    date: "",
    gender: null,
    usernameError: false,
    dateError: false,
    password: null,
    email: null
  };
  onClick = () => {
    let showProfile = true;
    this.props.showProfile(showProfile);
    this.props.history.push("/index");
  };
  componentDidMount() {
    const { username, date, gender, password, email } = this.props.user;
    this.setState({
      username: username,
      date: date,
      gender: gender,
      password: password,
      email: email
    });
  }
  onChange = e => {
    this.setState({ [e.target.dataset.id]: e.target.value });
  };
  onChangeDate = e => {
    let date = e.format("DD MMM YYYY");
    this.setState({ date: date });
  };
  isValid = date => {
    let validDate = Moment().subtract(0, "years");
    let dob = Moment(date);
    if (validDate.isAfter(dob)) {
      return true;
    } else {
      return false;
    }
  };
  autoLogin = (email, password) => {
    const data = {type: WEBVIEW_ACTION.AUTO_LOGIN,
      email: email,
      password: password}
    sendMessage(data)
  };
  onSubmit = async () => {
    const { username, gender, date, email, password } = this.state;

    const isValid = this.isValid(date);
    if (username.length > 3 && isValid) {
      let userProfile = {
        username: username,
        date: date,
        gender: gender
      };
      let result = await this.props.updateProfile(userProfile);
      if (result) {
        this.autoLogin(email, password);
      }
    } else {
      username.length < 5
        ? this.setState({ usernameError: true })
        : this.setState({ dateError: true });
    }
  };
  render() {
    let { username, date, gender } = this.state;
    return (
      <div>
        <div className="page pageFeedBack fadeIn">
          <div className="page-header">
            <Back onClick={this.onClick} />
            <h4>Edit Profile</h4>
            <Link to="" className="icon" />
          </div>
          <div className="colm_costume colm_info_user">
            <form className="box_user">
              <div className="form-group">
                <label>Your Name </label>
                <input
                  value={username}
                  type="text"
                  onChange={e => this.onChange(e)}
                  data-id="username"
                />
              </div>
              <div className="form-group">
                <label>DOB</label>
                <DateTime
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{ readOnly: true }}
                  value={date}
                  onChange={e => this.onChangeDate(e)}
                />
              </div>
              <div className="form-group select_custom">
                <label>Gender</label>
                <select
                  required
                  data-id="gender"
                  onChange={e => this.onChange(e)}
                >
                  {gender === "M" ? (
                    <option selected value="M">
                      Male
                    </option>
                  ) : (
                    <option value="M">Male</option>
                  )}
                  {gender === "F" ? (
                    <option selected value="F">
                      Female
                    </option>
                  ) : (
                    <option value="F">Female</option>
                  )}
                </select>
              </div>
            </form>
            <div className="button">
              <button onClick={this.onSubmit} type="button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.userInfo
  };
};
export default connect(
  mapStateToProps,
  { showProfile, updateProfile }
)(EditProfile);
