import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Mini_bang_bang_chicken_salad extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Snacks
        </div>

        <div className="recipesMain">
          <div className="image">
            <img
              src={require("../../../images/recipes/snacks/02.jpg")}
              alt=""
            />
          </div>
          <p>
            Mini Bang Bang Chicken Salad
            <span> Serves: 2-3 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            400g chicken breast (approx 2 pieces)
            <br />
            2 Japanese cucumbers (approx 2 cucumbers)
            <br />
            Sesame seeds
            <br />
            Fresh coriander
            <br />
            <h3 className="ttchild">For the sauce</h3>
            0.5 tbsp sesame oil
            <br />
            1 tbsp soy sauce
            <br />
            1 tbsp chilli sauce (or chilli oil)
            <br />
            1.5 tsp Szechuan peppercorn powder
            <br />
            1.5 tsp chilli flakes
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>Boil the chicken breasts for 12 minutes, turn off
                the heat and let it sit in the hot water for 5 minutes.
              </li>
              <li>
                <span>2</span>
                While waiting for the chicken to cook, slice the cucumbers into
                long strips and mix all the condiments for the sauce in a bowl.
              </li>
              <li>
                <span>3</span>
                Shred the chicken breasts when it is cooled, then plate it over
                a bed of cut cucumbers and coriander (as much as you like)
              </li>
              <li>
                <span>4</span>
                Pour the sauce all over the chicken and cucumber, and finish
                with some toasted sesame seeds.
              </li>
            </ul>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Mini_bang_bang_chicken_salad;
