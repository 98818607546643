import React, { Component } from "react";
import { Link } from "react-router-dom";
export class TargetWorkoutItem extends Component {
  render() {
    var { targetWorkoutName, targetWorkoutIndex } = this.props;
    return (
      <li>
        <span>
          {parseInt(targetWorkoutIndex) + 1}. {targetWorkoutName}
        </span>
        <Link to="#" onClick={() => this.props.onClick(targetWorkoutIndex)}>
          <img src={require("../../images/icn_close_tags.svg")} alt="" />
        </Link>
      </li>
    );
  }
}
