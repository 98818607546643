import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

class SliderPhoto extends Component {
  state = {
    disablePan: true
  }
  onZoomChangeHandler = (zoomEvent) => {
    if (zoomEvent.scale == 1) {
      zoomEvent.pan.disabled = true;
      setTimeout(()=>{
        this.props.onSetSwipe(true)
      })
    } else {
      // alert(zoomEvent.scale)
      zoomEvent.pan.disabled = false;
      setTimeout(()=>{
        this.props.onSetSwipe(false)
      })
    }
  }
  render() {
    const { img } = this.props;
    //console.log(img.photo)
    return (
      <TransformWrapper
        style={{ overflow: 'inherit' }}
        doubleClick={{
          mode: 'reset'
        }}
        pan={{
          disabled: true
        }}
        onZoomChange={this.onZoomChangeHandler}
      >
        <TransformComponent
        >
          <div>
            <img
              className="fadeIn progress-img"
              src={img.photo}
              // onClick={this.props.onClick}
              alt=""
            />
          </div>
        </TransformComponent>
      </TransformWrapper>

    );
  }
}

export default SliderPhoto;
