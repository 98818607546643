import * as actionTypes from './actionTypes';
import { API } from '../../config/config';
import { headers } from '../../ultils/helpers';

export const sendEmotion = emotion => dispatch => {

    return dispatch({
        type: actionTypes.SEND_EMOTION,
        data: emotion
    })
}
export const sendFeedback = feedback => dispatch => {
    return dispatch({
        type: actionTypes.SEND_FEEDBACK,
        data: feedback
    })
}
export const getAllDiary = () => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(API.GET_ALL_DIARY_API, {
            headers: headers
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err))
    });
}
export const sendDiary = diary => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(API.CREATE_DIARY_API, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(diary)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err))
    });
}
export const getHeartRateWeek = (heartRateWeek) => dispatch => {
    return dispatch({
        type: actionTypes.GET_HEART_RATE,
        data: heartRateWeek
    })
}
export const getStartTime = (startTime, workoutName, week) => dispatch => {
    return dispatch({
        type: actionTypes.GET_START_TIME,
        data: { startTime, workoutName, week }
    })
}

export const getEndTime = endTime => dispatch => {
    return dispatch({
        type: actionTypes.GET_END_TIME,
        data: endTime
    })
}

export const setHeartRate = (heartRate) => dispatch => {
    return dispatch({
        type: actionTypes.SET_HEART_RATE,
        data: heartRate
    })
}

