import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Moment from "moment";
import ModalPhotoItem from "./ModalPhotoItem";
class ModalPhotoList extends Component {
  render() {
    const { date, images, selectedId } = this.props;
    return (
      <div class="photos">
        <h3>{Moment(date).format("DD MMM YYYY")}</h3>
        <div className="photosContainer">
          {images
            ? images.map(img =>
                img.date === date ? (
                  <ModalPhotoItem
                    key={img.id}
                    img={img}
                    onItemClick={selectedImg =>
                      this.props.onItemClick(selectedImg)
                    }
                    selectedId={selectedId}
                  />
                ) : null
              )
            : null}
        </div>
      </div>
    );
  }
}

export default ModalPhotoList;
