import React, { Component } from "react";

import { rateType } from "../../config/config";
import ModalRate2 from "./ModalRate2";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getExerciseReps, nextExercise } from "../../store/actions/Exercise";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ModalwthWorkout from "./ModalwthWorkout";
import { WEBVIEW_ACTION } from "../../config/config";
import { sendEmotion, sendDiary, getEndTime } from "../../store/actions/Diary";
import AddToCalendar from "react-add-to-calendar";
class ModalRate extends Component {
  state = {
    emotion: null,
    showModel: true
  };
  onClick = () => {
    //this.sendTime()
    this.setState({ modalShow: true, showModel: false });
  };

  onSubmit = () => {
    //this.sendTime()
    this.setState({ modalShow: true, showModel: false });
    this.props.sendEmotion(this.state.emotion);
  };
  getEndTime = () => {
    let date = new Date();
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
  };
  emotionClick = rateType => {
    this.setState({ emotion: rateType });
  };
  modalClose = () => this.setState({ modalShow: false });
  modalShow = () => this.setState({ modalShow: true });
  onBack = () => {
    this.setState({ modalShow: false, showModel: true });
  };
  
  componentDidUpdate(prevProps) {}
  render() {
    const { show } = this.props;
    const { showModel } = this.state;
    return (
      <div>
        <Modal
          className="modal-custom"
          show={showModel === show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Link to="#" className="icon">
            <img
              onClick={this.onClick}
              src={require("../../images/ic_close_p.svg")}
              alt=""
            />
          </Link>
          <Modal.Body>
            <div className="content-popup">
              <h3>My Current Mood</h3>
              <div className="emoji">
                <Link
                  to="#"
                  onClick={() => this.emotionClick(rateType.HAPPY)}
                  className={
                    this.state.emotion === rateType.HAPPY ? "active" : ""
                  }
                >
                  <img src={require("../../images/emo1.svg")} alt="" />
                </Link>
                <Link
                  to="#"
                  onClick={() => this.emotionClick(rateType.NORMAL)}
                  className={
                    this.state.emotion === rateType.NORMAL ? "active" : ""
                  }
                >
                  <img src={require("../../images/emo2.svg")} alt="" />
                </Link>
                <Link
                  to="#"
                  onClick={() => this.emotionClick(rateType.SAD)}
                  className={
                    this.state.emotion === rateType.SAD ? "active" : ""
                  }
                >
                  <img src={require("../../images/emo3.svg")} alt="" />
                </Link>
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer">
            <button type="button" onClick={this.onClick}>
              SKIP
            </button>
            {this.state.emotion ? (
              <button type="button" onClick={this.onSubmit}>
                SUBMIT
              </button>
            ) : (
              <button type="button" className="disabled">
                SUBMIT
              </button>
            )}
          </div>
        </Modal>

        <ModalRate2
          show={this.state.modalShow}
          backdrop="static"
          onClosed={this.props.onClosed}
          onSubmit={this.props.onRated}
          onBack={this.onBack}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state,
    ...state.diary
  };
};
export default connect(
  mapStateToProps,
  { sendEmotion, sendDiary, getExerciseReps, nextExercise }
)(ModalRate);

// export class ModalRate extends Component {
//   constructor(...args) {
//     super(...args);

//     this.state = { modalShow: false };
//   }

//   render() {
//     let modalClose = () => this.setState({ modalShow: false });
//     return (
//       <ButtonToolbar>

//         <MyVerticallyCenteredModal
//           show={this.state.modalShow}
//           onHide={modalClose}
//         />
//       </ButtonToolbar>
//     );
//   }
// }

// export default ModalRate
