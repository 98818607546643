import * as actionTypes from "./actionTypes";
import { headers } from "../../ultils/helpers";
import { API } from "../../config/config";
import { resolve, reject } from "bluebird";
export const getWorkOut = () => (dispatch) => {
  fetch(API.GET_WORKOUT_BY_TYPE, {
    headers: headers,
  })
    .then((res) => res.json())
    .then((data) => {
      let workOutList = data.reduce((r, a) => {
        r[a.week] = r[a.week] || [];
        r[a.week].push(a);
        return r;
      }, {});
      return dispatch({
        type: actionTypes.GET_WORKOUT_BY_TYPE,
        workOutList: workOutList,
      });
    })
    .catch((err) => console.log(err));
};
export const getChallengeWorkout = (challengeType) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_CHALLENGE_BY_TYPE, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ challengeType }),
    })
      .then((res) => res.json())
      .then((data) => {
        let challengeList = data.reduce((r, a) => {
          r[a.week] = r[a.week] || [];
          r[a.week].push(a);
          return r;
        }, {});
        resolve(challengeList);
      })
      .catch((err) => reject(err));
  });
};

export const getCustomWorkout = (categoryId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_CUSTOM_WORKOUT_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ categoryId }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const createCustomWorkout = (categoryId, workoutData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.CREATE_CUSTOM_WORKOUT_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ categoryId, workoutData }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const getWeekWorkOut = (week) => (dispatch) => {
  return dispatch({
    type: actionTypes.GET_WEEK_WORKOUT,
    week: week,
  });
};
export const setOS = (data) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_OS,
    data: data,
  });
};
export const getFullBodyWorkout = () => (dispatch) => {
  console.log(API.GET_FULL_BODY_WORKOUT_API);
  return new Promise((resolve, reject) => {
    fetch(API.GET_FULL_BODY_WORKOUT_API, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const getFullBodyWorkoutExerciseByCategory = (categoryId) => (
  dispatch
) => {
  console.log({ categoryId });
  return new Promise((resolve, reject) => {
    fetch(API.GET_FULL_BODY_WORKOUT_EXERCISE_BY_CATEGORY_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ categoryId }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const changeFullBodyWorkoutStatus = (isFullBodyWorkout) => (
  dispatch
) => {
  return dispatch({
    type: actionTypes.CHANGE_FULL_BODY_WORKOUT,
    data: isFullBodyWorkout,
  });
};
export const changeCustomWorkoutStatus = (isCustomWorkout) => (dispatch) => {
  return dispatch({
    type: actionTypes.CHANGE_CUSTOM_WORKOUT,
    data: isCustomWorkout,
  });
};
export const getWorkOutId = (workOutId) => (dispatch) => {
  return dispatch({
    type: actionTypes.GET_WORKOUT_ID,
    workOutId: workOutId,
  });
};
export const setWorkoutDone = (done) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_WORKOUT_DONE,
    done: done,
  });
};
export const getAllMessage = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_MESSAGE_API, {
      method: "POST",
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: actionTypes.GET_ALL_MESSAGE,
          data: data,
        });
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};
// export const getCurrentWorkOut = (currentWeek) => dispatch => {
//   return new Promise((resolve, reject) => {
//     fetch(API.GET_CURRENT_WORKOUT_API, {
//       headers: headers,
//     })
//       .then(res => res.json())
//       .then(data => {
//         dispatch({
//           type: actionTypes.GET_CURRENT_WORKOUT,
//           data: data
//         });
//         resolve(data);
//       })
//       .catch(err => reject(err));
//   });
// };

export const getCurrentWorkOut = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_CURRENT_WORKOUT_API, {
      method: "POST",
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: actionTypes.GET_CURRENT_WORKOUT,
          data: data,
        });
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};
export const getChallengeMessages = (challengeId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_CHALLENGE_MESSAGES, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ challengeId }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
