import * as actionTypes from '../actions/actionTypes';

const initialState = {
  sliders: []
}

const sliderReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.GET_ALL_SLIDER:
      return {
        ...state,
        sliders: action.data
      }
    default:
      return state;
  }
}
export default sliderReducer;