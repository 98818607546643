export const TYPES = {
  DATE: "date",
  CHECKBOX: "radio",
  TEXTBOX: "textbox",
  DROPDOWN: "dropdown",
};

export const MENU = {
  INDEX: "Index",
  PROGRAMME: "Programme",
  PROGRESS: "Progress",
  MERCH: "Merch",
  COMMUNITY: "Community",
  MERCH_URL: { link: "https://shopee.sg/nosweatfitness" },
  COMMUNITY_URL: {
    ios: "fb://profile/467841577379196",
    android: "fb://page/467841577379196",
  },
  //COMMUNITY_URL: "fb://profile/467841577379196"
};
export const PRIVACY = {
  SEND_EMAIL: "SEND_EMAIL",
  EMAIL_URL: { link: "mailto:nosweat@clicknetwork.tv" },
};
export const TERM = {
  HOME_PAGE: "TERM_HOME_PAGE",
  CONS_PAGE: "TERM_CONS_PAGE",
  HOME_PAGE_URL: { link: "http://nosweatfitnessapp.com/" },
  CONS_PAGE_URL: { link: "https://www.termsandconditionsgenerator.com" },
};
export const APP_URL = {
  IOS_URL: { link: "https://apps.apple.com/app/id1468863692" },
  ANDROID_URL: {
    link:
      "https://play.google.com/store/apps/details?id=com.netrunnersystems.nosweat",
  },
};
export const WEBVIEW_ACTION = {
  SEND_URL: "SEND_URL",
  LOG_OUT: "LOG_OUT",
  ON_SHARE: "ON_SHARE",
  GET_STEPS: "GET_STEPS",
  GET_HEART_RATE: "GET_HEART_RATE",
  GET_DATA: "GET_DATA",
  AUTO_LOGIN: "AUTO_LOGIN",
  ON_UPGRADE: "ON_UPGRADE",
  GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
  SCHEDULE_WORKOUT: "SCHEDULE_WORKOUT",
  ALERT_MESSAGE: "ALERT_MESSAGE",
  GET_DEVICE_OS: "GET_DEVICE_OS",
  ON_NOTIFICATION: "ON_NOTIFICATION",
  FORCE_REGISTER: "FORCE_REGISTER",
  ON_RATING: "ON_RATING",
  KEEP_AWAKE: "KEEP_AWAKE",
  GO_TO_RECIPES: "GO_TO_RECIPES",
  OPEN_RECIPE_DETAIL: "OPEN_RECIPE_DETAIL",
  OPEN_UPGRADE_PAGE: "OPEN_UPGRADE_PAGE",
  GO_TO_INDEX: "GO_TO_INDEX",
};
export const DIARY_TYPES = {
  WORKOUT: "workout",
  CARDIO: "cardio",
};
const DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ROOT_URL_DEV
    : process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_ROOT_URL_PRO_DEV
    : process.env.REACT_APP_ROOT_URL_PRO_LIVE;
const ROUTES = {
  SURVEYS: "api/categories/getAllCategory",
  GET_WORKOUT_VIDEO: "api/workoutExercise/getWorkoutExerciseById",
  CREATE_SURVEY: "api/userSurveys/create",
  GET_WORKOUT: "api/workout/getall",
  GET_WORKOUT_BY_TYPE: "api/workout/getWorkoutByType",
  GET_CHALLENGE_BY_TYPE: "api/workout/getChallengeByType",
  GET_PHOTO: "api/photo/getAll",
  DELETE_PHOTO: "api/photo/delete",
  CREATE_WORKOUT_HISTORY: "api/workoutHistory/create",
  CREATE_CHALLENGE_WORKOUT_HISTORY_API:
    "api/workoutHistory/createChallengeHistory",
  CREATE_DIARY: "api/diary/create",
  GET_ALL_DIARY: "api/diary/getAll",
  GET_USER: "api/users/getuser",
  UPLOAD_PHOTO: "api/photo/upload",
  GET_CURRENT_WORKOUT: "api/workout/getCurrentWorkout",
  RESET_PASSWORD: "api/users/resetPassword",
  UPDATE_PASSWORD: "api/users/updateUserPassword",
  UPLOAD_ASSESSMENT: "api/assessment/uploadAssessment",
  CHECK_PASSWORD: "api/users/checkPassword",
  UPDATE_PROFILE: "api/users/update",
  UPDATE_PROFILE_PICTURE: "api/users/updateProfilePicture",
  UPDATE_USER_ASSESSMENT: "api/users/updateUserAssessment",
  UPDATE_RATING_STATUS: "api/users/updateRatingStatus",
  GET_ALL_ASSESSMENT: "api/assessment/getAllAssessment",
  GET_ALL_SLIDER: "api/slider/getAllSlider",
  GET_ALL_SCHEDULE: "api/schedule/getAllSchedule",
  UPDATE_SCHEDULE_WORKOUT: "api/schedule/updateWorkout",
  UPDATE_SCHEDULE_CARDIO: "api/schedule/updateCardio",
  REMOVE_SCHEDULE: "api/schedule/remove",
  GET_ALL_CARDIO: "api/cardio/getAllCardio",
  GET_CARDIO_HISTORY: "api/cardio/getCardioHistory",
  UPDATE_CARDIO_HISTORY: "api/cardio/updateCardioHistory",
  GET_ALL_CARDIO_HISTORY: "api/cardio/getAllCardioHistory",
  GET_ALL_MESSAGE: "api/workout/getAllMessage",
  UPDATE_ASSESSMENT_STATUS: "api/assessment/updateStatus",
  GET_ASSESSMENT_BY_ID: "api/assessment/getAssessmentById",
  GET_REPLIED_ASSESSMENT: "api/assessment/getRepliedAssessment",
  GET_ALL_WORKOUT_TYPE: "api/surveys/getAllWorkoutType",
  UPDATE_WORKOUT_TYPE: "api/surveys/updateWorkoutType",
  GET_ALL_EVENTS: "api/liveevents/getAllEvents",
  GET_VIDEO_EXERCISE: "api/workoutExercise/getVideoExerciseById",
  GET_LIST_ACHIEVEMENTS_DETAIL: "api/achievement/getListAchievementsDetail",
  GET_ALL_USER_ACHIEVEMENTS: "api/achievement/getAllUserAchievements",
  SHOULD_SHOW_RATING_DIALOG_API: "api/achievement/shouldShowRatingDialog",
  GET_FULL_BODY_WORKOUT: "api/workout/getFullBodyWorkoutCategory",
  GET_ALL_CHALLENGES: "api/workout/getAllChallenges",
  GET_CHALLENGE_WORKOUT_EXERCISES: "api/workout/getChallengeWorkoutExercises",
  GET_CHALLENGE_MESSAGES: "api/workout/getChallengeMessages",
  GET_FULL_BODY_WORKOUT_EXERCISE_BY_CATEGORY:
    "api/workout/getFullBodyWorkoutExercisesByCategory",
  GET_CUSTOM_WORKOUT: "api/workout/getCustomWorkout",
  CREATE_CUSTOM_WORKOUT: "api/workout/createCustomWorkout",
  CLICK_UPGRADE_BUTTON: "api/users/clickUpgradeButton",
};
export const API = {
  SURVEYS_API: DOMAIN + ROUTES.SURVEYS,
  CREATE_SURVEY_API: DOMAIN + ROUTES.CREATE_SURVEY,
  GET_WORKOUT_API: DOMAIN + ROUTES.GET_WORKOUT,
  GET_WORKOUT_VIDEO_API: DOMAIN + ROUTES.GET_WORKOUT_VIDEO,
  GET_WORKOUT_BY_TYPE: DOMAIN + ROUTES.GET_WORKOUT_BY_TYPE,
  GET_CHALLENGE_BY_TYPE: DOMAIN + ROUTES.GET_CHALLENGE_BY_TYPE,
  GET_PHOTO_API: DOMAIN + ROUTES.GET_PHOTO,
  DELETE_PHOTO_API: DOMAIN + ROUTES.DELETE_PHOTO,
  CREATE_WORKOUT_HISTORY_API: DOMAIN + ROUTES.CREATE_WORKOUT_HISTORY,
  CREATE_CHALLENGE_WORKOUT_HISTORY_API:
    DOMAIN + ROUTES.CREATE_CHALLENGE_WORKOUT_HISTORY_API,
  CREATE_DIARY_API: DOMAIN + ROUTES.CREATE_DIARY,
  GET_ALL_DIARY_API: DOMAIN + ROUTES.GET_ALL_DIARY,
  GET_USER_API: DOMAIN + ROUTES.GET_USER,
  UPLOAD_PHOTO_API: DOMAIN + ROUTES.UPLOAD_PHOTO,
  GET_CURRENT_WORKOUT_API: DOMAIN + ROUTES.GET_CURRENT_WORKOUT,
  RESET_PASSWORD_API: DOMAIN + ROUTES.RESET_PASSWORD,
  UPDATE_PASSWORD_API: DOMAIN + ROUTES.UPDATE_PASSWORD,
  UPLOAD_ASSESSMENT_API: DOMAIN + ROUTES.UPLOAD_ASSESSMENT,
  CHECK_PASSWORD_API: DOMAIN + ROUTES.CHECK_PASSWORD,
  UPDATE_PROFILE_API: DOMAIN + ROUTES.UPDATE_PROFILE,
  UPDATE_PROFILE_PICTURE_API: DOMAIN + ROUTES.UPDATE_PROFILE_PICTURE,
  UPDATE_USER_ASSESSMENT_API: DOMAIN + ROUTES.UPDATE_USER_ASSESSMENT,
  UPDATE_RATING_STATUS_API: DOMAIN + ROUTES.UPDATE_RATING_STATUS,
  GET_ALL_ASSESSMENT_API: DOMAIN + ROUTES.GET_ALL_ASSESSMENT,
  GET_ALL_SLIDER_API: DOMAIN + ROUTES.GET_ALL_SLIDER,
  GET_ALL_SCHEDULE_API: DOMAIN + ROUTES.GET_ALL_SCHEDULE,
  UPDATE_SCHEDULE_WORKOUT_API: DOMAIN + ROUTES.UPDATE_SCHEDULE_WORKOUT,
  UPDATE_SCHEDULE_CARDIO_API: DOMAIN + ROUTES.UPDATE_SCHEDULE_CARDIO,
  REMOVE_SCHEDULE_API: DOMAIN + ROUTES.REMOVE_SCHEDULE,
  GET_ALL_CARDIO_API: DOMAIN + ROUTES.GET_ALL_CARDIO,
  GET_CARDIO_HISTORY_API: DOMAIN + ROUTES.GET_CARDIO_HISTORY,
  UPDATE_CARDIO_HISTORY_API: DOMAIN + ROUTES.UPDATE_CARDIO_HISTORY,
  GET_ALL_CARDIO_HISTORY_API: DOMAIN + ROUTES.GET_ALL_CARDIO_HISTORY,
  GET_ALL_MESSAGE_API: DOMAIN + ROUTES.GET_ALL_MESSAGE,
  UPDATE_ASSESSMENT_STATUS_API: DOMAIN + ROUTES.UPDATE_ASSESSMENT_STATUS,
  GET_ASSESSMENT_BY_ID_API: DOMAIN + ROUTES.GET_ASSESSMENT_BY_ID,
  GET_REPLIED_ASSESSMENT_API: DOMAIN + ROUTES.GET_REPLIED_ASSESSMENT,
  GET_ALL_WORKOUT_TYPE_API: DOMAIN + ROUTES.GET_ALL_WORKOUT_TYPE,
  UPDATE_WORKOUT_TYPE_API: DOMAIN + ROUTES.UPDATE_WORKOUT_TYPE,
  GET_ALL_EVENTS_API: DOMAIN + ROUTES.GET_ALL_EVENTS,
  GET_VIDEO_EXERCISE_API: DOMAIN + ROUTES.GET_VIDEO_EXERCISE,
  GET_LIST_ACHIEVEMENTS_DETAIL_API:
    DOMAIN + ROUTES.GET_LIST_ACHIEVEMENTS_DETAIL,
  GET_ALL_USER_ACHIEVEMENTS_API: DOMAIN + ROUTES.GET_ALL_USER_ACHIEVEMENTS,
  SHOULD_SHOW_RATING_DIALOG_API: DOMAIN + ROUTES.SHOULD_SHOW_RATING_DIALOG_API,
  GET_FULL_BODY_WORKOUT_API: DOMAIN + ROUTES.GET_FULL_BODY_WORKOUT,
  GET_ALL_CHALLENGES: DOMAIN + ROUTES.GET_ALL_CHALLENGES,
  GET_CHALLENGE_WORKOUT_EXERCISES:
    DOMAIN + ROUTES.GET_CHALLENGE_WORKOUT_EXERCISES,
  GET_CHALLENGE_MESSAGES: DOMAIN + ROUTES.GET_CHALLENGE_MESSAGES,
  GET_FULL_BODY_WORKOUT_EXERCISE_BY_CATEGORY_API:
    DOMAIN + ROUTES.GET_FULL_BODY_WORKOUT_EXERCISE_BY_CATEGORY,
  GET_CUSTOM_WORKOUT_API: DOMAIN + ROUTES.GET_CUSTOM_WORKOUT,
  CREATE_CUSTOM_WORKOUT_API: DOMAIN + ROUTES.CREATE_CUSTOM_WORKOUT,
  CLICK_UPGRADE_BUTTON_API: DOMAIN + ROUTES.CLICK_UPGRADE_BUTTON,
};
export const ACHIEVEMENTS = {
  CODES: {
    FIRST_WORKOUT: "FIRST_WORKOUT",
    FITNESS_ROOKIE: "FITNESS_ROOKIE",
    HABIT_MAKER: "HABIT_MAKER",
    FITNESS_GURU: "FITNESS_GURU",
    HEALTH_HUSTLER: "HEALTH_HUSTLER",
    CONSISTENCY_CHAMPION: "CONSISTENCY_CHAMPION",
    CARDIO_WARRIOR: "CARDIO_WARRIOR",
    GAME_CHANGER: "GAME_CHANGER",
  },
  FIRST_WORKOUT: {
    title: "Starter Sticker",
    description: `You’ve successfully completed your first workout on the app! This is just the first step to a healthier and happier YOU!`,
    image_name: "StarterSticker.svg",
    allow_multiple: false,
  },
  FITNESS_ROOKIE: {
    title: "Fitness Rookie",
    description: `Yay! You’ve successfully completed 5 workouts and should be so proud of yourself! Let’s carry on and make working out a habit!`,
    image_name: "FitnessRookie.svg",
    allow_multiple: false,
  },
  HEALTH_HUSTLER: {
    title: "Health Hustler",
    description: `You completed all your workouts and cardio sessions of the week within 7 days and have  proven to be a true hustler when it comes to achieving your goals. Don’t stop now! `,
    image_name: "HealthHustler.svg",
    allow_multiple: true,
  },

  HABIT_MAKER: {
    title: "Habit Maker",
    description: `Congratulations! You’ve successfully completed 10 workouts on the programme, and made working out a habit! Keep it up, you fitspiration!`,
    image_name: "HabitMaker.svg",
    allow_multiple: true,
  },
  FITNESS_GURU: {
    title: "Fitness Guru",
    description: `50 Workouts! You're officially a fitness guru <3 Continue being an inspiration, and encourage others around you to make fitness their lifestyle too.  
    Here's to another 50 more! 
    `,
    image_name: "FitnessGuru.svg",
    allow_multiple: true,
  },

  CONSISTENCY_CHAMPION: {
    title: "Consistency Champion",
    description: `You’re on a workout streak! Consistency is key so keep going! `,
    image_name: "ConsistencyChampion.svg",
    allow_multiple: true,
  },
  CARDIO_WARRIOR: {
    title: "Cardio Warrior",
    description: `You deserve this badge for successfully completing your cardio sessions of the week! You’re extraordinary! `,
    image_name: "CardioWarrior.svg",
    allow_multiple: true,
  },
  GAME_CHANGER: {
    description: `That extra session was a game changer! You’re on track to reaching your goals sooner than you think. `,
    image_name: "GameChanger.svg",
    allow_multiple: true,
  },
};

export const ERRORS = {
  isEmpty: "is Empty",
  nameIsTooShort: "is too Short",
  nameIsTooLong: "is too Long",
  dateIsInvalid: "is Invalid",
  dateErr: "You can only select a day during this workout week, up to today.",
};
export const message =
  "How are you feeling? Any changes in diet, weight, energy levels etc?";

export const rateType = {
  HAPPY: "HAPPY",
  NORMAL: "NORMAL",
  SAD: "SAD",
};
export const exerciseType = {
  REPS: "reps",
  TIMER: "timer",
};
export const CLIENT_DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ROOT_DEV_CLIENT
    : DOMAIN;
export const DOMAIN_ICON = `${
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ROOT_DEV_CLIENT
    : DOMAIN
}data/achievement_img`;
//"https://app.nosweatfitnessapp.com/data/achievement_img";
export const UPGRADE_OPTIONS = {
  //ONE_YEAR: 'android.test.purchased',
  // ONE_YEAR: "com.netrunnersystems.nosweat.pro1y",
  //TWELVE_WEEKS: "com.netrunnersystems.nosweat.pro3m",
  // FOUR_WEEKS: "com.netrunnersystems.nosweat.pro1m",
  // "com.netrunnersystems.nosweat.1msub",
  //   "com.netrunnersystems.nosweat.3msub",
  //   "com.netrunnersystems.nosweat.1ysub"

  ONE_YEAR: "com.netrunnersystems.nosweat.1ysub",
  TWELVE_WEEKS: "com.netrunnersystems.nosweat.3msub",
  FOUR_WEEKS: "com.netrunnersystems.nosweat.1msub",
};
export const MODAL_TEXT = {
  RATING: "RATING MESSAGE",
  REGISTER_TRIAL_USER:
    "Please register an account before purchasing so that you will not lose access if you lose or change your phone.",
};
export const BOOL_VALUE = {
  TRUE: "true",
  FALSE: "false",
  NULL: "null",
};
export const sliderType = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
};
export const compressedFile = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1024, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight
};
export const HTMLCANVAS_CONFIG = {
  useCORS: true,
  // width: 375,
  // height: 667
};
export const UNSUBSCRIBE_LINK = {
  GOOGLE_URL: { link: "https://support.google.com/googleplay/answer/7018481" },
  APPLE_URL: { link: "https://support.apple.com/en-us/HT202039" },
};
export const imgConfig = "data:image/png;base64";

export const MAXIMUM_TARGET_WORKOUT_ALLOWED = 6;
