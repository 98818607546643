import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getUserEmail, updatePassword } from '../store/actions/UserInfo'
class ResetPassword extends Component {
  state = {
    email: null,
    password: null,
    confirmPassword: null
  }
  componentDidMount = async () => {
    const { token } = this.props.match.params
    const { result } = await this.props.getUserEmail(token)
    this.setState({ email: result })
  }
  updatePassword = async () => {
    const { password, confirmPassword, email } = this.state;
    if (password !== confirmPassword) {
      console.log('password err')
    } else {
      await this.props.updatePassword(email, password)
    }
  }
  /* sendMessage = () => {
   sendMessage({ type: WEBVIEW_ACTION.SEND_URL, url: MENU.URL })
  }; */
  onChange = (e) => {
    this.setState({ [e.target.dataset.id]: e.target.value })
  }
  render() {
    const { email, password, confirmPassword } = this.state
    console.log(password)
    return (
      <div>
        <div className="colm_costume colmResetPassword">
          <div className="logo">
            <img src={require('../images/logo.png')} alt="logo" />
          </div>
          <form className="login">
            <div className="form-group">
              <input
                type="textarea"
                className="form-control"
                id="Email"
                value={email ? email : null}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="Password"
                data-id="password"
                placeholder="New Password"
                onChange={e => this.onChange(e)}
                value={password}
              />
            </div>
            <div className="form-group form-group-2">
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                data-id="confirmPassword"
                onChange={e => this.onChange(e)}
                value={confirmPassword}
                placeholder="Confirm Password" />
            </div>
          </form>
          <div className="button">
            <button onclick="location.href='info.html'" type="button" onClick={this.updatePassword}>Submit</button>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    ...state.userInfo
  }
}
export default connect(mapStateToProps, { getUserEmail, updatePassword })(ResetPassword)
