import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import SliderPhoto from "./SliderPhoto";
import LoadingIcon from "../Loading/LoadingIcon";
import { getPhoto, deletePhoto } from "../../store/actions/Photo";
import { WEBVIEW_ACTION } from "../../config/config";
import Moment from "moment";
import { sendMessage } from "../../ultils/ultils";
class SliderProgress extends Component {
  state = {
    currentIndex: null,
    deleting: false,
    imgUrl: "",
    index: null,
    hover: false,
    enableSwipe: true,
  };
  deletePhoto = () => {
    const { images } = this.props;
    const { currentIndex } = this.state;
    this.setState({ deleting: true });
    const { id } = images[currentIndex];
    this.props.deletePhoto(id).then(() => {
      if (images.length <= 1) {
        this.props.history.push("/progress");
      }
      console.log("currentIndex", currentIndex);
      this.setState(
        {
          currentIndex: currentIndex === 0 ? 0 : currentIndex - 1,
          deleting: false,
        },
        () => {
          this.slider.slickGoTo(this.state.currentIndex);
        }
      );
    });
  };
  onShare = () => {
    let { currentIndex, imgUrl, index } = this.state;
    const { images } = this.props;
    imgUrl = images[currentIndex ? currentIndex : index].photo;
    sendMessage({ type: WEBVIEW_ACTION.ON_SHARE, imgUrl: imgUrl });
  };
  encodeImageFileAsURL(element) {
    var file = element.files[0];
    var reader = new FileReader();
    reader.onloadend = function() {
      console.log("RESULT", reader.result);
    };
    reader.readAsDataURL(file);
  }
  componentDidMount() {
    const { images } = this.props;
    const { id } = this.props.match.params;
    const arr = images.map((item) => {
      return item.id;
    });
    const index = arr.indexOf(parseInt(id));
    this.setState({ currentIndex: index, index: index });
  }
  onClick = () => {
    const { hover } = this.state;
    this.setState({ hover: !hover });
  };
  onSetSwipe = (swipeValue) => {
    // if(this.slider){
    //   this.slider.swipe = swipeValue;
    // }
    this.slider.swipe = swipeValue;
  };
  render() {
    console.log("rerender");
    const { images } = this.props;
    let { currentIndex, hover } = this.state;
    const { id } = this.props.match.params;
    const arr = images.map((item) => {
      return item.id;
    });
    const index = arr.indexOf(parseInt(id));
    const settings3 = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      slickNext: 1,
      initialSlide: index === -1 ? 0 : index,
      beforeChange: (current, changedIndex) => {
        this.setState({ currentIndex: changedIndex });
      },
    };

    return this.state.deleting || this.state.currentIndex === null ? (
      <LoadingIcon />
    ) : (
      <div className="progress_over  fadeIn">
        <div
          className={hover ? "bar-progress-top hoverImage" : "bar-progress-top"}
          onClick={this.onClick}
        >
          <Link to="/progress" className="icon">
            <img src={require("../../images/back.svg")} alt="" />
          </Link>

          <Link to="#" className="icon" onClick={this.onShare}>
            <img src={require("../../images/ic_share.svg")} alt="" />
          </Link>
        </div>

        <div className="image_slider_progress" onClick={this.onClick}>
          <Slider {...settings3} ref={(slider) => (this.slider = slider)}>
            {images.map((img) => {
              return (
                <SliderPhoto
                  key={img.id}
                  img={img}
                  onClick={this.onClick}
                  onSetSwipe={this.onSetSwipe}
                />
              );
            })}
          </Slider>
        </div>

        <div
          className={
            hover ? "bar-progress-bottom hoverImage" : "bar-progress-bottom"
          }
        >
          <span className="title_name">
            {Moment(images[currentIndex].date).format("DD MMM YYYY")}
          </span>
          <Link to="#" className="icon">
            <img
              onClick={this.deletePhoto}
              src={require("../../images/ic_delete.svg")}
              alt=""
            />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.images,
  };
};
export default connect(
  mapStateToProps,
  { getPhoto, deletePhoto }
)(SliderProgress);
