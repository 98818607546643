import React, { Component } from "react";
import { Link } from "react-router-dom";
class ModalPhotoItem extends Component {
  state = {
    checked: false
  };
  onClick = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  };
  render() {
    const { img, selectedId } = this.props;
    const { checked } = this.state;
    return (
      <div className="box">
        <div className="content">
          <Link
            to="#"
            className={selectedId === img.id ? "checked" : null}
            onClick={() => this.props.onItemClick(img)}
          >
            <img src={img.photo} alt="" />
          </Link>
        </div>
      </div>
    );
  }
}

export default ModalPhotoItem;
