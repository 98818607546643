import React, { Component, Fragment } from "react";
import Countdown from "react-countdown-now";
import { Link } from "react-router-dom";
import Moment from "moment";
import sound from "../assets/tick.mp3";
export class Resttime extends Component {
  state = {
    //isDone: false
    duration: 3
  };
  //audio = new Audio(sound);
  /*  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isDone != this.state.isDone) return true;
    else return false;
  } */
  componentDidMount = () => {
    //this.audio.play();
    let { duration } = this.state;
    let interval = setInterval(() => {
      duration--;
      this.setState({ duration: duration });
      if (duration < 0) {
        this.props.onComplete();
        clearInterval(interval)
      }
    }, 1000);
  };
  renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      minutes = minutes >= 10 ? minutes.toString() : "0" + minutes;
      seconds = seconds >= 10 ? seconds.toString() : "0" + seconds;
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  onTick = () => {
    //console.log('on tick');
  };
  render() {
    const { duration } = this.state;
    return (
      <div className="page page_restime fadeIn">
        <div className="time_restime">
          Rest time
          {/* <Countdown
                date={Date.now() + 3000}
                renderer={this.renderer}
                onComplete={this.props.onComplete}
              /> */}
          <span>{Moment.utc(duration * 1000).format("mm:ss")}</span>
        </div>
        <div className="skip_time">
          <Link to="#" onClick={this.props.onSkip} className="icon">
            Skip
          </Link>
        </div>
      </div>
    );
  }
}

export default Resttime;
