import React, { Component } from "react";
import Slider from "react-slick";
import CalendarDays from './CalendarDays';
import {Modal, Button} from "react-bootstrap/Modal";
import ModalSchedule from '../Modal/ModalSchedule';
import ModalScheduleEdit from '../Modal/ModalScheduleEdit';
import moment from 'moment'
class Calendar extends Component {
  // state = {
  //   week: [],
  //   modalShowScheduleEdit: false,
  // }
  // componentDidMount() {
  //   let now = moment();
  //   let { week } = this.state;
  //   let isSunday = now.format("dddd") === "Sunday";
  //   let dayPlusOne;
  //   if (isSunday) {
  //     for (let i = 0; i < 7; i++) {
  //       dayPlusOne = now.add(i === 0 ? 0 : 1, "days").format("DD");
  //       week.push(dayPlusOne);
  //     }
  //   } else {
  //     let today = now.day();
  //     let startDay = now.subtract(today, "days");
  //     for (let i = 0; i < 7; i++) {
  //       dayPlusOne = startDay.add(i === 0 ? 0 : 1, "days").format("D");
  //       week.push(dayPlusOne);
  //     }
  //   }
  //   this.setState({ week });
  // }
  // modalCloseSchedule = () => this.setState({ modalShowSchedule: false });
  // modalCloseScheduleEdit = () => this.setState({ modalShowScheduleEdit: false });
  // render() {
  //   const { username, date, gender, usernameError, dateError } = this.state;
  //   const settings = {
  //     dots: false,
  //     speed: 500,
  //     slidesToShow: 7,
  //     slidesToScroll: 7,
  //     arrows: false,
  //     infinite: false
  //   };
  //   let now = moment();
  //   const { week } = this.state;
  //   return (
  //     <div className="calendar-container">
  //       <div className="calendar-table__row">
  //         <div className="calendar-table__col">S</div>
  //         <div className="calendar-table__col">M</div>
  //         <div className="calendar-table__col">T</div>
  //         <div className="calendar-table__col">W</div>
  //         <div className="calendar-table__col">T</div>
  //         <div className="calendar-table__col">F</div>
  //         <div className="calendar-table__col">S</div>
  //       </div>
  //       <div>
  //         <Slider {...settings}>
  //           {/* <CalendarDays days="2" />
  //           <CalendarDays isActive="active" days="3" /> */}

  //           {week.map(item => (
  //             <CalendarDays
  //               days={item}
  //               isActive={now.format("D") === item ? "active" : ""}
  //             />
  //           ))}
  //         </Slider>
  //       </div>
  //       <ul className="schedule-list">
  //         <li onClick={() => this.setState({ modalShowScheduleEdit: true })}>
  //           <h4>Workout A</h4>
  //           <p>
  //             10:00<span> am</span>
  //           </p>
  //         </li>
  //         <li>
  //           <h4>Workout B</h4>
  //           <p>
  //             10:00<span> am</span>
  //           </p>
  //         </li>
  //         <li>
  //           <h4>Workout C</h4>
  //           <p>
  //             10:00<span> pm</span>
  //           </p>
  //         </li>
  //       </ul>
  //       <a className="icon_add" onClick={() => this.setState({ modalShowSchedule: true })}><img src={require('../../images/icon_add.svg')} alt="icon_add" /></a>
  //       <ModalSchedule
  //         show={this.state.modalShowSchedule}
  //         onHide={this.modalCloseSchedule}
  //       />
  //       <ModalScheduleEdit
  //         show={this.state.modalShowScheduleEdit}
  //         onHide={this.modalCloseScheduleEdit}
  //       />
  //     </div>
  //   );
  // }
}

export default Calendar;
