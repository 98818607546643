import * as actionTypes from "../actions/actionTypes";

const initialState = {
  achievementsListDetail: null,
  achievementsData: [],
  countedAchievement: null,
  dateAchievement: null
};

const achievementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_ACHIEVEMENTS_DETAIL:
      return {
        ...state,
        achievementsListDetail: action.data.sortedData,
        achievementsData: action.data.data
      };
    case actionTypes.GET_ALL_USER_ACHIEVEMENTS:
      const { countedAchievement, dateAchievement } = action.data;
      // console.log({ data: action.data });
      return {
        ...state,
        countedAchievement: countedAchievement,
        dateAchievement: dateAchievement
      };
    default:
      return state;
  }
};

export default achievementsReducer;
