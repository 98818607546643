import React, { Component, Fragment } from "react";
import Menu from "../Menu/Menu";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LoadingIcon from "../Loading/LoadingIcon";
import { getAllDiary } from "../../store/actions/Diary";
import DiaryItem from "./DiaryItem";
import Moment from "moment";
import DiaryChart from "./DiaryChart";
class Diary extends Component {
  state = {
    isActive: MENU.PROGRESS,
    isLoading: true,
    diary: [],
    heartRateWeek: null
  };
  modalClose = () => this.setState({ modalShow: false });
  getAllDiary = async () => {
    const diary = await this.props.getAllDiary();
    if (diary) {
      this.setState({ isLoading: false, diary });
    }
  };
  componentDidMount() {
    const { heartRateWeek } = this.props;
    this.getAllDiary();
    this.setState({ heartRateWeek: heartRateWeek });
  }
  hasData = heartData => {
    let hasData = false;
    heartData.forEach(week => {
      week.forEach(day => {
        if (day.value.length > 0) {
          hasData = true;
        }
      });
    });
    return hasData;
  };
  render() {
    const { isLoading, diary, heartRateWeek } = this.state;
    const newList =
      diary.length > 0
        ? diary.reduce((r, a) => {
            r[a.date] = r[a.date] || [];
            r[a.date].push(a);
            return r;
          }, {})
        : null;
    if (isLoading) {
      return <LoadingIcon />;
    }
    return (
      <div className="fadeIn">
        <div className="page  page-header-progress">
          <div className="page-header">
            <div className="bar-progress">
              <Link to="#" className="icon" />
              <h4>Progress Photos</h4>

              <Link to="#" className="icon" />
            </div>
            <div className="nav_progress">
              {/* <a>Photos</a> */}
              <Link to="/progress">Photos</Link>
              {/* <a className="active">Workout  diary</a> */}
              <Link to="#" className="active">
                Workout Log
              </Link>
            </div>
          </div>
          <div className="page-content">
            {heartRateWeek &&
            heartRateWeek.length > 0 &&
            this.hasData(heartRateWeek) ? (
              <DiaryChart heartRateWeek={heartRateWeek} />
            ) : null}
            {diary && diary.length > 0 ? (
              <ul className="timeline">
                {diary.map((diary, key) => {
                  return (
                    <Fragment key={key}>
                      <DiaryItem diary={diary} />
                    </Fragment>
                  );
                })}
              </ul>
            ) : (
              <div className="empty__images2">
                <img src={require("../../images/emptydiary.svg")} alt="" />
              </div>
            )}
          </div>
          <div className="page-footer">
            <div className="menu">
              <Menu isActive={this.state.isActive} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.diary
  };
};
export default connect(
  mapStateToProps,
  { getAllDiary }
)(Diary);
