import * as actionTypes from "../actions/actionTypes";

const initialState = {
  showProfile: false,
  userProfile: null,
  profileAssessment: 0,
  listAssessment: [],
  reloadAssessment: false,
  playAudio: true,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL_PROFILE:
      return {
        ...state,
        showProfile: action.data,
      };
    case actionTypes.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.data,
      };
    case actionTypes.SET_PROFILE_ASSESSMENT:
      return {
        ...state,
        profileAssessment: action.data,
      };
    case actionTypes.RELOAD_ASSESSMENT:
      return {
        ...state,
        reloadAssessment: action.data,
      };
    case actionTypes.CLEAR_RELOAD:
      return {
        ...state,
        reloadAssessment: action.data,
      };
    case actionTypes.TOGGLE_PLAY_AUDIO:
      return {
        ...state,
        playAudio: !state.playAudio,
      };
    default:
      return state;
  }
};

export default profileReducer;
