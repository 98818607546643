import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/auth";
import userSurveysReducer from "./reducers/UserSurveys";
import userInfoReducer from "./reducers/UserInfo";
import workOutReducer from "./reducers/WorkOut";
import photoReducer from "./reducers/Photo";
import diaryReducer from "./reducers/Diary";
import exerciseReducer from "./reducers/Exercise";
import videosReducer from "./reducers/Video";
import profileReducer from "./reducers/Profile";
import sliderReducer from "./reducers/Slider";
import upgradeReducer from "./reducers/Upgrade";
import cardioReducer from "./reducers/Cardio";
import assessmentReducer from "./reducers/Assessment";
import eventsReducer from "./reducers/Liveevents";
import achievementsReducer from "./reducers/Achievements";
import challengeReducer from "./reducers/Challenge";
const rootReducer = combineReducers({
  auth: authReducer,
  userSurveys: userSurveysReducer,
  userInfo: userInfoReducer,
  workOutList: workOutReducer,
  images: photoReducer,
  diary: diaryReducer,
  exerciseReducer: exerciseReducer,
  videosReducer: videosReducer,
  profileReducer: profileReducer,
  sliderReducer: sliderReducer,
  upgradeReducer: upgradeReducer,
  cardioReducer: cardioReducer,
  assessmentReducer: assessmentReducer,
  eventsReducer: eventsReducer,
  achievementsReducer: achievementsReducer,
  challenge: challengeReducer,
});
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;
