import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import DiaryChartWeek from './DiaryChartWeek';
import Moment from 'moment';
class DiaryChart extends Component {
  state = {
    currentIndex: 0,
    count: 0
  }
  onNext = () => {
    let div = document.getElementById("styleChart")
    let deviceWidth = div.offsetWidth;
    let { currentIndex } = this.state;
    const { heartRateWeek } = this.props
    currentIndex > 0
      ? this.setState({ currentIndex: currentIndex - 1 })
      : this.setState({ currentIndex: 0 })
    let lastIndex = heartRateWeek[currentIndex].length
    let point = Math.floor((deviceWidth / 7));
    let scrollTo = point * lastIndex
    if (lastIndex < 7) {
      if (div.scrollLeft >= 0) {
        div.scrollLeft += scrollTo
      } else {
        div.scrollLeft += scrollTo
      }
    } else {
      div.scrollLeft += deviceWidth
    }

  }
  onBack = () => {
    let div = document.getElementById("styleChart")
    let deviceWidth = div.offsetWidth;
    let { currentIndex } = this.state;
    const { heartRateWeek } = this.props
    div.scrollLeft += (deviceWidth * -1)
    currentIndex < heartRateWeek.length - 1
      ? this.setState({ currentIndex: currentIndex + 1 })
      : this.setState({ currentIndex: heartRateWeek.length - 1 })
    let lastIndex = heartRateWeek[currentIndex].length
  }
  onScroll = () => {
    // let div = document.getElementById("styleChart")
    // let deviceWidth = div.offsetWidth;
    // let divScroll = div.scrollLeft;
    // const { currentIndex } = this.state;
    // const { heartRateWeek } = this.props;
    // if (divScroll < 0) {
    //   let scrollGap = Math.abs(divScroll / deviceWidth)
    //   switch (true) {
    //     case scrollGap > currentIndex + 1 && scrollGap < currentIndex + 1.1:
    //       this.setState({ currentIndex: currentIndex + 1 })
    //       break;
    //     case scrollGap < (currentIndex > 0 ? currentIndex === 1 ? 0.3 : currentIndex - 1 : 0):
    //       this.setState({ currentIndex: currentIndex - 1 })
    //       break;
    //     case scrollGap > ((heartRateWeek.length - 2) + 0.1):
    //       this.setState({ currentIndex: heartRateWeek.length - 1 })
    //       break
    //     default:
    //       break;
    //   }
    // } else {
    //   const { totalDiv } = this.state;
    //   let divGap = totalDiv / divScroll
    //   if (divGap > currentIndex + 1.3 && divGap < currentIndex + 1.5) {
    //     this.setState({ currentIndex: currentIndex + 1 })
    //   }
    //   if (divGap > ((heartRateWeek.length - 2) + 0.1)) {
    //     this.setState({ currentIndex: heartRateWeek.length - 1 })
    //   }
    //   if (divGap < (currentIndex > 0 ? currentIndex === 1 ? 1.3 : (currentIndex - 1) + 0.5 : 0)) {
    //     this.setState({ currentIndex: currentIndex - 1 })
    //   }


    // }
  }

  render() {
    const { heartRateWeek } = this.props
    const { currentIndex } = this.state
    let keys = Object.keys(heartRateWeek)
    let maxHeartRate = 0;
    heartRateWeek.map(items => {
      items.map(item => {
        let max = Math.max(...item.value)
        if (max > maxHeartRate) {
          maxHeartRate = Math.round(max)
        }
      })
    })
    let averageList = []
    heartRateWeek.forEach(list => {
      let totalValue = []
      list.forEach(x => {
        if (x.value.length > 0) {
          x.value.forEach(i => {
            totalValue.push(Math.round(i))
          })
        }
      })
      let from = list[list.length - 1].id;
      let to = list[0].id;
      let max = totalValue.length > 0 ? Math.max(...totalValue) : null
      let min = totalValue.length > 0 ? Math.min(...totalValue) : null
      let item = { from: Moment(from).format("DD MMM YYYY"), to: Moment(to).format("DD MMM, YYYY"), min: min, max: max }
      averageList.push(item)
    })
    let min = averageList[currentIndex].min;
    let max = averageList[currentIndex].max;
    let from = averageList[currentIndex].from;
    let to = averageList[currentIndex].to
    let fromMonth = Moment(from).format('MMM');
    let toMonth = Moment(to).format('MMM');

    if (fromMonth === toMonth) {
      from = Moment(from).format('DD')
    } else {
      from = Moment(from).format('DD MMM')
    }
    return (
      <div>
        <div className="kmTotalChart" style={{ margin: '10px 20px 0' }}>
          Average <span> {min && max ? `${min}-${max}` : '--'} BPM</span>
          <p>{from}-{to}</p>
        </div>

        <div className="daysTotalChart">
          <Link onClick={this.onBack} className={currentIndex === heartRateWeek.length - 1 ? "disable" : ""}><img src={require("../../images/icn_back_dark.svg")} alt="" /> </Link>
          {from}-{to}
          <Link onClick={this.onNext} className={currentIndex === 0 ? "disable" : ""}> <img src={require("../../images/icn_back_dark2.svg")} alt="" /> </Link>
        </div>
        <div className="styleChart hearChart" id="styleChart" onScroll={this.onScroll}>
          {keys ? keys.map(i => <DiaryChartWeek heartRateList={heartRateWeek[i]} maxHeartRate={maxHeartRate} />) : null}
        </div>
      </div>

    )
  }
}


export default DiaryChart;