import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Confetti from "react-dom-confetti";
import { sendMessage } from "../../ultils/ultils";
import html2canvas from "html2canvas";
import { WEBVIEW_ACTION, HTMLCANVAS_CONFIG } from "../../config/config";
import { DOMAIN_ICON, imgConfig } from "../../config/config";
import soundTest from "../../assets/applause.mp3";
import moment from "moment";
import imageToBase64 from "image-to-base64";
import { connect } from "react-redux";
class ModalAchievements extends Component {
  state = {
    confet: false,
    //base64Badge: null,
    showShareScreen: false,
    enableShareButton: true,
  };
  audio = new Audio(soundTest);
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.audio.play();
      this.setState({ confet: true });
    }
  };
  onClose = () => {
    this.audio.pause();
    this.audio.currentTime = 0;
    setTimeout(() => {
      this.props.onHide();
    }, 100);
    this.setState({ confet: false });
  };
  onShare = async (photo) => {
    const { os, appVersion } = this.props;
    let canShare = parseInt(appVersion) > 25;
    console.log({ canShare });
    if (canShare) {
      this.setState({ enableShareButton: false, showShareScreen: true }, () => {
        html2canvas(
          document.querySelector("#achievementPanel"),
          HTMLCANVAS_CONFIG
        ).then((canvas) => {
          const imgUrl = canvas.toDataURL("image/jpeg");
          console.log(imgUrl);
          sendMessage({
            type: WEBVIEW_ACTION.ON_SHARE,
            imgUrl: imgUrl,
            imgType: "BASE64",
          });
          this.setState({ showShareScreen: false, enableShareButton: true });
        });
      });
    }
  };

  render() {
    const config = {
      angle: "107",
      spread: "91",
      startVelocity: 45,
      elementCount: 50,
      dragFriction: 0.1,
      duration: 3000,
      stagger: 0,
      width: "10px",
      height: "10px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    };
    const { item, show, countedAchievement, dateAchievement } = this.props;
    const { showShareScreen, enableShareButton } = this.state;
    //console.log(item);
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg">
          <div className="page pageFeedBack">
            <div className="page-header">
              {showShareScreen ? null : (
                <Fragment>
                  <Link to="#" className="icon" onClick={this.onClose}>
                    {/* <img src={require("../../images/back.svg")} alt="" />
                Back */}
                  </Link>
                  <h4 className="adward">
                    <img src={require("../../images/larel.png")} alt="" />
                    Achievements
                    <img
                      className="rotate"
                      src={require("../../images/larel.png")}
                      alt=""
                    />
                  </h4>
                  <Link to="#" className="icon" onClick={this.onClose}>
                    <img src={require("../../images/ic_close.svg")} alt="" />
                  </Link>
                </Fragment>
              )}
            </div>

            <div className="privacy achievements">
              <div
                className={
                  showShareScreen ? "content achivementClass" : "content"
                }
                id="achievementPanel"
              >
                {showShareScreen ? (
                  <div className="logoShare">
                    <img src={require("../../images/logo.png")} />
                    <Link>www.nosweatfitnessapp.com</Link>
                  </div>
                ) : null}
                <div className="imgLarge">
                  <img
                    src={`${item ? DOMAIN_ICON + "/" + item.photo : ""}`}
                    alt=""
                  />
                </div>

                <h1>{item ? item.name : ""}</h1>
                <img
                  className="mt-0"
                  src={require("../../images/laurel_line.svg")}
                  alt=""
                />
                <p>
                  {item ? item.message : ""}
                  {item ? (
                    item.multiple ? (
                      <Fragment>
                        <span>
                          Number achieved: {countedAchievement[item.code]}
                        </span>
                        <span>
                          {`Awarded on ${moment(
                            dateAchievement[item.code]
                          ).format("DD MMM YYYY")}`}
                        </span>
                      </Fragment>
                    ) : (
                      <span>
                        {`Awarded on ${moment(
                          dateAchievement[item.code]
                        ).format("DD MMM YYYY")}`}
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <Confetti active={this.state.confet} config={config} />
              <div class="button_2">
                <button
                  className="blue"
                  type="button"
                  disabled={!enableShareButton}
                  onClick={() => this.onShare(item.photo)}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.workOutList,
    ...state.images,
    ...state.profileReducer,
    ...state.sliderReducer,
    ...state.cardioReducer,
    ...state.assessmentReducer,
    ...state.eventsReducer,
    ...state.achievementsReducer,
  };
};
export default connect(mapStateToProps, {})(ModalAchievements);
