import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "./Menu/Menu";
import Photos from "./Photo/Photos";
import { getCurrentWorkOut } from "../store/actions/GetWorkOut";
import { getPhoto, uploadPhoto } from "../store/actions/Photo";
import { MENU, compressedFile, WEBVIEW_ACTION } from "../config/config";
import LoadingIcon from "./Loading/LoadingIcon";
import ImageTools from "../ultils/ImageTools/ImageTools.js";
import StepsChart from "./StepsChart/StepsChart";
import moment from "moment";
import loadImage from "blueimp-load-image";
import { sendMessage } from "../ultils/ultils";
class Progress extends Component {
  state = {
    isActive: MENU.PROGRESS,
    fileUpload: null,
    isLoading: false,
    stepsList: null,
  };

  uploadPhoto = (event) => {
    const imageTool = new ImageTools();
    const { iosVersion, os } = this.props;
    let rotate = false;
    // if (os === "ios" && iosVersion >= 13.4) {
    //   rotate = false;
    // }
    this.setState({ isLoading: true });
    if (event.target.files[0]) {
      loadImage(event.target.files[0], {
        maxWidth: compressedFile.maxWidthOrHeight,
        maxHeight: compressedFile.maxWidthOrHeight,
        orientation: true,
        canvas: true,
      })
        .then((data) => {
          console.log({ dataImage: data });
          const image = imageTool.canvastoBlob(data.image, "image/png");
          this.setState({ fileUpload: "", isLoading: false });
          this.props.uploadPhoto(image.blob);
        })
        .catch((err) => {
          console.log({ uploadImageError: err });
          this.setState({ isLoading: false });
        });
    }
    // if (event.target.files[0]) {
    //   imageTool
    //     .resize(
    //       event.target.files[0],
    //       {
    //         width: compressedFile.maxWidthOrHeight,
    //         height: compressedFile.maxWidthOrHeight,
    //       },
    //       rotate
    //     )
    //     .then((img) => {
    //       this.setState({ fileUpload: "", isLoading: false });
    //       this.props.uploadPhoto(img);
    //     })
    //     .catch((err) => {
    //       this.setState({ isLoading: false });
    //     });
    // }

    // if (event.target.files[0]) {
    //   try {
    //     this.setState({ fileUpload: '', isLoading: false })
    //     this.props.uploadPhoto(event.target.files[0]);
    //   } catch (error) {
    //     alert(JSON.stringify(error))
    //     this.setState({ isLoading: false })
    //   }
    // }
  };
  sendDate = (startDate, endDate) => {
    /* console.log('start Date', startDate)
    console.log('end Date', endDate) */
    sendMessage({
      type: WEBVIEW_ACTION.GET_STEPS,
      startDate: startDate,
      endDate: endDate,
    });
  };

  componentDidMount = async () => {
    const { images } = this.props;

    if (images.length === 0) {
      this.props.getPhoto();
    }
    await this.props.getCurrentWorkOut();

    this.setState({ stepsList: null });
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentWorkOut !== prevProps.currentWorkOut) {
      window.WebViewBridge = {
        get_steps: this._onMessage,
      };
      const event = new Event("WebViewBridge");
      window.dispatchEvent(event);
      const { images } = this.props;
      let newList =
        images.length > 0
          ? images.reduce((r, a) => {
              r[a.date] = r[a.date] || [];
              r[a.date].push(a);
              return r;
            }, {})
          : null;
      if (newList) {
        let keys = Object.keys(newList);
        let startDate = keys[keys.length - 1];
        let endDate = keys[0];
        this.sendDate(
          startDate,
          endDate === startDate ? new Date().toISOString() : endDate
        );
        //this.sendDate(startDate, endDate)
      }
    }
  }
  _onMessage = (data) => {
    this.setState({ stepsList: data.steps });
  };
  render() {
    const { images } = this.props;
    const { isLoading, stepsList } = this.state;
    let newList =
      images.length > 0
        ? images.reduce((r, a) => {
            r[a.date] = r[a.date] || [];
            r[a.date].push(a);
            return r;
          }, {})
        : null;
    return (
      <div className="fadeIn">
        <div className="page  page-header-progress">
          <div className="page-header">
            <div className="bar-progress">
              <a className="icon" />
              <h4>Progress</h4>

              <a className="icon">
                <input
                  onChange={this.uploadPhoto}
                  type="file"
                  className="file"
                  accept="image/*"
                  value={this.state.fileUpload || ""}
                />
                <img
                  className="icon_camera"
                  src={require("../images/ic_camera.svg")}
                  alt=""
                />
              </a>
            </div>
            <div className="nav_progress">
              <Link to="#" className="active">
                Photos
              </Link>
              <Link to="/diary">Workout Log</Link>
            </div>
          </div>

          <div className="page-content">
            {/* stepsList ? <StepsChart data={stepsList} /> : null */}
            {/* <StepsChart /> */}

            {newList ? (
              Object.keys(newList).map((key) => {
                return (
                  <div key={key} className="photos">
                    <Photos
                      date={key}
                      steps={stepsList}
                      images={newList[key]}
                    />
                  </div>
                );
              })
            ) : (
              <div className="empty__images">
                {" "}
                <img src={require("../images/emptyphoto.svg")} alt="" />{" "}
              </div>
            )}
          </div>
          <div className="page-footer">
            <div className="menu">
              <Menu isActive={this.state.isActive} />
            </div>
          </div>
        </div>
        {isLoading ? <LoadingIcon /> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.images,
    ...state.workOutList,
  };
};
export default connect(
  mapStateToProps,
  { getPhoto, uploadPhoto, getCurrentWorkOut }
)(Progress);
