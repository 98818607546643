import * as actionTypes from './actionTypes';

export const getExerciseReps = data => dispatch => {
  return dispatch({
    type: actionTypes.GET_EXCERCISE_REPS,
    data: data
  })
}

export const nextExercise = data => dispatch => {
  return dispatch({
    type: actionTypes.NEXT_EXCERCISE,
    data: data
  })
}

export const countProgress = data => dispatch => {
  return dispatch({
    type: actionTypes.COUNT_PROGRESS,
    data: data
  })
}

export const switchStatus = (status) => dispatch => {
  return dispatch({
    type: actionTypes.SWITCH_STATUS,
    data: status
  })
}

export const onBack = () => dispatch => {
  return dispatch({
    type: actionTypes.ON_BACK
  })
}

export const clearProgress = () => dispatch => {
  return dispatch({
    type: actionTypes.CLEAR_PROGRESS
  })
}