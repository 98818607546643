import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import StepChartWeek from "./StepsChartWeek";
import { Accordion, Card } from "react-bootstrap";
/* const list = [
  { key: "16/06", value: 370 },
  { key: "15/06", value: 421 },
  { key: "14/06", value: 462 },
  { key: "13/06", value: 369 },
  { key: "12/06", value: 691 },

] */
let lastScrollY = 0;
class StepsChart extends Component {
  state = {
    currentIndex: 0,
    count: 0,
    totalDiv: 0,
  };
  componentDidMount() {
    let div = document.getElementById("styleChart");
    let totalDiv = div.scrollLeft;
    this.setState({ totalDiv: totalDiv });
  }
  onNext = () => {
    let div = document.getElementById("styleChart");
    let deviceWidth = div.offsetWidth;
    let { currentIndex } = this.state;
    const { stepsList } = this.props;
    currentIndex > 0
      ? this.setState({ currentIndex: currentIndex - 1 })
      : this.setState({ currentIndex: 0 });
    let lastIndex = stepsList[currentIndex].length;
    let point = Math.floor(deviceWidth / 7);
    let scrollTo = point * lastIndex;
    if (lastIndex < 7) {
      if (div.scrollLeft >= 0) {
        div.scrollLeft += scrollTo;
      } else {
        div.scrollLeft += scrollTo;
      }
    } else {
      div.scrollLeft += deviceWidth;
    }
    /* const { stepsList } = this.props
    currentIndex > 0
      ? this.setState({ currentIndex: currentIndex - 1 })
      : this.setState({ currentIndex: 0 })
    let divScroll = div.scrollLeft;
    let isGap = divScroll / deviceWidth
    if (isGap) {
      let spaceScroll = Math.ceil(isGap)
      let scroll = deviceWidth * spaceScroll
      div.scrollLeft = scroll + deviceWidth
    } else {
      div.scrollLeft += deviceWidth
    } */
  };
  onBack = () => {
    let div = document.getElementById("styleChart");
    let deviceWidth = div.offsetWidth;
    let { currentIndex } = this.state;
    const { stepsList } = this.props;
    div.scrollLeft += deviceWidth * -1;
    currentIndex < stepsList.length - 1
      ? this.setState({ currentIndex: currentIndex + 1 })
      : this.setState({ currentIndex: stepsList.length - 1 });
    let lastIndex = stepsList[currentIndex].length;
    /* currentIndex < stepsList.length - 1
      ? this.setState({ currentIndex: currentIndex + 1 })
      : this.setState({ currentIndex: stepsList.length - 1 })
    let divScroll = div.scrollLeft;
    let isGap = divScroll / deviceWidth
    if (isGap) {
      let spaceScroll = Math.ceil(isGap)
      let scroll = deviceWidth * spaceScroll
      div.scrollLeft = scroll - deviceWidth
    } else {
      div.scrollLeft += (deviceWidth * -1)
    } */
  };
  onScroll = () => {
    // let div = document.getElementById("styleChart")
    // let deviceWidth = div.offsetWidth;
    // let divScroll = div.scrollLeft;
    // const { currentIndex } = this.state;
    // const { stepsList } = this.props;
    // if (divScroll < 0) {
    //   let scrollGap = Math.abs(divScroll / deviceWidth)
    //   switch (true) {
    //     case scrollGap > currentIndex + 1 && scrollGap < currentIndex + 1.1:
    //       this.setState({ currentIndex: currentIndex + 1 })
    //       break;
    //     case scrollGap < (currentIndex > 0 ? currentIndex === 1 ? 0.3 : currentIndex - 1 : 0):
    //       this.setState({ currentIndex: currentIndex - 1 })
    //       break;
    //     case scrollGap > ((stepsList.length - 2) + 0.1):
    //       this.setState({ currentIndex: stepsList.length - 1 })
    //       break
    //     default:
    //       break;
    //   }
    // } else {
    //   const { totalDiv } = this.state;
    //   let divGap = totalDiv / divScroll
    //   if (divGap > currentIndex + 1.3 && divGap < currentIndex + 1.5) {
    //     this.setState({ currentIndex: currentIndex + 1 })
    //   }
    //   if (divGap > ((stepsList.length - 2) + 0.1)) {
    //     this.setState({ currentIndex: stepsList.length - 1 })
    //   }
    //   if (divGap < (currentIndex > 0 ? currentIndex === 1 ? 1.3 : (currentIndex - 1) + 0.5 : 0)) {
    //     this.setState({ currentIndex: currentIndex - 1 })
    //   }
    // }
    // alert(totalDiv)
    // alert(div.scrollLeft)
    /* let scrollGap = Math.abs(divScroll / deviceWidth);
    switch (true) {
      case scrollGap > currentIndex + 1 && scrollGap < currentIndex + 1.1:
        this.setState({ currentIndex: currentIndex + 1 })
        break;
      case scrollGap < (currentIndex > 0 ? currentIndex === 1 ? 0.3 : currentIndex - 1 : 0):
        this.setState({ currentIndex: currentIndex - 1 })
        break;
      case scrollGap > ((stepsList.length - 2) + 0.1):
        this.setState({ currentIndex: stepsList.length - 1 })
        break
      default:
        break;
    } */
  };
  render() {
    const { currentIndex } = this.state;
    const { stepsList } = this.props;
    let keys = Object.keys(stepsList);
    let maxSteps = 0;
    stepsList.map((items) => {
      items.map((item) => {
        if (item.value > maxSteps) {
          maxSteps = item.value;
        }
      });
    });
    let averageList = [];
    stepsList.forEach((list) => {
      let totalSteps = list.reduce(
        (sum, x) => (sum = x.value ? (sum += x.value) : (sum += 0)),
        0
      );
      let averageSteps = Math.round(totalSteps / list.length);
      let from = list[list.length - 1].id;
      let to = list[0].id;
      let item = {
        steps: averageSteps,
        from: Moment(from).format("DD MMM YYYY"),
        to: Moment(to).format("DD MMM, YYYY"),
      };
      averageList.push(item);
    });
    let from = averageList[currentIndex].from;
    let to = averageList[currentIndex].to;
    let fromMonth = Moment(from).format("MMM");
    let toMonth = Moment(to).format("MMM");
    const {
      isScheduleWorkoutPanelOpen,
    } = this.state;
    if (fromMonth === toMonth) {
      from = Moment(from).format("DD");
    } else {
      from = Moment(from).format("DD MMM");
    }
    return (
     
      <div className="accordion-ct mt-2">
     
      <Accordion>
        <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    onClick={() => {
                      this.setState({
                        isScheduleWorkoutPanelOpen: !isScheduleWorkoutPanelOpen,
                      });
                    }}
                  >
           <div
                      className={`schedule-title ${
                        isScheduleWorkoutPanelOpen ? "open" : "hide"
                      }`}
                    >
            <div className="kmTotalChart ">
              Average <span>{averageList[currentIndex].steps} steps</span>
              <p>
                {from}-{to}
              </p>
            </div>
            <img src={require("../../images/step_counter.svg")} alt="" />
         </div>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="1">
          <div>
            <div className="daysTotalChart">
              <Link
                onClick={this.onBack}
                className={
                  currentIndex === stepsList.length - 1 ? "disable" : ""
                }
              >
                <img src={require("../../images/icn_back_dark.svg")} alt="" />{" "}
              </Link>
              {from}-{to}
              <Link
                onClick={this.onNext}
                className={currentIndex === 0 ? "disable" : ""}
              >
                {" "}
                <img
                  src={require("../../images/icn_back_dark2.svg")}
                  alt=""
                />{" "}
              </Link>
            </div>
            <div
              className="styleChart"
              id="styleChart"
              onScroll={this.onScroll}
            >
              {keys
                ? keys.map((i) => (
                    <StepChartWeek
                      stepsList={stepsList[i]}
                      maxSteps={maxSteps}
                    />
                  ))
                : null}
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
      </div>
    );
  }
}

export default StepsChart;
