import React, { Component } from "react";

export class RecipesSliderItem extends Component {
  openRecipeLink = (link) => {
    try {
      console.log({ link });
    } catch (error) {}
  };
  render() {
    var { image, title, link } = this.props;
    return (
      <>
        <div className="image">
          <img src={image} alt="" />
        </div>
        <p> {title} </p>
      </>
    );
  }
}

export default RecipesSliderItem;
