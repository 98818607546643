import * as actionTypes from "./actionTypes";
import { API } from "../../config/config";
import { headers } from "../../ultils/helpers";

export const postUserInfo = (userInfo) => (dispatch) => {
  return dispatch({
    type: actionTypes.POST_USER_INFO,
    data: userInfo,
  });
};

export const getUser = () => (dispatch) => {
  /* fetch(API.GET_USER_API, {
    headers: headers
  })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: actionTypes.GET_USERNAME,
        data: data
      })
    ); */

  return new Promise((resolve, reject) => {
    fetch(API.GET_USER_API, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: actionTypes.GET_USERNAME,
          data: data,
        });
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};
export const updateRatingStatus = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_RATING_STATUS_API, {
      headers: headers,
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const setUserExpired = (userExpired) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_USER_EXPIRED,
    data: userExpired,
  });
};
export const checkExpired = (isExpired) => (dispatch) => {
  return dispatch({
    type: actionTypes.CHECK_EXPIRED,
    data: isExpired,
  });
};

export const getUserEmail = (token, pinNumber) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.RESET_PASSWORD_API, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ token: token, pinNumber: pinNumber }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const updatePassword = (email, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_PASSWORD_API, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const updateUserAssessment = (userAssessment) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_USER_ASSESSMENT_API, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ userAssessment: userAssessment }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const checkUserPassword = (email, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.CHECK_PASSWORD_API, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
