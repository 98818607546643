import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "./css/bootstrap.min.scss";
import "./css/style.scss";
import "./css/fronts.scss";
import Index from "./containers/Index";
import { Link } from "react-router-dom";
import "./App.css";
import { Button } from "react-bootstrap";
import "./css/slick/slick.min.scss";
import "./css/slick/slick-theme.min.scss";
import moment from "moment";
class App extends Component {
  state = {
    arr: [],
    repsArr: [],
    repsIndex: 0,
    point: null,
    next: false,
    isCounting: true,
    interval: null,
  };
  componentDidMount() {
    window.location.href = "http://nosweatfitnessapp.com/";
  }

  render() {
    return (
      <div>
        <h1 />
      </div>
    );
  }
}

export default withRouter(App);
