import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import YoutubeWorkout from "../Workout/YoutubeWorkout";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import SliderItem from "./SliderItem";
import LiveEvent from "../LiveEvents/LiveEvent";
import { sendMessage } from "../../ultils/ultils";
import moment from "moment";
class MultipleItems extends Component {
  state = {
    hideBanner: false,
    hideBackground: false,
  };
  playVideo = () => {
    this.videoObj.playVideo();
  };
  onReady = (e) => {
    //alert('ready');
    this.videoObj = e.target;
  };
  componentDidMount() {
    const { user } = this.props;
    //console.log(user);
    // if (user.allWorkoutHistory == 1) {
    //   console.log(user.allWorkoutHistory);
    //   const data = { type: WEBVIEW_ACTION.ON_NOTIFICATION };
    //   sendMessage(data);
    // }
  }
  onClickVideo = () => {
    if (this.videoObj) {
      this.setState({ hideBanner: true });
      this.videoObj.playVideo();
    }
    // console.log(this.videoObj)
    // this.videoObj.playVideo();
  };
  goToUpgrade = () => {
    this.props.history.push("/Upgrade");
  };
  onEndVideo = () => {
    //console.log('on End Video')
    this.setState({ hideBanner: false });
  };
  pauseVideo = async () => {
    await this.videoObj.pauseVideo();
    //this.setState({ hideBanner: false });
  };
  onClick = () => {
    const { app_url } = this.props;
    const data = { type: WEBVIEW_ACTION.SEND_URL, url: app_url };
    sendMessage(data);
  };
  render() {
    const {
      isExpired,
      sliders,
      disabled,
      user,
      liveEvents,
      isUpdated,
      showEvents,
      os,
      appVersion,
    } = this.props;
    const { total_days_purchase } = user;
    const onSchedule = total_days_purchase && !isExpired;
    const freeLiveEvents =
      liveEvents && liveEvents.length > 0
        ? liveEvents.filter((event) => !event.subscribers_only)
        : null;
    //console.log(liveEvents);
    //console.log({ freeLiveEvents })
    //console.log('isExpire Slider', isExpired)
    const { hideBanner } = this.state;
    const settings3 = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: false,
    };

    const opts = {
      // height: '390',
      width: "100%",
      playerVars: {
        playsinline: 1,
        autoplay: 0,
        controls: 1,
        rel: 0,
        modestbranding: 0,
        showinfo: 0,
        autohide: 1,
        enablejsapi: 1,
        html5: 1,
      },
    };
    let sliderBanner;
    let showBannerAlert = false;
    let dayExpiredLeft = moment(user.expired_time).diff(moment(), "days");
    let isExpiring = dayExpiredLeft <= 7 && dayExpiredLeft >= 0;
    let nonPayUserExpired = !user.transactionId && isExpired;
    let paidUserExpired = user.transactionId && isExpired;
    let paidUserIsExpiring = user.transactionId && isExpiring;
    let isSubscription = user.isSubscription;
    // dayExpiredLeft = 1
    // nonPayUserExpired = true
    // paidUserExpired = false
    // paidUserIsExpiring = false
    // console.log({ dayExpiredLeft, isExpiring });
    // console.log({ user });
    if (sliders && sliders.length > 0) {
      const { reg_date } = user;
      if (
        moment().diff(moment(reg_date), "weeks") > 1 ||
        user.allWorkoutHistory > 1
      ) {
        //console.log(user);
        sliderBanner = sliders.filter((item) => item.link !== "WGVSMot3O4I");
        //console.log(sliderBanner);
      } else {
        sliderBanner = sliders;
      }
    }
    if (nonPayUserExpired || paidUserExpired || paidUserIsExpiring) {
      showBannerAlert = true;
    }
    return (
      <div>
        <Slider {...settings3} className="slider">
          {sliderBanner && sliderBanner.length > 0
            ? sliderBanner.map((item, i) => <SliderItem key={i} data={item} />)
            : null}
        </Slider>

        <div className="button_2 ">
          <div className="button_index">
            <button
              onClick={this.props.onClick}
              disabled={disabled}
              //className={isExpired ? "disable" : ""}
              type="button"
            >
              CURRENT PROGRAMME
            </button>
            {os && appVersion >= 33 && (
              <button
                onClick={this.props.goToChallenge}
                //className={isExpired ? "disable" : ""}
                type="button"
                style={{ background: "#c99976" }}
              >
                TRY SOMETHING NEW
              </button>
            )}
          </div>

          {showBannerAlert ? (
            <Link
              to={isUpdated ? "/Upgrade" : "#"}
              //className="alert joinMember2"
              className={`${
                paidUserIsExpiring && isSubscription
                  ? "alert joinMember2"
                  : "alert joinMember"
              } `}
              role="alert"
            >
              <img
                src={require(`../../images/${
                  paidUserIsExpiring && isSubscription ? "alert_green" : "alert"
                }.png`)}
                alt=""
              />
              <div className="content">
                {nonPayUserExpired ? (
                  <Fragment>
                    <h3>Join Membership</h3>
                    Your trial has expired but your fitness journey has just
                    begun! Tap here to start a plan.
                  </Fragment>
                ) : null}
                {paidUserIsExpiring ? (
                  <Fragment>
                    <h3>Continue Your Fitness Journey</h3>
                    {`${
                      isSubscription
                        ? "Just a reminder that your plan will auto-renew"
                        : "Your plan is expiring"
                    } ${
                      dayExpiredLeft > 0 ? `in ${dayExpiredLeft} ` : "today"
                    }${
                      dayExpiredLeft > 1
                        ? "days"
                        : dayExpiredLeft > 0
                        ? "day"
                        : ""
                    }${
                      isSubscription
                        ? ". You've come sooo far and there's a lot more you can do! Keep going!"
                        : ", but you've come sooo far and there's a lot more you can do! Tap here to extend your plan."
                    }`}
                  </Fragment>
                ) : null}
                {paidUserExpired ? (
                  <Fragment>
                    <h3>Keep Going</h3>
                    {`Your plan has expired, but your fitness journey never ends! Let’s continue working together. Tap here to renew your plan.`}
                  </Fragment>
                ) : null}
              </div>
            </Link>
          ) : null}
        </div>
        {isUpdated === false ? (
          <div className="button-newversion">
            <p>A new version of this app is available with improved features</p>
            <div className="button">
              <button type="button" onClick={this.onClick}>
                UPDATE NOW
              </button>
            </div>
          </div>
        ) : null}
        <div style={{ display: "flex", overflowX: "scroll" }}>
          {showEvents
            ? onSchedule && liveEvents && liveEvents.length > 0
              ? liveEvents
                  .slice(0, 2)
                  .map((event) => <LiveEvent key={event.id} event={event} />)
              : freeLiveEvents && freeLiveEvents.length > 0
              ? freeLiveEvents
                  .slice(0, 2)
                  .map((event) => <LiveEvent key={event.id} event={event} />)
              : null
            : null}
        </div>
        {/* {liveEvents && liveEvents.length > 0 ? liveEvents.map(event => <LiveEvent event={event} onSchedule={onSchedule} />) : null} */}
        {isExpired && !total_days_purchase ? (
          <div className="youtubeExpired">
            <div className="embed-responsive embed-responsive-16by9">
              <YouTube
                opts={opts}
                videoId="3A_FV9MWwOc"
                onReady={this.onReady}
                onPause={this.pauseVideo}
                onEnd={this.onEndVideo}
                allowFullScreen
              />
              {/* <iframe
                  key={videoDetails.youtube_id}
                  src={`${videoUrl}/${videoDetails.youtube_id}?playsinline=1`}
                  frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen>
                </iframe> */}
            </div>
            <div
              className="img_slider"
              onClick={this.onClickVideo}
              style={
                !hideBanner
                  ? {
                      backgroundImage: `url(${require("../../images/End-of-Trial-Week-Message.jpg")})`,
                    }
                  : { display: "none" }
              }
            >
              <img src={require("../../images/playf2.svg")} alt="back" />
            </div>
          </div>
        ) : null}
        {isExpired && total_days_purchase ? (
          <div className="youtubeExpired">
            <div className="embed-responsive embed-responsive-16by9">
              <YouTube
                opts={opts}
                videoId="rX9mKRh90Zo"
                onReady={this.onReady}
                onPause={this.pauseVideo}
                onEnd={this.onEndVideo}
              />
            </div>
            <div
              className="img_slider"
              onClick={this.onClickVideo}
              style={
                !hideBanner
                  ? {
                      backgroundImage: `url(${require("../../images/End-of-Subscription.jpg")})`,
                    }
                  : { display: "none" }
              }
            >
              <img src={require("../../images/playf2.svg")} alt="back" />
            </div>
          </div>
        ) : null}
        {/* <div className="youtubeExpired">
          <div className="embed-responsive embed-responsive-16by9">
            <YouTube
              opts={opts}
              videoId="3A_FV9MWwOc"
              onReady={this.onReady}
              onPause={this.pauseVideo}
            />
          </div>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.workOutList,
  };
};
export default connect(
  mapStateToProps,
  {}
)(MultipleItems);
