import * as actionTypes from "../actions/actionTypes";

const initialState = {
  workOutList: null,
  isLoading: true,
  week: null,
  workOutId: null,
  currentWorkOut: null,
  done: false,
  os: null,
  appVersion: null,
  iosVersion: null,
  message: null,
  isFullBodyWorkout: false,
  isCustomWorkout: false,
};

const workOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WORKOUT_BY_TYPE:
      return {
        ...state,
        workOutList: action.workOutList,
        isLoading: false,
      };
    case actionTypes.GET_WEEK_WORKOUT:
      return {
        ...state,
        week: action.week,
      };
    case actionTypes.GET_WORKOUT_ID:
      return {
        ...state,
        workOutId: action.workOutId,
      };
    case actionTypes.GET_CURRENT_WORKOUT:
      return {
        ...state,
        currentWorkOut: action.data,
      };
    case actionTypes.SET_OS:
      return {
        ...state,
        os: action.data.deviceOS,
        appVersion: action.data.appVersion,
        iosVersion: action.data.iosVersion,
      };
    case actionTypes.SET_WORKOUT_DONE:
      return {
        ...state,
        done: action.data,
      };
    case actionTypes.GET_ALL_MESSAGE:
      return {
        ...state,
        message: action.data,
      };
    case actionTypes.CHANGE_FULL_BODY_WORKOUT:
      console.log({ actionData: action.data });
      return {
        ...state,
        isFullBodyWorkout: action.data,
      };
    case actionTypes.CHANGE_CUSTOM_WORKOUT:
      return {
        ...state,
        isCustomWorkout: action.data,
      };
    default:
      return state;
  }
};

export default workOutReducer;
