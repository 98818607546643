import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Microwave_steamed_egg_recipe extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Breakfast
        </div>

        <div className="recipesMain">
          <div className="image">
            <img
              src={require("../../../images/recipes/breakfast/01.jpg")}
              alt=""
            />
          </div>
          <p>
            10-min Microwave Steamed Egg
            <span> Serves: 1 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            2 eggs, beaten <br />
            120ml water <br />
            Spring onion (chopped) <br />
            Soy sauce (alternatives: tamari, amino acids) <br />
            <h3 className="title-main">What you need</h3>
            Cling-wrap
            <br />
            Microwave
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Beat the two eggs in a bowl.
              </li>
              <li>
                <span>2</span>
                Add half the chopped spring onion into the mixture.
              </li>
              <li>
                <span>3</span>
                Add in 120 ml of water and continue beating until the whites
                have fully incorporated with the yolk.
              </li>
              <li>
                <span>4</span>
                Transfer to the bowl or large cup that you will be microwaving
                the egg in.
              </li>
              <li>
                <span>5</span>
                Carefully skim away any bubbles on the surface of the egg
                mixture.
              </li>
              <li>
                <span>6</span>
                Cover with cling wrap and set your microwave to x and check back
                in x minutes.
              </li>
              <li>
                <span>7</span>
                To serve, add soy sauce to taste, and the remaining spring
                onions over the steamed egg.
              </li>
            </ul>
          </div>
        </div>

        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Microwave_steamed_egg_recipe;
