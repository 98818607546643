import * as actionTypes from "../actions/actionTypes";

const initialState = {
  Challenges: [],
  SelectedChallenge: null,
};

const challengeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CHALLENGES:
      return {
        ...state,
        Challenges: action.data,
      };
    case actionTypes.SET_SELECTED_CHALLENGE:
      return {
        ...state,
        SelectedChallenge: action.data,
      };
    default:
      return state;
  }
};

export default challengeReducer;
