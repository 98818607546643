import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllAssessment } from "../../store/actions/Profile";
import Moment from "moment";
class ModalAssessmentListDetail extends Component {
  render() {
    const {
      message,
      reply,
      date,
      beforePicture,
      afterPicture,
      profile_picture
    } = this.props;

    return (
      <div>
        <Modal
          className="modalProfileChild modalListAssessment"
          {...this.props}
          size="lg"
        >
          <div className="page pageFeedBack">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.props.onHide}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>{Moment(date).format("DD MMMM YYYY")}</h4>
              <Link to="#" className="icon" />
            </div>
            <div className="privacy">
              <div class="msgerChat">
                <div className="msgBubbleImage">
                  <div>
                    <h4>Before</h4>
                    <img src={beforePicture} alt="" />
                  </div>
                  <div>
                    <h4>After</h4>
                    <img src={afterPicture} alt="" />
                  </div>
                </div>
                <div class="msg msgLeft">
                  <div className="msgImage">
                    <img
                      src={
                        profile_picture
                          ? profile_picture
                          : require("../../images/user.svg")
                      }
                      alt=""
                    />
                  </div>
                  <div class="msgBubble">{message}</div>
                </div>
                {reply ? (
                  <div class="msg msgRight">
                    <div className="msgImage">
                      <img src={require("../../images/tyen.jpg")} alt="" />
                    </div>
                    <div class="msgBubble">{reply}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.userInfo,
    ...state.profileReducer
  };
};
export default connect(
  mapStateToProps,
  {}
)(ModalAssessmentListDetail);
