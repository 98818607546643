import React, { Component } from "react";
import { MENU } from "../../config/config";
import { connect } from "react-redux";
import ChallengeSliderProgramme from "./ChallengeSliderProgramme";
import LoadingIcon from "../Loading/LoadingIcon";
import Completed from "../Completed";
import {
  getChallengeWorkout,
  getChallengeMessages,
} from "../../store/actions/GetWorkOut";
import Back from "../Button/Back";
class ChallengeProgramme extends Component {
  state = {
    programmeStatus: [],
    currentIndex: 0,
    initialIndex: 0,
    isExpired: null,
    challengeWorkout: null,
    isLoading: true,
    item: null,
    challengeMessages: [],
  };

  onChange = (id) => {
    this.setState({ currentIndex: id });
  };
  onClick = async (week, workoutList) => {
    const { item, challengeMessages } = this.state;
    const message = challengeMessages.find((m) => m.week == week);
    this.props.history.push({
      pathname: `/ChallengeWorkout`,
      state: { week, workoutList, item, message },
    });
  };
  componentDidMount = async () => {
    const { Challenges, SelectedChallenge } = this.props;
    const item = Challenges.find((m) => m.id == SelectedChallenge);

    let challengeWorkout = await this.props.getChallengeWorkout(item.id);
    let challengeMessages = await this.props.getChallengeMessages(item.id);
    const programmeStatus = Object.keys(challengeWorkout)
      .map((key) => challengeWorkout[key])
      .reduce((acc, value) => {
        const count = value.filter((item) => item.historyId).length;
        const percent = (count / value.length) * 100;
        const enable = acc.length === 0 || acc[acc.length - 1].percent === 100;
        acc.push({ percent, enable });
        return acc;
      }, []);
    for (let i = 0; i < programmeStatus.length; i++) {
      if (programmeStatus[i].percent !== 100) {
        this.setState({ initialIndex: i });
        break;
      }
    }
    this.setState({
      challengeWorkout: challengeWorkout,
      programmeStatus: programmeStatus,
      isLoading: false,
      item: item,
      challengeMessages: challengeMessages,
    });

    // console.log({ challengeWorkout, programmeStatus, isLoading: false });
  };
  backToChallenge = () => {
    console.log({ state: this.props.location.state });
    this.props.history.push({
      pathname: `/ChallengeDetail`,
      state: this.props.location.state,
    });
  };
  render() {
    const {
      challengeWorkout,
      programmeStatus,
      currentIndex,
      initialIndex,
      isLoading,
      item,
    } = this.state;
    if (isLoading) {
      return <LoadingIcon />;
    } else {
      return (
        <div className="fadeIn">
          <div className="page">
            <div className="page-header">
              <Back onClick={this.backToChallenge} />
              <h4>CHALLENGES</h4>

              <a className="icon" />
            </div>
            <div className="page-content page-content-programme ChallengeProgramme">
              <Completed
                value={
                  programmeStatus[currentIndex]
                    ? programmeStatus[currentIndex].percent
                    : 0
                }
              />
              <div className="curentfocus">
                <p>{item.name}</p>
              </div>

              <div className="width_programme_slider">
                {challengeWorkout && (
                  <ChallengeSliderProgramme
                    workoutList={challengeWorkout}
                    onClick={this.onClick}
                    onChange={this.onChange}
                    programmeStatus={programmeStatus ? programmeStatus : []}
                    index={initialIndex}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.challenge,
  };
};
export default connect(
  mapStateToProps,
  { getChallengeWorkout, getChallengeMessages }
)(ChallengeProgramme);
