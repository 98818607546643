import * as actionTypes from "./actionTypes";
import { API } from "../../config/config";
import { headers } from "../../ultils/helpers";

export const setProductList = (productList) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_PRODUCT_LIST,
    data: productList,
  });
};
export const clickUpgradeButton = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.CLICK_UPGRADE_BUTTON_API, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ id })
    })
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        reject(err)
      });
  });
};
