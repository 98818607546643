import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Tomato_egg_drop_noodle_soup extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
         Meals
        </div>

        <div className="recipesMain">
          <div className="image">
            <img src={require("../../../images/recipes/lunch/01.jpg")} alt="" />
          </div>
          <p>
            Tomato Egg Drop Noodle Soup
            <span> Serves: 2 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            3 eggs
            <br />
            4 medium-sized tomatoes
            <br />
            Soy sauce
            <br />
            300g spinach noodles
            <br />
            2 tbsp soy sauce
            <br />
            500ml water
            <br />
            2 stalks of spring onion (optional)
            <br />
            <h3 className="title-main">What you need</h3>
            1 wok (or a medium-sized pot)
            <br />
            1 pot
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Beat the eggs together, and cut each tomato into 6 wedges.
              </li>
              <li>
                <span>2</span>
                In a well-oiled wok or a pot, add the egg mixture when the pan
                is hot. Wait for 20-30 seconds, allowing the egg to form a nice
                light brown crust, before scrambling it.
              </li>
              <li>
                <span>3</span>
                Add the tomatoes and soy sauce when the eggs are cooked, and
                saute for 1-2 minutes until the tomatoes are soft.
              </li>
              <li>
                <span>4</span>
                Add 500ml of water, and cover with a lid. After it comes to a
                boil, lower the fire to let it simmer for another 5 minutes.
                While waiting, blanch the spinach noodles in a pot of boiling
                water, and drain when done.
              </li>
              <li>
                <span>5</span>
                Add more salt or water if needed, depending on how soupy you
                want your dish to be.
              </li>
              <li>
                <span>6</span>
                Pour the soup over the cooked noodles, and serve with a topping
                of spring onion.
              </li>
            </ul>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Tomato_egg_drop_noodle_soup;
