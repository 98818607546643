import React, { Component } from 'react'

export class LinksYoutube extends Component {
  render() {
    return (
      <div className="video" style={{width:'100vw',height:'133vw'}}>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HW3nzPscgCE"></iframe>
      </div>
    )
  }
}

export default LinksYoutube
