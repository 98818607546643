import React, { Component } from "react";

export class ButtonDownload extends Component {
  render() {
    return (
      <div class="button">
        <button
          onClick={() => window.open("http://nosweatfitnessapp.com/", "_blank")}
          type="button"
        >
          DOWNLOAD NO SWEAT FITNESS APP
        </button>
      </div>
    );
  }
}

export default ButtonDownload;
