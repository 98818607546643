import Cookies from 'js-cookie'

export const headers = {
    'Accept': 'application/json',
    'Authorization': Cookies.get('AuthorizationToken'),
    'Content-Type': 'application/json'
}
export const uploadHeaders = {
    
    'Authorization': Cookies.get('AuthorizationToken')
}