import React, { Component, Fragment } from 'react';
import { WEBVIEW_ACTION } from "../../config/config";
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import Moment from "moment";
import { sendMessage } from '../../ultils/ultils';
import moment from 'moment';
const data = [
  { min: 80, max: 100 },
];
class DiaryItemChart extends Component {
  state = {
    data: null,
    heartRate: null
  }
  componentDidMount() {
    let { startDate, endDate, id, os } = this.props;
    if (os === 'ios') {
      this.sendDate(startDate, endDate === startDate ? new Date().toISOString() : moment(endDate).toISOString(), id)
    }
  }
  componentDidUpdate() {
    return true;
  }
  sendDate = (startDate, endDate, id) => {
    const data = {
      type: WEBVIEW_ACTION.GET_HEART_RATE,
      startDate: startDate,
      endDate: endDate, id: id
    }
    sendMessage(data)
  }
  render() {
    const { data } = this.props;
    return (
      <Fragment>
        {data && data.heartRate.length > 0 ? <div className="heartMinMax">
          {`Min ${(data.heartRate[0].min)}`} <img src={require('../../images/ic_heart.svg')} alt="heart" class="heartBeat" /> {`Max ${(data.heartRate[0].max)}`}
        </div> : null}
      </Fragment>

    )
  }
}


export default DiaryItemChart;