import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Lemongrass_chicken_salad extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Meals
        </div>

        <div className="recipesMain">
          <div className="image">
            <img src={require("../../../images/recipes/lunch/03.jpg")} alt="" />
          </div>
          <p>
          Lemongrass Chicken Salad
            <span> Serves: 4 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            2 boneless chicken thighs <br />
            0.5 Carrot, julienned
            <br />
            1 Cucumber, de-seeded and julienned
            <br />
            2 stalks of coriander
            <br />
            Mint leaves
            <br />
            1 handful beansprouts, blanched
            <br />
            1 cup lettuce, chopped
            <br />
            7-8 cherry tomatoes
            <br />
            Rice Vermicelli, 1 portion
            <br />
            <div className="ttchild">For the marinade</div>
            4 stalks lemongrass, crushed
            <br />
            1 tbsp fish sauce
            <br />
            1 tsp sesame oil
            <br />
            1 tsp minced garlic (or garlic paste)
            <br />
            1 tsp minced ginger (or ginger paste)
            <br />
            <div className="ttchild">For the sauce</div>
            1.5 tbsp rice vinegar
            <br />
            1 chilli padi, deseeded and chopped
            <br />
            1 clove minced garlic
            <br />
            1 tbsp brown sugar (or any other sugar substitute)
            <br />
            0.5 tbsp fish sauce
            <br />
            1 tbsp soy sauce
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Marinate the chicken with everything in the marinade for at
                least 30 minutes, or even overnight in the fridge.
              </li>
              <li>
                <span>2</span>
                Cut all your vegetables into long thin strips of around 5cm.
              </li>
              <li>
                <span>3</span>
                Blanch the beehoon and set aside
              </li>
              <li>
                <span>4</span>
                For the chicken, sear on a non stick pan for around 3 minutes on
                each side. Try not to move it around too much so it will develop
                a nice colour. Then, slice into thick pieces and set aside to
                cool
              </li>
              <li>
                <span>5</span>
                Combine all the ingredients for the sauce and mix well. Taste it
                and adjust the flavour according to your preferences. At this
                point it should taste quite strong and salty as it is supposed
                to flavour your entire salad.
              </li>
              <li>
                <span>6</span>
                Plate all your ingredients onto a deep dish or bowl of your
                choice, then serve with the sauce by the side.
              </li>
            </ul>
          </div>
        </div>

        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Lemongrass_chicken_salad;
