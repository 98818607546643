import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { access } from "fs";
class SliderProgrammeContent extends Component {
  state = {
    workOutOption: null
  };
  componentDidMount() {
    const { workoutItem, percent } = this.props;
    if (workoutItem) {
      const workOutLength = workoutItem.length;
      const workOutStatus = workoutItem.filter(item => item.historyId !== null)
        .length;
      //console.log('workoutStatus', percent)
      switch (true) {
        case percent === 100:
          this.setState({ workOutOption: "START AGAIN" });
          break;
        case percent > 0:
          this.setState({ workOutOption: "CONTINUE" });
          break;
        default:
          this.setState({ workOutOption: "START" });
          break;
      }
    }
  }

  render() {
    const {
      workoutItem,
      week,
      isExpired,
      enable,
      expired,
      cardioItem,
      totalDays,
      isEnable,
      percent,
      listCardio
    } = this.props;
    let workoutChecked = workoutItem
      .filter(item => item.historyId)
      .reduce((acc, cur) => {
        acc.push(cur.id);
        return acc;
      }, []);
    //console.log({ workoutChecked })
    //console.log('list', listCardio)
    //console.log(percent)
    let cardioArr = [],
      cardioChecked = [];
    if (cardioItem) {
      //console.log({ cardioItem })
      const { session } = cardioItem;
      for (let i = 1; i <= session; i++) {
        cardioArr.push(i);
      }
    }
    //console.log('session', listCardio)
    if (listCardio) {
      for (let i = 1; i <= listCardio.cardioSession; i++) {
        cardioChecked.push(i);
      }
    }
    //console.log({ cardioChecked })
    let canAccess = false;
    if (parseInt(week) === 0) {
      canAccess = true;
    } else {
      if (totalDays && !isExpired) {
        canAccess = true;
      } else {
      }
    }
    //let canAccess = parseInt(week) === 0 ? true : !isExpired;
    //console.log("programme week", week)
    const { workOutOption } = this.state;
    return (
      <div>
        {workoutItem ? (
          <div className="item">
            <div className="content_programme">
              <h4>{week >= 1 ? `Week ${week}` : `FREE Trial Week`}</h4>
              {!canAccess ? (
                <span className="lock">
                  <img
                    onClick={this.props.onUpgrade}
                    src={require("../../images/lock.svg")}
                    alt=""
                  />
                </span>
              ) : null}

              <div className="name_programme">
                <div>
                  <p>Programme</p>
                  {workoutItem.map((item, index) => (
                    <span key={item.id}>
                      Workout {index + 1} - {item.description}{" "}
                      {workoutChecked.indexOf(item.id) !== -1 ? (
                        <img src={require("../../images/check.svg")} alt="" />
                      ) : null}
                    </span>
                  ))}
                </div>
                {cardioItem ? (
                  <div>
                    <p>Cardio</p>
                    {cardioArr.length > 0
                      ? cardioArr.map(index => (
                          <span key={index}>
                            Session {index}{" "}
                            {cardioChecked.indexOf(index) !== -1 ? (
                              <img
                                src={require("../../images/check.svg")}
                                alt=""
                              />
                            ) : null}{" "}
                          </span>
                        ))
                      : null}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="button">
              <button
                onClick={this.props.onClick.bind(this, week)}
                className={
                  isEnable && canAccess ? "button-w220" : "button-w220 disable"
                }
                type="button"
                disabled={!isEnable || !canAccess}
              >
                {percent && percent === 100
                  ? "START AGAIN"
                  : percent > 0
                  ? "CONTINUE"
                  : "START"}
              </button>
              {/* <Link to={`/Workout`}></Link> */}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.userInfo,
    ...state.cardioReducer
  };
};
export default connect(mapStateToProps, {})(SliderProgrammeContent);
