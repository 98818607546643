import React, { Component, Fragment } from "react";
import Player from "../Player";
import NameworkVideo from "./NameworkVideo";
import Resttime from "../Resttime";
// import YouTube from '@u-wave/react-youtube';
// import YouTube from 'react-youtube';
import YouTube from "react-youtube";
import Moment from "moment";
import { connect } from "react-redux";
import { getVideo } from "../../store/actions/GetVideo";
import {
  getExerciseReps,
  nextExercise,
  countProgress,
  clearProgress,
  onBack
} from "../../store/actions/Exercise";
import soundIcon from "../../images/ic_sound.svg";
import muteIcon from "../../images/ic_sound_mute.svg";
import closeIcon from "../../images/ic_close.svg";
import ModalRate from "../Modal/ModalRate";
import { rateType, exerciseType, WEBVIEW_ACTION } from "../../config/config";
import ModalwthWorkout from "../Modal/ModalwthWorkout";
import ProgressBar from "../ProgressBar";
import Back from "../Button/Back";
export class DemoVideo extends Component {
  state = {
    //videoObj: null,
    isPlaying: false,
    isEnd: false,
    isStopped: true,
    isMute: false,
    showResttimePage: false,
    showWorkoutRate: false,
    duration: null,
    isFinished: 1,
    progress: null,
    videos: [],
    currentIndex: 0,
    percent: null
  };
  PlayerState = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    CUED: 5
  };
  componentDidMount = async () => {
    let { index, progress, demoWorkoutUrl } = this.props;
    let { percent, showWorkoutRate } = this.state;
    const { id } = this.props.match.params;
    const { videos } = this.props;
    console.log(demoWorkoutUrl);
    index++;
    if (videos.length === 0) {
      let data = await this.props.getVideo(id);
      this.setState({ videos: data });
    } else {
      this.setState({ videos: videos });
    }
    if (videos.length > 0) {
      percent = (1 / videos.length) * 100;
      this.setState({ percent: percent, progress: progress });
    }
    if (index > 1) {
      index <= videos.length
        ? this.setState({ currentIndex: index - 1 })
        : this.setState({ showWorkoutRate: true });
    }
    // if (index - 1 === videos.length) {
    //   this.videoObj.pauseVideo();
    // }
    window.WebViewBridge = {
      onMessage: this._onMessage
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);

    let progressStatus = await this.isCounting(index, videos.length);
    await this.props.countProgress(progressStatus);
  };
  componentWillUnmount() {
    if (this.playerInterval) {
      clearInterval(this.playerInterval);
    }
  }
  isCounting = (index, length) => {
    if (index <= length) {
      return Math.floor((index / length) * 100);
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.duration !== nextState.duration) {
      return true;
    }
    return true;
  }
  componentDidUpdate() {
    if (this.state.showWorkoutRate) {
      const { demoWorkoutUrl } = this.props;
      this.props.history.push(`${demoWorkoutUrl}`);
    }
  }
  loadInitialVideo = () => {
    //let videoObj = this.state.videoObj;

    this.videoObj.loadVideoById(
      this.state.videos[this.state.currentIndex].video
    );
    let { index, videos } = this.props;
    if (index === videos.length) {
      this.videoObj.pauseVideo();
    } else {
      //console.log(2)
      this.playButtonToggle();
    }

    /* this.videoObj.stopVideo();
    this.setVideoCountDown(); */
  };
  loadVideo = autoplay => {
    //console.log('this.state.currentIndex',this.state.currentIndex)
    this.videoObj.loadVideoById(
      this.state.videos[this.state.currentIndex].video
    );
    if (!autoplay || autoplay === false) {
      this.videoObj.stopVideo();
    }
  };
  onReady = e => {
    console.log(".....");
    this.videoObj = e.target;
    const duration = this.state.videos[this.state.currentIndex].duration;
    this.setState({ duration: duration }, () => {
      this.loadInitialVideo();
    });
  };
  onVideoEnd = event => {
    this.setState({ isPlaying: false });
  };
  onVideoPause = () => {
    const isPlaying = this.state.isPlaying;
    if (isPlaying) {
      this.videoObj.pauseVideo();
    }
  };
  onVideoStateChange = event => {
    // console.log("video change");
    if (event.data === this.PlayerState.PLAYING) {
      this.playerInterval = setInterval(() => {
        const duration = this.state.duration - 1;
        // console.log("interval running" + duration);
        if (duration >= 0) {
          this.setState({ duration: duration });
        } else {
          clearInterval(this.playerInterval);
        }
      }, 1000);
    } else {
      clearInterval(this.playerInterval);
    }
  };
  playButtonToggle = () => {
    //let videoObj = this.state.videoObj;
    if (this.videoObj) {
      let isPlaying = this.state.isPlaying;
      this.setState({ isPlaying: !isPlaying, isStopped: false }, () => {
        if (isPlaying) {
          this.videoObj.pauseVideo();
        } else {
          this.videoObj.playVideo();
        }
      });
    }
  };
  setVideoCountDown = () => {
    // this.playerInterval = setInterval(() => {
    //   if (this.state.isPlaying) {
    //     const duration = this.state.duration - 1;
    //     if (duration >= 0) {
    //       this.setState({ duration: duration })
    //     } else {
    //       //clearInterval(this.playerInterval);
    //     }
    //   }
    // }, 1000);
  };
  nextVideo = autoplay => {
    let currentIndex = this.state.currentIndex + 1;
    //if this is the last video, then show award page
    if (currentIndex >= this.state.videos.length) {
      this.setState({ showWorkoutRate: true });
      //Clear count down interval to prevent memory leak
      clearInterval(this.playerInterval);
    } else {
      const duration = this.state.videos[currentIndex].duration;
      this.setState({ currentIndex: currentIndex, duration: duration }, () => {
        this.loadVideo(autoplay);
      });
    }
  };
  previousVideo = () => {
    let currentIndex = this.state.currentIndex - 1;
    const duration = this.state.videos[currentIndex].duration;
    this.setState({ currentIndex: currentIndex, duration: duration }, () => {
      this.loadVideo(false);
    });
  };
  reloadVideo = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex = 0;
    const duration = this.state.videos[currentIndex].duration;
    this.setState(
      { duration: duration, isPlaying: true, isStopped: false },
      () => {
        //let videoObj = this.state.videoObj;
        this.videoObj.seekTo(0);
      }
    );
  };
  muteToogle = () => {
    let isMute = !this.state.isMute;
    this.setState({ isMute: isMute }, () => {
      isMute ? this.videoObj.mute() : this.videoObj.unMute();
    });
  };
  stopVideo = () => {
    const isPlaying = false;
    const isStopped = true;
    const duration = this.state.videos[this.state.currentIndex].duration;
    this.setState(
      { isPlaying: isPlaying, isStopped: isStopped, duration: duration },
      () => {
        this.videoObj.stopVideo();
      }
    );
  };
  restTimeEndHandler = () => {
    this.setState({ showResttimePage: false });
    //this.nextVideo(true);
  };
  clearProgress = () => {
    let { index } = this.props;
    index = null;
    this.props.clearProgress();
    this.props.nextExercise(index);
  };
  onBack = () => {
    const { id } = this.props.match.params;
    const { videos, currentIndex } = this.state;
    if (currentIndex > 0 && currentIndex < videos.length) {
      this.setState(
        { currentIndex: currentIndex - 1, isPlaying: false },
        async () => {
          await this.props.onBack();
          this.loadVideo(false);
        }
      );
    }
  };
  goExcerciseReps = async () => {
    const { id, link } = this.props.match.params;
    console.log(link);
    const { videos, currentIndex } = this.state;
    let data = {
      name: videos[currentIndex].name,
      reps: videos[currentIndex].reps,
      sets: videos[currentIndex].sets,
      currentIndex: currentIndex,
      workoutId: id,
      gif: videos[currentIndex].gif,
      description: videos[currentIndex].description
    };
    await this.props.getExerciseReps(data);
    //check type of exercise
    // videos[currentIndex].type === exerciseType.REPS
    //   ? this.props.history.push("/ExerciseReps")
    //   : this.props.history.push("/ExerciseTimer");
    videos[currentIndex].reps.includes("followed by")
      ? this.props.history.push(`/DemoExerciseRest`)
      : this.props.history.push(`/DemoExerciseReps`);
  };
  onClosedHandler = () => {
    this.goToAwardedPage();
  };
  onRatedHandler = () => {
    this.goToAwardedPage();
  };
  goToAwardedPage = () => {
    this.props.history.push("/Congratulations/" + this.props.match.params.id);
  };
  modalClose = () => this.setState({ modalShow: false });
  render() {
    const opts = {
      // height: '390',
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        playsinline: 1,
        autoplay: 0,
        controls: 0,
        fs: 0,
        rel: 0,
        modestbranding: 1,
        showinfo: 0,
        autohide: 1,
        enablejsapi: 1,
        html5: 1
      }
    };
    const { progressStatus, progress, videos } = this.props;
    const { percent } = this.state;
    return this.state.videos.length > 0 ? (
      <div className="video-wrapper">
        <div className="progress prgess-complete">
          {videos.length > 0
            ? videos.map((v, i) => {
                return (
                  <Fragment key={i}>
                    <ProgressBar
                      restTime={this.state.showResttimePage}
                      i={i}
                      percent={percent}
                      progress={progress}
                    />
                  </Fragment>
                );
              })
            : null}
        </div>
        <div className="sound">
          <a className="icon" onClick={this.muteToogle}>
            <img src={this.state.isMute ? muteIcon : soundIcon} />
          </a>
          {/* <a
            onClick={() => this.setState({ modalShow: true })}
            className="icon"
          >
            <img src={closeIcon} />
          </a> */}
        </div>
        {this.state.isPlaying ? null : (
          <div
            className="background_youtube fadeIn"
            style={{
              backgroundImage: `url(${
                this.state.videos[this.state.currentIndex]
                  ? this.state.videos[this.state.currentIndex].thumbnail
                  : null
              })`
            }}
          />
        )}
        <div className="video_iframe">
          <YouTube
            opts={opts}
            onReady={this.onReady}
            onPause={this.onVideoPause}
            onEnd={this.onVideoEnd}
            onStateChange={this.onVideoStateChange}
          />
        </div>
        {!this.state.isPlaying ? (
          <Fragment>
            <div className="player fadeIn" onClick={this.playButtonToggle}>
              <Player className="widthPlay" img="playf2.svg" />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className="player player_stop fadeIn"
              onClick={this.playButtonToggle}
            />
          </Fragment>
        )}
        {/* <div className="box_video"> */}
        {/* <div className="box_player"> */}

        <NameworkVideo
          name={this.state.videos[this.state.currentIndex].name}
          reps={this.state.videos[this.state.currentIndex].reps}
          sets={this.state.videos[this.state.currentIndex].sets}
          onClick={this.goExcerciseReps}
          onBack={this.onBack}
          currentIndex={this.state.currentIndex}
        />
        {/* </div> */}
        {/* </div> */}
        {this.state.showResttimePage ? (
          <Resttime
            onComplete={this.restTimeEndHandler}
            onSkip={this.restTimeEndHandler}
          />
        ) : null}
        {/* <ModalRate
          show={this.state.showWorkoutRate}
          onClosed={this.onClosedHandler}
          onRated={this.onRatedHandler}
          id={this.props.match.params}
        /> */}

        {/* <ModalwthWorkout
          show={this.state.modalShow}
          backdrop="static"
          onHide={this.modalClose}
          onClick={this.clearProgress}
        /> */}
      </div>
    ) : null;
  }
}
const mapStateToProps = state => {
  return {
    ...state.workOutList,
    ...state.videosReducer,
    ...state.exerciseReducer,
    ...state.diary
  };
};
export default connect(mapStateToProps, {
  getVideo,
  getExerciseReps,
  nextExercise,
  countProgress,
  clearProgress,
  onBack
})(DemoVideo);
