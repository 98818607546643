import React, { Component } from "react";
import { Link } from "react-router-dom";
import Back from "./Button/Back";
import { connect } from "react-redux";
import { showProfile } from "../store/actions/Profile";
import { MENU, WEBVIEW_ACTION } from "../config/config";
import { checkUserPassword, updatePassword } from "../store/actions/UserInfo";
import  { sendMessage} from "../ultils/ultils"
export class ChangePassword extends Component {
  state = {
    currentPassword: null,
    newPassword: null,
    confirmNewPassword: null,
    isInvalid: false
  };

  changePassword = async () => {
    const { email } = this.props.user;
    const { currentPassword, newPassword, confirmNewPassword } = this.state;
    let isValid = await this.props.checkUserPassword(email, currentPassword);
    if (isValid) {
      if (newPassword && !newPassword.includes(" ") && newPassword.length > 3) {
        const isMatch = newPassword === confirmNewPassword;
        if (isMatch) {
          let result = await this.props.updatePassword(email, newPassword);
          if (result) {
            this.autoLogin(email, currentPassword);
          }
        }
      }
    }
  };
  onChange = e => {
    this.setState({ [e.target.dataset.id]: e.target.value });
  };
  onClick = () => {
    let showProfile = true;
    this.props.showProfile(showProfile);
    this.props.history.push("/index");
  };
  autoLogin = (email, password) => {
    const data  = {
      type: WEBVIEW_ACTION.AUTO_LOGIN,
          email: email,
          password: password
    }
    sendMessage(data)
  };
  render() {
    //console.log(this.state.currentPassword)
    return (
      <div>
        <div className="page pageFeedBack fadeIn">
          <div className="page-header">
            <Back onClick={this.onClick} />
            <h4>Change Password</h4>
            <Link to="" className="icon" />
          </div>
          <div className="colm_costume colm_info_user">
            <form class="login changePassword">
              <div class="form-group">
                <label>Current Password</label>
                <input
                  data-id="currentPassword"
                  onChange={e => this.onChange(e)}
                  type="password"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>New Password</label>
                <input
                  data-id="newPassword"
                  onChange={e => this.onChange(e)}
                  type="password"
                  class="form-control"
                />
                <small class="text-danger">Must be 8-20 characters long.</small>
              </div>
              <div class="form-group">
                <label>Confirm New Password</label>
                <input
                  data-id="confirmNewPassword"
                  onChange={e => this.onChange(e)}
                  type="password"
                  class="form-control"
                />
              </div>
              <div className="button">
                <button onClick={this.changePassword} type="button">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.userInfo,
    ...state.workOutList,
    ...state.images
  };
};

export default connect(
  mapStateToProps,
  { checkUserPassword, updatePassword, showProfile }
)(ChangePassword);
