import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { access } from "fs";
class ChallengeSliderProgrammeContent extends Component {
  state = {
    workOutOption: null,
  };
  componentDidMount() {
    const { workoutItem, percent } = this.props;
    if (workoutItem) {
      switch (true) {
        case percent === 100:
          this.setState({ workOutOption: "START AGAIN" });
          break;
        case percent > 0:
          this.setState({ workOutOption: "CONTINUE" });
          break;
        default:
          this.setState({ workOutOption: "START" });
          break;
      }
    }
  }
  render() {
    const { workoutItem, week, percent } = this.props;
    let workoutChecked = workoutItem
      .filter((item) => item.historyId)
      .reduce((acc, cur) => {
        acc.push(cur.id);
        return acc;
      }, []);
    return (
      <div>
        {workoutItem ? (
          <div className="item">
            <div className="content_programme">
              <h4>{`Week ${week}`}</h4>
              <div className="name_programme">
                <div>
                  <p>Programme</p>
                  {workoutItem.map((item, index) => (
                    <span key={item.id}>
                      Workout {index + 1} - {item.description}{" "}
                      {item.historyId && (
                        <img src={require("../../images/check.svg")} alt="" />
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="button">
              <button
                onClick={this.props.onClick.bind(this, week)}
                className={"button-w220"}
                type="button"
              >
                {percent && percent === 100
                  ? "START AGAIN"
                  : percent > 0
                  ? "CONTINUE"
                  : "START"}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default connect(
  null,
  {}
)(ChallengeSliderProgrammeContent);
