export const RECIPES_CATEGORIES = {
  BREAKFAST: "Breakfast",
  LUNCH: "Meals",
  SNACK: "Snack",
  MEAL_PLANS: "Meal Plans",
};

export const BREAK_FAST_RECIPES = [
  {
    link: "Microwave_steamed_egg_recipe",
    title: "10-min Microwave Steamed Egg",
    isShowing: true,
    imgIndex: 0,
  },
  {
    link: "Umami_pumpkin_mash_on_toast",
    title: "Umami Pumpkin Mash on Toast (V,VG)",
    isShowing: true,
    imgIndex: 1,
  },
  {
    link: "Spinach_and_shiitake_fritata",
    title: "Spinach and Shiitake Fritata (VG,GF)",
    isShowing: true,
    imgIndex: 2,
  },
  {
    link: null,
    title: "Asian Breakfast Oats _WIP_",
    isShowing: false,
    imgIndex: 3,
  },
  {
    link: "Siracha_avocado_toast",
    title: "Siracha Avocado Toast (V,VG)",
    isShowing: true,
    imgIndex: 4,
  },
];

export const LUNCH_RECIPES = [
  {
    link: "Tomato_egg_drop_noodle_soup",
    title: "Tomato Egg Drop Noodle Soup",
    isShowing: true,
    imgIndex: 0,
  },
  {
    link: "Chicken_tikka_with_vegetable_basmati_rice",
    title: "Chicken Tikka with Vegetable Basmati Rice",
    isShowing: true,
    imgIndex: 1,
  },
  {
    link: "Lemongrass_chicken_salad",
    title: "Lemongrass Chicken Salad ",
    isShowing: true,
    imgIndex: 2,
  },
  {
    link: "Mushroom_barley_risotto",
    title: "Mushroom Barley Risotto",
    isShowing: true,
    imgIndex: 3,
  },
  {
    link: "Japanese_tempeh_curry",
    title: "Japanese Tempeh Curry",
    isShowing: true,
    imgIndex: 4,
  },
  {
    link: "Mis_glazed_salmon_with_soba_noodles",
    title: "Miso-Glazed Salmon with Soba Noodles",
    isShowing: true,
    imgIndex: 5,
  },
  {
    link: null,
    title: "The _anything goes_ Okonomiyaki",
    isShowing: false,
    imgIndex: 6,
  },
  {
    link: "Thai_style_lettuce_quorn_cups",
    title: "Thai-Style Lettuce Quorn Cups (Vegan)",
    isShowing: true,
    imgIndex: 7,
  },
];

export const SNACK_RECIPES = [
  {
    link: "Vegetable_platter_with_Asian_dips",
    title: "Vegetable Platter with Asian Dips",
    isShowing: true,
    imgIndex: 0,
  },
  {
    link: "Mini_bang_bang_chicken_salad",
    title: "Mini Bang Bang Chicken Salad",
    isShowing: true,
    imgIndex: 1,
  },
  {
    link: null,
    title: "Grilled Okra",
    isShowing: false,
    imgIndex: 2,
  },
  {
    link: "Okonomiyaki",
    title: "Okonomiyaki",
    isShowing: true,
    imgIndex: 3,
  },
  {
    link: null,
    title: "Miso-glazed popcorn",
    isShowing: false,
    imgIndex: 4,
  },
];

export const MEAL_PLANS_RECIPES = [
  {
    link:
      "https://drive.google.com/file/d/1bd0j0k6GPItaRozM6d02kt9hbK3siFoY/view",
    title: "28-Day Weight Loss",
    isShowing: true,
    imgIndex: 0,
    external: true,
  },
  {
    link:
      "https://drive.google.com/file/d/1INmdJZvOxgE3CCTWj9YfD9mB4OWenAqS/view",
    title: "28-Day Weight Loss (Vegetarian)",
    isShowing: true,
    imgIndex: 1,
    external: true,
  },
  {
    link:
      "https://drive.google.com/file/d/1Oft_3I6BEp7OYvT7TU_w3j2RTa1UooSY/view",
    title: "5-Day Meal Prep",
    isShowing: true,
    imgIndex: 2,
    external: true,
  },
];
