import React, { Component } from 'react'
import Moment from 'moment';
class StepChartItem extends Component {
  render() {
    const { steps, date, maxSteps } = this.props
    let round = maxSteps / 100;
    let roundSteps = (Math.ceil(round * 2) / 2) * 100;
    let height = (steps / roundSteps) * 100;
    let stepsData = Math.round(steps);
    return (
      <div className={!steps ? "styleColumn styleHeight" : "styleColumn"}>
        <div className="chartBar" style={{ height: `${steps ? height : 0}%` }}>
        </div>
        <div className="dayChart">{Moment(date).format("ddd")}<span>{Moment(date).format("DD MMM")}</span></div>
        <div className="stepsChart">
          {stepsData ? stepsData : 0} steps
        </div>
      </div>
    )
  }
}

export default StepChartItem