import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Confetti from "react-dom-confetti";
import soundTest from "../assets/applause.mp3";
//const audio = new Audio(soundTest);
class DemoAchievement extends Component {
  state = {
    applause: false,
    confet: false,
    audio: null
  };
  audio = new Audio(soundTest);
  onClick = () => {
    console.log("OnClick");
  };
  componentDidMount = () => {
    this.playSound();
    setTimeout(() => {
      this.setState({ confet: true });
    }, 500);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.audio !== this.state.audio) {
      this.state.audio.play();
    }
  };
  playSound = async () => {
    //const media = this.audio.nativeElement;
    //this.audio.muted = true; // without this line it's not working although I have "muted" in HTML
    this.audio.play();
  };
  onBack = () => {
    this.audio.pause();
    // this.props.onHide();
  };
  componentDidUpdate() { }
  render() {
    let { applause, confet } = this.state;
    const config = {
      angle: "107",
      spread: "91",
      startVelocity: 45,
      elementCount: 50,
      dragFriction: 0.1,
      duration: 5000,
      stagger: 0,
      width: "10px",
      height: "10px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
    };
    return (
      <div>
        <div className="page pageFeedBack">
          {/* <audio id="rvoltage" src={soundTest} muted></audio> */}
          <div className="page-header">
            <Link to="/index" className="icon" onClick={this.onBack}>
              {/* <img src={require("../../images/back.svg")} alt="" /> */}
              
            </Link>
            <h4>Achievements</h4>
            <Link to="/index" className="icon">
              <img src={require("../images/ic_close.svg")} alt="" />

            </Link>
          </div>

          <div className="privacy achievements">
            <div className="content">
              <img
                className="imgLarge"
                src={require("../images/HealthHustler.svg")}
                alt=""
              />
              <h1>Health Hustler</h1>
              <h3>Completed full week’s programme within one week</h3>
              <img
                className="mt-0"
                src={require("../images/laurel_line.svg")}
                alt=""
              />
              <p>
                You completed all your workouts and cardio sessions of the week
                within 7 days and have proven to be a true hustler when it comes
                to achieving your goals. Don’t stop now!
                <span>Number achieved: 4</span>
              </p>
            </div>
            <Confetti active={confet} config={config} />
            <div className="button_2">
              <button className="blue" type="button" onClick={this.playSound}>
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DemoAchievement;
