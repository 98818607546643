import React, { Component } from 'react';
import { Link } from "react-router-dom";
import RecipesSliderItem from './RecipesSliderItem';

export class RecipesSliderBreakfast extends Component {
    render() {
        const Image = [
            require("../../images/recipes/breakfast/01.jpg"),
            require("../../images/recipes/breakfast/02.jpg"),
            require("../../images/recipes/breakfast/03.jpg"),
            require("../../images/recipes/breakfast/04.jpg"),
            require("../../images/recipes/breakfast/05.jpg"),
        ];

        return (
            <div>
                <div className="title-main" style={{ margin: '10px 16px 12px' }}>Breakfast</div>
                <div className="sliderRecipes">
                    <div className="item">
                        <Link to="/Microwave_steamed_egg_recipe">                            
                           <RecipesSliderItem 
                                image = {Image[0]}
                                titlecontent="10-min Microwave Steamed Egg"                           
                           />
                        </Link>                      
                    </div>
                    <div className="item">
                        <Link to="/Umami_pumpkin_mash_on_toast">                            
                           <RecipesSliderItem 
                                image = {Image[1]}
                                titlecontent="Umami Pumpkin Mash on Toast (V,VG)"                           
                           />
                        </Link>                      
                    </div>
                    <div className="item lock">
                        <Link to="/Spinach_and_shiitake_fritata">                            
                           <RecipesSliderItem 
                                image = {Image[2]}
                                titlecontent="Spinach and Shiitake Fritata (VG,GF)"                           
                           />
                        </Link>                      
                    </div>
                    {/* <div className="item">
                        <Link>                            
                           <RecipesSliderItem 
                                image = {Image[3]}
                                titlecontent="Asian Breakfast Oats _WIP_"                           
                           />
                        </Link>                      
                    </div> */}
                    <div className="item lock">
                        <Link to="/Siracha_avocado_toast">                            
                           <RecipesSliderItem 
                                image = {Image[4]}
                                titlecontent="Siracha Avocado Toast (V,VG)"                           
                           />
                        </Link>                      
                    </div>
                </div>
            </div>
        )
    }
}

export default RecipesSliderBreakfast
