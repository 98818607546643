import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Miso_glazed_popcorn extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return <></>;
  }
}

export default Miso_glazed_popcorn;
