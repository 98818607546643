import * as actionTypes from "../actions/actionTypes";
const initialState = {
  videos: [],
  demoWorkoutUrl: null
};
const videosReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VIDEOS:
      return {
        ...state,
        videos: action.data
      };
    case actionTypes.SET_DEMOWORKOUT_URL:
      return {
        ...state,
        demoWorkoutUrl: action.data
      };
    default:
      return state;
  }
};

export default videosReducer;
