import React, { Component } from "react";
import { Link } from "react-router-dom";
import Back from "../Button/Back";
import { connect } from "react-redux";
import { showProfile, updateProfile } from "../../store/actions/Profile";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import UserInfo from "../UserInfo/UserInfo";
import { TYPES, ERRORS } from "../../config/config";
import DateTime from "react-datetime";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import { sendMessage } from "../../ultils/ultils";
export class ModalEditProfile extends Component {
  state = {
    username: "",
    date: "",
    gender: null,
    usernameError: false,
    dateError: false,
    password: null,
    email: null,
    userProfile: null,
  };
  onClick = () => {
    let showProfile = true;
    this.props.showProfile(showProfile);
    this.props.history.push("/index");
  };
  componentDidMount() {
    //const { username, date, gender, password, email } = this.props.user;
    const { userProfile } = this.props;
    //console.log(userProfile);
    //this.setState({ username: username, date: date, gender: gender, password: password, email: email })
  }
  onChange = (e) => {
    const { username } = this.state;
    this.setState({ [e.target.dataset.id]: e.target.value });
    if (username.length < 3) {
      this.setState({ usernameError: true });
    } else {
      this.setState({ usernameError: false });
    }
  };
  onChangeDate = (e) => {
    let date = Moment(e.target.value);
    let isValid = this.isValid(date);
    this.setState({ date: date, dateError: !isValid });
  };
  isValid = (date) => {
    let validDate = Moment().subtract(0, "years");
    let dob = Moment(date);
    if (validDate.isAfter(dob)) {
      return true;
    } else {
      return false;
    }
  };
  clearData = () => {
    const { username, date, gender } = this.props.userProfile;
    this.setState({
      username,
      date,
      gender,
      usernameError: false,
      dateError: false,
    });
  };
  onHide = async () => {
    await this.clearData();
    this.props.onHide();
  };
  autoLogin = (email, password) => {
    sendMessage({
      type: WEBVIEW_ACTION.AUTO_LOGIN,
      email: email,
      password: password,
    });
  };
  onSubmit = async () => {
    const { username, gender, date, email, password } = this.state;
    const isValid = this.isValid(date);
    if (username.length > 3 && isValid) {
      let userProfile = {
        username: username,
        date: date,
        gender: gender,
      };
      let result = await this.props.updateProfile(userProfile);
      if (result) {
        this.autoLogin(email, password);
      }
    } else {
      // if (username.length < 3) {
      //   this.setState({ usernameError: true })
      // }
      //username.length < 3 ? this.setState({ usernameError: true }) : this.setState({ dateError: true })
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userProfile !== prevProps.userProfile) {
      const {
        username,
        date,
        gender,
        password,
        email,
      } = this.props.userProfile;
      this.setState({ username, date, gender, password, email });
    }
  }
  render() {
    const {
      username,
      date,
      gender,
      usernameError,
      dateError,
      email,
    } = this.state;
    const { os } = this.props;
    // if (!os) {
    //   return null;
    // }
    let test = "Test";
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg" centered>
          <div className="page pageNomal">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.onHide}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>Edit Profile</h4>
              <Link to="#" className="icon" />
            </div>

            <div className="colm_costume">
              <div>
              <p className="text-center">{email}</p>
              <form className="box_user">                
                <div className="form-group">
                  <label className={usernameError ? "text-danger" : null}>
                    Your Name{" "}
                  </label>
                  <input
                    autoCapitalize={os && os === "android" ? "words" : "off"}
                    value={username}
                    type="text"
                    onChange={(e) => this.onChange(e)}
                    data-id="username"
                  />
                </div>
                <div className="form-group">
                  <label className={dateError ? "text-danger" : null}>
                    DOB
                  </label>
                  {/* <DateTime
                    dateFormat="DD-MM-YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    inputProps={{ readOnly: true }}
                    value={date}
                    onChange={e => this.onChangeDate(e)}
                  /> */}

                  <input
                    value={Moment(date).format("YYYY-MM-DD")}
                    onChange={(e) => this.onChangeDate(e)}
                    type="date"
                  />
                </div>
                <div className="form-group select_custom">
                  <label>Gender</label>
                  <select
                    required
                    data-id="gender"
                    onChange={(e) => this.onChange(e)}
                  >
                    {gender === "M" ? (
                      <option selected value="M">
                        Male
                      </option>
                    ) : (
                        <option value="M">Male</option>
                      )}
                    {gender === "F" ? (
                      <option selected value="F">
                        Female
                      </option>
                    ) : (
                        <option value="F">Female</option>
                      )}
                    {gender === "P" ? (
                      <option selected value="F">
                        Prefer not to say
                      </option>
                    ) : (
                        <option value="F">Prefer not to say</option>
                      )}
                  </select>
                </div>
              </form>
              </div>
              <div className="button">
                <button onClick={this.onSubmit} type="button">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.profileReducer,
    ...state.workOutList,
  };
};
export default connect(mapStateToProps, { showProfile, updateProfile })(
  ModalEditProfile
);
