import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Bxupgrade extends Component {
  render() {
    var { price, description, id, totalPrice, title, month, message, currency } = this.props
    return (
      <>
        <Link to="#" className="box_upgrade">
          {title} <br />
          - {parseFloat(price).toLocaleString()} {currency}/{month}
          <p className="subscribe" style={{ fontSize: 12, marginTop: 6, marginBottom: 6, fontWeight: "bold" }}>Total: {parseFloat(totalPrice).toLocaleString()} {currency}
            <button data-id={id} onClick={this.props.onClick} className="subbutton">Purchase now</button>
          </p>

        </Link>
        {message ? (
          <p className="bg"><b>{message}</b> - {description}</p>
        ) : (<p className="bg">{description}</p>)}

      </>
    )
  }
}

export default Bxupgrade
