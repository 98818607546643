import React, { Component } from 'react'

export class CalendarDays extends Component {
  render() {
    var {days, isActive} = this.props
    return (
      <div>
        <div className="calendar_day ">
          <span className={isActive}>{days}</span>
        </div>
      </div>
    )
  }
}

export default CalendarDays
