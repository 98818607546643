import * as actionTypes from "../actions/actionTypes";
import { API } from "../../config/config";
import { headers, uploadHeaders } from "../../ultils/helpers";
export const showProfile = (showProfile) => (dispatch) => {
  return dispatch({
    type: actionTypes.SHOW_MODAL_PROFILE,
    data: showProfile,
  });
};

export const updateProfile = (userProfile) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_PROFILE_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(userProfile),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
export const setUserProfile = (userProfile) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_USER_PROFILE,
    data: userProfile,
  });
};
export const setProfileAssessment = (assessment) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_PROFILE_ASSESSMENT,
    data: assessment,
  });
};
export const getAllAssessment = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_ASSESSMENT_API, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const togglePlayAudio = () => (dispatch) => {
  return dispatch({
    type: actionTypes.TOGGLE_PLAY_AUDIO,
  });
};

export const reloadAssessment = () => (dispatch) => {
  return dispatch({
    type: actionTypes.RELOAD_ASSESSMENT,
    data: true,
  });
};

export const clearReload = () => (dispatch) => {
  return dispatch({
    type: actionTypes.CLEAR_RELOAD,
    data: false,
  });
};
