import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Textarea from "react-textarea-autosize";
import { DIARY_TYPES } from "../../config/config";
import { sendDiary } from "../../store/actions/Diary";

import { connect } from "react-redux";
import Moment from "moment";
class ModalRate2 extends Component {
  state = {
    feedback: null,
    other: "",
    disabled: true,
    onSubmit: false,
    onBack: false,
  };
  componentDidMount() {
    /* window.WebViewBridge = {
      onMessage: this._onMessage,
    };
    const event = new Event('WebViewBridge'); */
  }
  /* _onMessage = (data) => {
    //alert(JSON.stringify(data));
    this.setState({ stepsList: data })
  } */
  onChange = (e) => {
    this.setState({ other: e.target.value, feedback: e.target.value });
  };
  onClick = (value) => {
    this.setState({ onBack: false });
    if (!value) {
      this.setState({ disabled: false, feedback: "" });
      setTimeout(function() {
        document.getElementById("other").focus();
      }, 0);
    } else {
      this.setState({ disabled: true, feedback: value });
    }
  };
  onBack = () => {
    this.setState({ onBack: true });
    this.props.onBack();
  };
  onSubmit = async () => {
    const {
      emotion,
      startTime,
      workoutName,
      week,
      isFullBodyWorkout,
    } = this.props;
    let challengeName = "";
    const { Challenges, SelectedChallenge } = this.props;
    if (SelectedChallenge) {
      const item = Challenges.find((m) => m.id == SelectedChallenge);
      challengeName = `${item.name} - `;
    }

    const { feedback } = this.state;
    const endTime = Moment().format("YYYY-MM-DD HH:mm:ss");
    const diary = {
      emotion: emotion,
      feedback: feedback,
      startTime: startTime,
      endTime: endTime,
      workoutName: workoutName,
      week: isFullBodyWorkout
        ? ""
        : challengeName
        ? `${challengeName} Week ${week}`
        : `Week ${week}`,
      type: DIARY_TYPES.WORKOUT,
    };
    console.log("DIARYYYYYYY", { diary, week });
    this.setState({ onSubmit: true });
    if (diary.emotion !== null || diary.feedback !== null) {
      await this.props.sendDiary(diary);
      this.setState({ onSubmit: false });
      this.props.onClosed();
    } else {
      await this.props.sendDiary(diary);
      this.props.onClosed();
    }
  };
  render() {
    const { onSubmit, onBack } = this.state;
    return (
      <Modal
        className="content-popup modal-custom"
        {...this.props}
        size="lg"
        centered
      >
        <Link to="#" className="icon">
          <img
            onClick={this.props.onClosed}
            src={require("../../images/ic_close_p.svg")}
            alt=""
          />
        </Link>

        <Modal.Body className="rate2">
          <h3>How are you feeling after your workout?</h3>
          <ul>
            <li>
              <input type="radio" id="f-option" name="selector" />
              <label onClick={() => this.onClick("Amazing")} htmlFor="f-option">
                Amazing
              </label>

              <div className="check" />
            </li>

            <li>
              <input type="radio" id="s-option" name="selector" />
              <label onClick={() => this.onClick("Tired")} htmlFor="s-option">
                Tired{" "}
              </label>

              <div className="check">
                <div className="inside" />
              </div>
            </li>

            <li>
              <input type="radio" id="t-option" name="selector" />
              <label
                onClick={() => this.onClick("Stronger")}
                htmlFor="t-option"
              >
                Stronger{" "}
              </label>

              <div className="check">
                <div className="inside" />
              </div>
            </li>
            <li>
              <input type="radio" id="h-option" name="selector" />
              <label
                onClick={() => this.onClick("I hate exercise")}
                htmlFor="h-option"
              >
                I hate exercise
              </label>

              <div className="check">
                <div className="inside" />
              </div>
            </li>
            <li>
              <input
                onClick={() => this.onClick()}
                type="radio"
                id="o-option"
                name="selector"
              />
              <label htmlFor="o-option">Other</label>
              <div className="check">
                <div className="inside" />
              </div>
              <div>
                <textarea
                  innerRef={(textarea) => {
                    this.messageTextarea = textarea;
                  }}
                  disabled={this.state.disabled}
                  onChange={this.onChange}
                  // defaultValue={this.state.other}
                  // placeholder="Other"
                  value={this.state.other}
                  id="other"
                />
              </div>
            </li>
          </ul>
        </Modal.Body>
        <div className="modal-footer">
          <button
            type="button"
            className={onSubmit ? "disabled" : ""}
            onClick={this.onBack}
          >
            BACK
          </button>
          {this.state.feedback ? (
            <button
              type="button"
              className={
                this.state.feedback.length === 0 || onBack || onSubmit
                  ? "disabled"
                  : ""
              }
              onClick={this.onSubmit}
              disabled={onSubmit}
            >
              SUBMIT
            </button>
          ) : (
            <button type="button" className="disabled">
              SUBMIT
            </button>
          )}
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.diary,
    ...state.isFullBodyWorkout,
    ...state.challenge,
  };
};
export default connect(
  mapStateToProps,
  { sendDiary }
)(ModalRate2);
