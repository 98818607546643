import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import Moment from 'moment';
import StepChartItem from './StepsChartItem'
class StepChartWeek extends Component {
  render() {
    const { stepsList, maxSteps } = this.props;
    return (

      <div className={stepsList.length === 7 ? "weeksChart" : "testClass"}>

        {stepsList.map(item => <StepChartItem steps={item.value} date={item.id} maxSteps={maxSteps} />)}
      </div>

    )
  }
}


export default StepChartWeek