import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { WEBVIEW_ACTION,UNSUBSCRIBE_LINK } from "../../config/config";
import { connect } from "react-redux";
import ImageTools from "../../ultils/ImageTools/ImageTools.js";
import { uploadProfilePicture } from "../../store/actions/Photo";
import {
  getUser,
  checkExpired,
  setUserExpired,
} from "../../store/actions/UserInfo";
import {
  setProfileAssessment,
  reloadAssessment,
} from "../../store/actions/Profile";
import { compressedFile } from "../../config/config";
import moment from "moment";
import ModalTerms from "./ModalTerms";
import ModalPrivacy from "./ModalPrivacy";
import ModalEditProfile from "./ModalEditProfile";
import ModalChangePassword from "./ModalChangePassword";
import ModalFeedBack from "./ModalFeedBack";
import ModalAssessmentList from "./ModalAssessmentList";
import ModalContactUs from "./ModalContactUs";
import ModalUpdateApp from "./ModalUpdateApp";
import { sendMessage } from "../../ultils/ultils";
import ModalChangeWorkoutFocus from "./ModalChangeWorkoutFocus";
import ModalAchievementsList from "./ModalAchievementsList";
import Toggle from "../Button/Toogle";

export class ModalProfile extends Component {
  state = {
    fileUpload: null,
    isLoading: false,
    previewPhoto: null,
    modalShowPrivacy: false,
    modalShowTerms: false,
    modalShowEditProfile: false,
    modalShowChangePassword: false,
    modalShowFeedBack: false,
    modalShowListAssessment: false,
    modalShowContactUs: false,
    modalShowChangeWorkoutFocus: false,
    modalShowAchievementsList: false,
    modalShowUpdateApp: false,
    userProfile: null,
  };
  logOut = () => {
    localStorage.removeItem("playIntro");
    sendMessage({ type: WEBVIEW_ACTION.LOG_OUT });
  };
  onClick = () => {
    this.props.history.push("/index");
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userProfile !== prevProps.userProfile) {
      const { userProfile } = this.props;
      //console.log("Update");
      this.setState({ userProfile: userProfile });
    }
  }
  userRegister = () => {
    //console.log("userRegister()");
    const data = {
      type: WEBVIEW_ACTION.FORCE_REGISTER,
      goRegister: true,
    };
    sendMessage(data);
  };
  goToExternal = (os) => {
    let data = {};
    if(os == "ios"){
      data = { type: WEBVIEW_ACTION.SEND_URL, url: UNSUBSCRIBE_LINK.APPLE_URL };
    }else{
      data = { type: WEBVIEW_ACTION.SEND_URL, url: UNSUBSCRIBE_LINK.GOOGLE_URL };
    }
    sendMessage(data);
  };
  uploadProfilePicture = (event) => {
    const compress = new ImageTools();

    //this.setState({ isLoading: true })
    if (event.target.files[0]) {
      compress
        .resize(event.target.files[0], {
          width: compressedFile.maxWidthOrHeight,
          height: compressedFile.maxWidthOrHeight,
        })
        .then(async (img) => {
          let baseImg = await this.getBase64(img);
          this.setState({
            fileUpload: "",
            isLoading: false,
            previewPhoto: baseImg,
          });
          await this.props.uploadProfilePicture(img);
          //await this.props.reloadAssessment();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  };
  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
  modalClosePrivacy = () => this.setState({ modalShowPrivacy: false });
  modalCloseTerms = () => this.setState({ modalShowTerms: false });
  modalCloseEditProfile = () => this.setState({ modalShowEditProfile: false });
  modalCloseChangePassword = () =>
    this.setState({ modalShowChangePassword: false });
  modalCloseFeedBack = () => this.setState({ modalShowFeedBack: false });
  modalCloseListAssessment = () =>
    this.setState({ modalShowListAssessment: false });
  modalCloseUpdateApp = () => this.setState({ modalShowUpdateApp: false });
  modalCloseContactUs = () => this.setState({ modalShowContactUs: false });
  modalCloseChangeWorkoutFocus = () =>
    this.setState({ modalShowChangeWorkoutFocus: false });
  modalCloseAchievementsList = () =>
    this.setState({ modalShowAchievementsList: false });
  /* getMessage = () => {
    let message = document.addEventListener("message", function(event) {
      console.log("Received post message", event);
      logMessage(event.data);
  }, false);
  } */
  render() {
    const {
      memberTimes,
      leftTime,
      isExpired,
      profileAssessment,
      achievementsData,
      countedAchievement,
      dateAchievement,
      os,
      isPurchased,
      isUpdated,
      app_url,
      isSubscription,
      isInApp,
    } = this.props;
    const { userProfile, previewPhoto } = this.state;
    console.log({ isUpdated });
    //console.log(leftTime);
    //console.log(userProfile);
    let isRegistered =
      userProfile && userProfile.username && userProfile.email ? true : false;
    //console.log({ isRegistered });
    let userAssessment = userProfile ? userProfile.userAssessment : null;
    return (
      <>
        <Modal
          className="modal-custom-bottom"
          {...this.props}
          size="lg"
          centered
        >
          <Modal.Body>
            <Link to="#" className="icon" onClick={this.props.onHide}>
              {/* <img src={require("../../images/back.svg")} alt="" /> */}
              Back
            </Link>
            <div className="Profile">
              <div className="avatarUser">
                <div className="boxAvartarUser">
                  <p>
                    <img
                      src={
                        userProfile
                          ? previewPhoto
                            ? previewPhoto
                            : userProfile.profile_picture
                            ? userProfile.profile_picture
                            : require("../../images/user.svg")
                          : null
                      }
                      alt=""
                    />
                  </p>
                  <span>
                    <input
                      onChange={this.uploadProfilePicture}
                      type="file"
                      className="file"
                      accept="image/*"
                    />
                    <img src={require("../../images/ic_edit.svg")} alt="" />
                  </span>
                </div>
                <p>
                  {isRegistered ? userProfile.username : "<Not yet registered>"}
                </p>
                {achievementsData.length > 0 &&
                countedAchievement &&
                dateAchievement ? (
                  <Link
                    to="#"
                    className="adward"
                    onClick={() =>
                      this.setState({ modalShowAchievementsList: true })
                    }
                  >
                    <img src={require("../../images/larel.png")} alt="" />
                    Achievements
                    <img
                      className="rotate"
                      src={require("../../images/larel.png")}
                      alt=""
                    />
                  </Link>
                ) : null}
                <Link
                  to={`${
                    (isExpired || !isPurchased) && isUpdated
                      ? "/Upgrade/profile"
                      : "#"
                  }`}
                  onClick={
                    !isUpdated
                      ? () => this.setState({ modalShowUpdateApp: true })
                      : null
                  }
                  className={`${
                    (isExpired || !isPurchased) && isUpdated
                      ? "upgrade"
                      : !isUpdated
                      ? "upgrade"
                      : "upgrade disable"
                  }`}
                >
                  <img src={require("../../images/star.svg")} alt="" />{" "}
                  {/* {leftTime < 1 ? "JOIN MEMBERSHIP" : "EXTEND MEMBERSHIP"} */}
                  {"SUBSCRIBE"}
                </Link>
                <p className="memberDays">
                  {!isExpired
                    ? `${
                        isSubscription && !isInApp
                          ? "Renews on"
                          : "Member until"
                      } ${moment(memberTimes).format("DD MMM YYYY")}`
                    : null}
                </p>
                <ul>
                  {isRegistered ? (
                    <Fragment>
                      <li>
                        {" "}
                        <Link
                          onClick={() =>
                            this.setState({ modalShowEditProfile: true })
                          }
                        >
                          {" "}
                          Edit Profile{" "}
                          <img
                            src={require("../../images/ic_arrow.svg")}
                            alt=""
                          />
                        </Link>
                      </li>
                    </Fragment>
                  ) : (
                    <li>
                      {" "}
                      <Link onClick={() => this.userRegister()}>
                        {" "}
                        Register{" "}
                        <img
                          src={require("../../images/ic_arrow.svg")}
                          alt=""
                        />
                      </Link>
                    </li>
                  )}

                  <li>
                    {" "}
                    <Link
                      onClick={
                        /* profileAssessment > 0 ||  */ userAssessment &&
                        userAssessment > 0
                          ? () => this.setState({ modalShowFeedBack: true })
                          : null
                      }
                      className={
                        /* profileAssessment > 0 || */ userAssessment &&
                        userAssessment > 0
                          ? "assessClass"
                          : "disableClass"
                      }
                    >
                      {" "}
                      <span>
                        {" "}
                        Request Assessment By Tyen{" "}
                        <i>
                          {" "}
                          {/* profileAssessment > 0 ||  */ userAssessment &&
                          userAssessment > 0
                            ? "" +
                              ` ${
                                /* profileAssessment ? profileAssessment : */ userAssessment
                              } available ` +
                              ""
                            : null}
                        </i>
                      </span>{" "}
                      <img src={require("../../images/ic_arrow.svg")} alt="" />
                    </Link>
                    {/* <span>  Request Assessment  <i> {profileAssessment > 0 || (userAssessment && userAssessment > 0) ? '' + ` ${profileAssessment ? profileAssessment : userAssessment} available ` + '' : null}</i></span> <img src={require("../../images/ic_arrow.svg")} alt="" /> */}{" "}
                  </li>
                  <li>
                    {" "}
                    <Link
                      onClick={
                        isRegistered
                          ? () =>
                              this.setState({ modalShowListAssessment: true })
                          : null
                      }
                      className={isRegistered ? "assessClass" : "disableClass"}
                    >
                      {" "}
                      Assessment List{" "}
                      <img src={require("../../images/ic_arrow.svg")} alt="" />
                    </Link>{" "}
                  </li>

                  <li>
                    {" "}
                    <Link
                      onClick={() =>
                        this.setState({ modalShowChangeWorkoutFocus: true })
                      }
                    >
                      {" "}
                      Change Workout Focus{" "}
                      <img src={require("../../images/ic_arrow.svg")} alt="" />
                    </Link>
                  </li>
                  {os && os === "ios" ? (
                    <li>
                      <Toggle />
                    </li>
                  ) : null}
                  {isRegistered && !userProfile.appleUser ? (
                    <li>
                      {" "}
                      <Link
                        onClick={() =>
                          this.setState({ modalShowChangePassword: true })
                        }
                      >
                        {" "}
                        Change Password{" "}
                        <img
                          src={require("../../images/ic_arrow.svg")}
                          alt=""
                        />
                      </Link>
                    </li>
                  ) : null}
                  {isSubscription && !isExpired && os?(
                    <li>
                      <a
                        onClick={() => this.goToExternal(os)}
                      >
                        How To Unsubscribe
                        <img
                          src={require("../../images/ic_arrow.svg")}
                          alt=""
                        />
                      </a>
                    </li>
                  ):null}
                  <li>
                    {" "}
                    <Link
                      onClick={() => this.setState({ modalShowPrivacy: true })}
                    >
                      {" "}
                      Privacy Policy{" "}
                      <img src={require("../../images/ic_arrow.svg")} alt="" />
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      onClick={() => this.setState({ modalShowTerms: true })}
                    >
                      {" "}
                      Terms & Conditions{" "}
                      <img src={require("../../images/ic_arrow.svg")} alt="" />
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      onClick={() =>
                        this.setState({ modalShowContactUs: true })
                      }
                    >
                      {" "}
                      Contact Us{" "}
                      <img src={require("../../images/ic_arrow.svg")} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
              {/* {isRegistered ? (
                <div className="logOUt">
                  <Link to="#" onClick={this.logOut}>
                    <img src={require("../../images/logout.svg")} alt="" /> Log
                    Out
                  </Link>
                </div>
              ) : null} */}
              <div className="logOUt">
                <Link to="#" onClick={this.logOut}>
                  <img src={require("../../images/logout.svg")} alt="" /> Log
                  Out
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <ModalTerms
          show={this.state.modalShowTerms}
          backdrop="static"
          onHide={this.modalCloseTerms}
        />
        <ModalPrivacy
          show={this.state.modalShowPrivacy}
          backdrop="static"
          onHide={this.modalClosePrivacy}
        />

        <ModalEditProfile
          userProfile={userProfile ? userProfile : null}
          show={this.state.modalShowEditProfile}
          backdrop="static"
          onHide={this.modalCloseEditProfile}
        />
        <ModalChangePassword
          show={this.state.modalShowChangePassword}
          backdrop="static"
          onHide={this.modalCloseChangePassword}
        />
        <ModalFeedBack
          show={this.state.modalShowFeedBack}
          backdrop="static"
          onHide={this.modalCloseFeedBack}
        />
        <ModalAssessmentList
          show={this.state.modalShowListAssessment}
          backdrop="static"
          onHide={this.modalCloseListAssessment}
        />
        <ModalContactUs
          show={this.state.modalShowContactUs}
          backdrop="static"
          onHide={this.modalCloseContactUs}
        />
        <ModalChangeWorkoutFocus
          show={this.state.modalShowChangeWorkoutFocus}
          backdrop="static"
          onHide={this.modalCloseChangeWorkoutFocus}
        />
        <ModalUpdateApp
          show={this.state.modalShowUpdateApp}
          backdrop="static"
          app_url={app_url}
          onHide={this.modalCloseUpdateApp}
        />
        {countedAchievement && dateAchievement ? (
          <ModalAchievementsList
            achievementsData={achievementsData}
            countedAchievement={countedAchievement}
            dateAchievement={dateAchievement}
            show={this.state.modalShowAchievementsList}
            backdrop="static"
            onHide={this.modalCloseAchievementsList}
          />
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.profileReducer,
  };
};
export default connect(mapStateToProps, {
  uploadProfilePicture,
  getUser,
  reloadAssessment,
})(ModalProfile);
