import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";

class ModalUpdateApp extends Component {
  updateApp = (url) => {
    console.log({ url });
    const data = { type: WEBVIEW_ACTION.SEND_URL, url: url };
    this.props.onHide();
    sendMessage(data);
  };
  render() {
    const { app_url } = this.props;
    return (
      <Modal className="modal-custom bg" {...this.props} size="lg" centered>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            You need to update your app in order to Subscribe.
          </div>
          <div className="button buttoncalendar">
            <button onClick={() => this.updateApp(app_url)}>OK</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalUpdateApp;
