import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Mushroom_barley_risotto extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
         Meals
        </div>

        <div className="recipesMain">
          <div className="image">
            <img src={require("../../../images/recipes/lunch/04.jpg")} alt="" />
          </div>
          <p>
            Mushroom Barley Risotto
            <span> Serves: 4 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            1 medium onion, chopped
            <br />
            2 cloves garlic
            <br />
            Black pepper powder, to taste
            <br />
            1.5 cups pearl barley
            <br />
            300g mushrooms (shitake, button, or shimeji all work)
            <br />
            4.5 cups low sodium chicken broth (use vegtable broth if vegan)
            <br />
            8 tbsp grated Parmesan cheese (use nutritional yeast if vegan)
            <br />
            Truffle Oil (optional)
            <br />
            6 Almonds, thinly sliced (optional)
            <br />
            Chopped parsley
            <br />
            <h3 className="title-main">What you need</h3>
            1 large, deep pan
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Saute the chopped onion and garlic in some olive oil until
                fragrant. Add in the mushrooms and continue cooking for 2-3
                minutes{" "}
              </li>
              <li>
                <span>2</span>
                Add the barley and coat well with the mixture, stirring for a
                minute before adding the stock.
              </li>
              <li>
                <span>3</span>
                Cook for 35-40 minutes, gently stirring occassionaly to ensure
                that the bottom does not burn. Remove from heat and stir in half
                of the the parmesan cheese or nutritional yeast, and the black
                pepper.
              </li>
              <li>
                <span>4</span>
                Drizzle some truffle oil. Garnish with the remaining cheese (or
                nutritional yeast), almonds, and parsley.
              </li>
            </ul>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Mushroom_barley_risotto;
