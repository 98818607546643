import React, { Component } from "react";
import Back from "../Button/Back";
import YoutubeWorkout from "./YoutubeWorkout";
import SliderWorkout from "../Slider/SliderWorkout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Cardio from "../Cardio";
import { API } from "../../config/config";
import { setSelectedChallenge } from "../../store/actions/Challenge";
class Workout extends Component {
  backToProgramme = () => {
    this.props.history.push("/programme");
  };

  onClick = () => {
    const { week } = this.props;
    this.props.history.push(`/Cardio/${week}`);
  };
  componentDidMount() {
    this.props.setSelectedChallenge(null);
  }
  render() {
    const { week, message, cardioList } = this.props;
    let newCardioList = cardioList.reduce((r, a) => {
      r[a.week] = { session: a.session, id: a.id, duration: a.duration };
      return r;
    }, {});

    const session = newCardioList[week] ? newCardioList[week].session : null;
    //console.log('session', session)
    const videoId = message[week] ? message[week].video : null;
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <Back onClick={this.backToProgramme} />
          <h4>{week >= 1 ? `Week ${week}` : `FREE Trial Week`}</h4>

          <a />
        </div>
        <div className="page-content page-content-workout">
          {videoId ? <YoutubeWorkout videoId={videoId} /> : null}
          <div className="width_workout_slider">
            <h3 className="title-main">Programme</h3>
            <SliderWorkout />
          </div>
          {session ? (
            <div className="cardiooutside">
              <h3 className="title-main">Cardio</h3>
              <div className="box">
                <p>
                  {" "}
                  {session} {session > 1 ? "cardios" : "cardio"} this week
                </p>{" "}
                <div className="button">
                  <button onClick={this.onClick} className="button">
                    Start Cardio
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.workOutList,
    ...state.cardioReducer,
  };
};
export default connect(
  mapStateToProps,
  { setSelectedChallenge }
)(Workout);
