import * as actionTypes from "./actionTypes";
import { headers } from "../../ultils/helpers";
import { API } from "../../config/config";


export const getAssessmentById = (assessmentId) => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ASSESSMENT_BY_ID_API, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ assessmentId })
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: actionTypes.GET_ASSESSMENT_BY_ID,
          data: data
        })
        resolve(data)
      })
      .catch(err => reject(err))
  })
}
export const getRepliedAssessment = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_REPLIED_ASSESSMENT_API, {
      method: 'GET',
      headers: headers
    })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type : actionTypes.GET_REPLIED_ASSESSMENT,
        data : data
      })
      resolve(data)
    })
    .catch(err => reject(err))
  })
  
}
export const updateStatus = (assessmentId) => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_ASSESSMENT_STATUS_API, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ assessmentId })
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err))
  })

}