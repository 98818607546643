import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import Moment from 'moment';

class DiaryChartWeekItem extends Component {
  render() {
    let { heartRate, date, maxHeartRate } = this.props;
    let length = heartRate.length;
    let max = null, min = null;
    /* if (maxHeartRate > 150) {
      maxHeartRate = 150
    } */

    if (length > 0) {
      max = Math.ceil(Math.max(...heartRate))
      /* if (max > 150) {
        max = 150
      } */
      min = Math.round(Math.min(...heartRate))
      /* if (min < 60) {
        min = 60
      } */
      // if(min === max) {
      //   min = max - 10
      // }
    }
    let maxTop = max ? (1 - (max / maxHeartRate)) * 100 : null
    //let minTop = min ? (1 - (min / maxHeartRate)) * 100 : null
    let maxHeight = max ? (max / maxHeartRate) * 100 : null
    let minHeight = min ? (min / maxHeartRate) * 100 : null
    //let height = maxHeight - minHeight;
    let height = 0;
    if (maxHeight && minHeight) {
      height = maxHeight - minHeight === 0 ? 10 : maxHeight - minHeight;
    }
    /* let tick = max - min;
    let list = [];
    let moreMin = [];
    let lessMax = [];
    heartRate.forEach(i => {
      if (i !== max && i !== min) {
        list.push(i)
      }
    })
    for (let i = 0; i < list.length; i++) {
      if (list[i] > tick) {
        lessMax.push(list[i])
      } else {
        moreMin.push(list[i])
      }
    }
    let tickMin = moreMin.length > 0 ? moreMin.reduce((sum, x) => sum += x) / moreMin.length : 0
    let tickMax = lessMax.length > 0 ? lessMax.reduce((sum, x) => sum += x) / lessMax.length : 0
    let tickMinTop = tickMin > 0 ? (1 - (tickMin / maxHeartRate)) * 100 : null;
    let tickMaxTop = tickMax > 0 ? (1 - (tickMax / maxHeartRate)) * 100 : null; */
    return (
      <div className={length > 0 ? "styleColumn" : "styleColumn styleHeight"}>
        {<div className="chartBar" style={{ height: `${height ? height : 0}%`, top: `${maxTop}%` }}></div>}
        {/* <div className="chartBar" style={{ height: `${minTop || minTop == 0 ? 5 : 0}%`, top: `${minTop}%` }}></div> */}
        <div className="dayChart">{Moment(date).format("ddd")}<span>{Moment(date).format("DD MMM")}</span></div>
        <div className="stepsChart">
          <div className="heartMM">
            <div><span>Min</span> {min}</div>
            <div><span>Max</span> <p>{max} <small>bpm</small></p></div>
          </div>

        </div>
      </div>
    )
  }
}
export default DiaryChartWeekItem