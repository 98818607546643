import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Japanese_tempeh_curry extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
         Meals
        </div>

        <div className="recipesMain">
          <div className="image">
            <img src={require("../../../images/recipes/lunch/05.jpg")} alt="" />
          </div>
          <p>
            Japanese Tempeh Curry
            <span> Serves: 4 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            300g Tempeh <br />
            1 medium onion, cut into medium chunks <br />1 large carrot (or 2
            medium), cut into small cubes approx. 1cm <br />
            1 cup brown rice <br />
            2 Japanese curry cubes <br />
            1 cup corn flour <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Cut the tempeh into squares, approximately 1.5cm. Lightly coat
                with corn flour and pan fry in olive oil until a crust forms.
              </li>
              <li>
                <span>2</span>
                Saute the carrots for 5 minutes, then add in the onion and
                continue cooking for 1-2 minutes.
              </li>
              <li>
                <span>3</span>
                Add in the Japanese curry cubes and sufficient water, depending
                on the brand that you are using. Refer to the packaging for the
                measurements.
              </li>
              <li>
                <span>4</span>
                Bring to a low simmer and cover with a lid for 5 minutes. Add
                more water if the curry is too thick, and let it reduce if it is
                too watery.
              </li>
              <li>
                <span>5</span>
                Spoon the curry over the brown rice and tempeh, and serve.
                Optional: Garnish with chives.
              </li>
            </ul>
            <div className="note">
              *Tip: This is great for meal prep as it freezes well
            </div>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Japanese_tempeh_curry;
