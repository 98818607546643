import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Okonomiyaki extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Snacks
        </div>

        <div className="recipesMain">
          <div className="image">
            <img
              src={require("../../../images/recipes/snacks/04.jpg")}
              alt=""
            />
          </div>
          <p>
            Okonomiyaki
            <span> Serves: 3-4 as a snack, 2 for a full meal </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients (suggested)</h3>
            Capsicum, 0.5 cup
            <br />
            Mushrooms of any kind, 0.5 cup
            <br />
            Cabbage, thinly sliced, 0.5 cup
            <br />
            Carrots, thin strips, 1 cup
            <br />
            Seafood or protein of choice: 1 cup
            <br />
            Okonomiyaki sauce
            <br />
            Light mayonnaise
            <br />
            <h3 className="ttchild">For the batter</h3>
            0.5 cups (or 60g) plain flour
            <br />
            1/4 tsp salt
            <br />
            1/2 cups dashi (optional- can substitute with water and 1tbsp soy
            sauce)
            <br />
            2-3 large eggs
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>Cut all the ingredients listed.
              </li>
              <li>
                <span>2</span>
                Mix the batter, then add in the chopped vegetables and protein
                of choice.
              </li>

              <li>
                <span>3</span>
                In a non stick pan, pan fry it with a small amount of oil for
                about 3-4 minutes on medium heat, or until the bottom surface
                has a nice brown colour and the pancake is stiff enough to be
                flipped.
              </li>
              <li>
                <span>4</span>A trick for flipping the pancake is to tightly
                cover the top of the pan with a large plate, then flip the pan
                over such that the pancake is on the plate, then slide the
                uncooked side back into the pan and cook for another 2-3 minutes
                or until it is fully cooked.
              </li>
              <li>
                <span>5</span>
                Brush the top side with okonomiyaki suace, then transfer the
                pancake onto the serving plate using the method above. Finish
                off with a generous serving of okonomiyaki sauce and light
                mayonnaise. For the full experience, you can add katsuobushi
                flakes on top!
              </li>
            </ul>
            <div className="note">
              Tip: This freezes and keeps very well, so why not make more batter
              so that you can cook and freeze it!
            </div>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Okonomiyaki;
