import React, { Component } from "react";
import { Link } from "react-router-dom";
import Back from "../Button/Back";
import { connect } from "react-redux";
import { showProfile } from "../../store/actions/Profile";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import {
  checkUserPassword,
  updatePassword
} from "../../store/actions/UserInfo";
import Modal from "react-bootstrap/Modal";
import { sendMessage } from "../../ultils/ultils";

class ModalChangePassword extends Component {
  state = {
    currentPassword: null,
    newPassword: null,
    confirmNewPassword: null,
    isInvalid: false,
    errPassword: false,
    errConfirm: false,
    userId: null,
    emptyPass: false,
    currentPasswordEmpty: false,
    newPasswordEmpty: false,
    confirmNewPasswordEmpty: false
  };

  changePassword = async () => {
    const { email, id } = this.props.user;
    const { currentPassword, newPassword, confirmNewPassword } = this.state;

    if (currentPassword && newPassword && confirmNewPassword) {
      this.setState({
        currentPasswordEmpty: false,
        confirmNewPasswordEmpty: false,
        newPasswordEmpty: false
      });
      const { userId } = await this.props.checkUserPassword(
        email,
        currentPassword
      );
      if (userId == id) {
        this.setState({ errPassword: false });
        if (
          newPassword &&
          !newPassword.includes(" ") &&
          newPassword.length > 3
        ) {
          const isMatch = newPassword === confirmNewPassword;
          if (isMatch) {
            this.setState({ errConfirm: false });
            const { result } = await this.props.updatePassword(
              email,
              newPassword
            );
            if (result == id) {
              this.autoLogin(email, newPassword);
            } else {
              this.setState({ errConfirm: true });
            }
          } else {
            this.setState({ errConfirm: true });
          }
        }
      } else {
        this.setState({ errPassword: true });
      }
    } else {
      if (currentPassword) {
        this.setState({ currentPasswordEmpty: false });
      } else {
        this.setState({ currentPasswordEmpty: true });
      }
      if (newPassword) {
        this.setState({ newPasswordEmpty: false });
      } else {
        this.setState({ newPasswordEmpty: true });
      }
      if (confirmNewPassword) {
        this.setState({ confirmNewPasswordEmpty: false });
      } else {
        this.setState({ confirmNewPasswordEmpty: true });
      }
    }
  };
  onChange = e => {
    //this.setState({ emptyPass: false })
    this.setState({ [e.target.dataset.id]: e.target.value });
    this.setState({ [e.target.dataset.id + "Empty"]: false });
    //this.setState({ errPassword: false, errConfirm: false })
  };
  clearData = () => {
    this.setState({
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      errConfirm: false,
      errPassword: false,
      emptyPass: false,
      newPasswordEmpty: false,
      confirmNewPasswordEmpty: false,
      currentPasswordEmpty: false
    });
  };
  onHide = async () => {
    await this.clearData();
    this.props.onHide();
  };
  autoLogin = (email, password) => {
    const data = {type: WEBVIEW_ACTION.AUTO_LOGIN,
      email: email,
      password: password}
    sendMessage(data)
  };
  render() {
    let {
      errConfirm,
      errPassword,
      emptyPass,
      currentPasswordEmpty,
      newPasswordEmpty,
      confirmNewPasswordEmpty
    } = this.state;
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg" centered>
          <div className="page pageNomal">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.onHide}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>Change Password</h4>
              <Link to="#" className="icon" />
            </div>

            <div className="colm_costume">
              <form class="login changePassword">
                <div class="form-group">
                  <label
                    className={
                      errPassword || currentPasswordEmpty ? "text-danger" : null
                    }
                  >
                    {currentPasswordEmpty
                      ? "Current password is empty"
                      : errPassword
                      ? "Current password is not correct"
                      : "Current Password"}
                  </label>
                  <input
                    data-id="currentPassword"
                    onChange={e => this.onChange(e)}
                    type="password"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label
                    className={
                      errConfirm || newPasswordEmpty ? "text-danger" : null
                    }
                  >
                    {newPasswordEmpty
                      ? "New password is emptry"
                      : errConfirm
                      ? "Password does not match"
                      : "New Password"}
                  </label>
                  <input
                    data-id="newPassword"
                    onChange={e => this.onChange(e)}
                    type="password"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label
                    className={
                      errConfirm || confirmNewPasswordEmpty
                        ? "text-danger"
                        : null
                    }
                  >
                    {confirmNewPasswordEmpty
                      ? "Confirm new password is empty"
                      : errConfirm
                      ? "Password does not match"
                      : "Confirm New Password"}
                  </label>
                  <input
                    data-id="confirmNewPassword"
                    onChange={e => this.onChange(e)}
                    type="password"
                    class="form-control"
                  />
                </div>
                <div className="button">
                  <button onClick={this.changePassword} type="button">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.userInfo,
    ...state.workOutList,
    ...state.images
  };
};

export default connect(
  mapStateToProps,
  { checkUserPassword, updatePassword, showProfile }
)(ModalChangePassword);
