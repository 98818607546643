import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Img from "react-fix-image-orientation";
class PhotoItem extends Component {
  render() {
    const { img } = this.props;
    return (
      <div className="box">
        <div className="content">
          <Link to={`/SliderProgress/${img.id}`}>
            <Img key={img.id} src={img.photo} alt="" />
          </Link>
        </div>
      </div>
    );
  }
}

export default PhotoItem;
