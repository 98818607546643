import React, { Component } from "react";
import { WEBVIEW_ACTION } from "../config/config";
import { sendMessage } from "../ultils/ultils";

class ThanksSubscription extends Component {
  onClick = () => {
    //this.props.history.push('/Index')
    sendMessage({ type: WEBVIEW_ACTION.GO_TO_INDEX });
  };
  render() {
    return (
      <div className="page thanks_page fadeIn">
        <div>
          <img src={require("../images/Jumping_Jacks.gif")} alt="like" />
        </div>
        <div className="title_thanks">
          {/* <h3>
            Thank you <span>for your subcription!  </span>
          </h3>
          <p>Time to kickstart  your fitness journey </p> */}
          <p>You’re a step closer to kickstarting your fitness journey!</p>
          <div class="button_2">
            <button type="button" onClick={this.onClick}>
              START
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ThanksSubscription;
