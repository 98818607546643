import React, { Component, Fragment } from "react";
import Moment from "moment";
import { rateType } from "../../config/config";
import { connect } from "react-redux";
import DiaryItemChart from "./DiaryItemChart";
import { DIARY_TYPES } from "../../config/config";
import { setHeartRate } from "../../store/actions/Diary";
const { HAPPY, NORMAL } = rateType;

class DiaryItem extends Component {
  state = {
    heartRate: [],
  };
  componentDidMount() {
    window.WebViewBridge = {
      get_heart_rate: this._onMessage,
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
  }
  _onMessage = (data) => {
    let { heartRate } = this.props;
    if (
      heartRate.length === 0 ||
      !heartRate.find((item) => item.id === data.id)
    ) {
      this.props.setHeartRate(data);
    }
  };
  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  render() {
    const { diary, heartRate, os } = this.props;
    let startTime = Moment(diary.startTime);
    let endTime = Moment(diary.endTime);
    let timeDisplay = endTime.diff(startTime, "seconds");
    let min = Math.floor(timeDisplay / 60);
    console.log();
    let sec = timeDisplay - min * 60;
    var yourDate = new Date();
    console.log(yourDate.toLocaleTimeString());
    //console.log(Moment(startTime).toLocaleTimeString())

    return (
      <li className="event">
        <h3>
          {Moment(diary.startTime).format("DD MMM YYYY")}{" "}
          <span>{Moment(diary.startTime).format("hh:mm a")}</span>
        </h3>

        {diary ? (
          diary.type === DIARY_TYPES.WORKOUT ? (
            <div className="div-box-dsc">
              <div className="box-diary">
                {diary.week ? (
                  <p>
                    {diary.week === "Week 0" ? "FREE Trial Week" : diary.week} -{" "}
                    {diary.workoutName}
                  </p>
                ) : (
                  <p>Targeted Workout - {diary.workoutName}</p>
                )}

                {diary.feedback}
                {diary.emotion ? (
                  <img
                    src={require(diary.emotion.toString().toUpperCase() ===
                      HAPPY
                      ? "../../images/emo1.svg"
                      : diary.emotion.toString().toUpperCase() === NORMAL
                      ? "../../images/emo2.svg"
                      : "../../images/emo3.svg")}
                    alt=""
                  />
                ) : null}

                <DiaryItemChart
                  os={os}
                  startDate={diary.startTime}
                  endDate={diary.endTime}
                  id={diary.id}
                  data={
                    heartRate
                      ? heartRate.find((listItem) => listItem.id === diary.id)
                      : null
                  }
                />
                {
                  <p className="time-heart">
                    <span>{`${min} min`}</span>
                    <span className="line-time" />
                    <span>{`${sec} sec`}</span>
                  </p>
                }
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="div-box-dsc">
                <div className="box-diary">
                  <p>{diary.type ? this.capitalize(diary.type) : null}</p>
                  {diary.feedback}
                  {diary.emotion ? (
                    <img
                      src={require(diary.emotion.toString().toUpperCase() ===
                        HAPPY
                        ? "../../images/emo1.svg"
                        : diary.emotion.toString().toUpperCase() === NORMAL
                        ? "../../images/emo2.svg"
                        : "../../images/emo3.svg")}
                      alt=""
                    />
                  ) : null}
                  {
                    <p className="time-heart">
                      <span>{diary.duration} Min</span>
                      {/* <span className="line-time"></span>
                <span>{diary.duration} Min</span> */}
                    </p>
                  }
                </div>
              </div>
            </Fragment>
          )
        ) : null}
      </li>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.diary,
    ...state.userInfo,
    ...state.profileReducer,
    ...state.workOutList,
  };
};
export default connect(
  mapStateToProps,
  { setHeartRate }
)(DiaryItem);
