import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Grilled_Okra extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return <></>;
  }
}

export default Grilled_Okra;
