import * as actionTypes from "./actionTypes";
import { headers } from '../../ultils/helpers';
import { API } from "../../config/config";
export const getAllSurveys = () => dispatch => {
  fetch(API.SURVEYS_API, {
    headers: headers
  })
    .then(res => res.json())
    .then(data => {
      const transformedSurveys = data.surveys.reduce((obj, item) => {
        item.value = null;
        obj[item.id] = item;
        return obj;
      }, {});
      dispatch({
        type: actionTypes.GET_SURVEYS,
        data: { surveys: transformedSurveys, categories: data.categories }
      });
    })
    .catch(err => console.log(err));
};

export const updateSurvey = (surveys, categories) => dispatch => {
  const updatedSurvey = { ...surveys };
  return dispatch({
    type: actionTypes.UPDATE_SURVEYS,
    surveys: updatedSurvey,
    categories: categories
  });
};

export const nextCategory = index => dispatch => {
  return dispatch({
    type: actionTypes.NEXT_CATEGORY,
    index: index
  });
};

export const postSurvey = submitData => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.CREATE_SURVEY_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(submitData)
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err))
  });
};

export const backCategory = index => dispatch => {
  return dispatch({
    type: actionTypes.BACK_CATEGORY,
    index: index
  });
};

export const getAllWorkoutType = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_WORKOUT_TYPE_API, {
      method: 'GET',
      headers: headers
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: actionTypes.GET_ALL_WORKOUT_TYPE,
          data: data
        })
        return resolve(data)
      })
      .catch(err => reject(err))
  })
}

export const updateWorkoutType = (id) => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_WORKOUT_TYPE_API, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ id })
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err))
  })

}