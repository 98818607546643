import * as actionTypes from "../actions/actionTypes";
import { TYPES } from "../../config/config";

const initialState = {
  emotion: null,
  feedback: null,
  startTime: null,
  endTime: null,
  workoutName: null,
  week: null,
  heartRateWeek: [],
  heartRate: []
};

const diaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_EMOTION:
      return {
        ...state,
        emotion: action.data
      };
    case actionTypes.GET_START_TIME:
      return {
        ...state,
        startTime: action.data.startTime,
        workoutName: action.data.workoutName,
        week : action.data.week
      }
    case actionTypes.GET_END_TIME:
      return {
        ...state,
        endTime: action.data
      }
    case actionTypes.GET_HEART_RATE:
      return {
        ...state,
        heartRateWeek: action.data
      }
    case actionTypes.SET_HEART_RATE:
      return {
        ...state,
        heartRate: state.heartRate.length > 0 && !state.heartRate.find(item => item.id == action.data.id)
          ? [action.data, ...state.heartRate]
          : [action.data]
      }
    default:
      return state;
  }
};

export default diaryReducer;
