import React, { Component } from "react";
import { Link } from "react-router-dom";
import Back from "../Button/Back";
import { connect } from "react-redux";
import Textarea from "react-textarea-autosize";
import {
  getPhoto,
  uploadPhoto,
  uploadAssessment,
} from "../../store/actions/Photo";
import LoadingIcon from "../Loading/LoadingIcon";
import { updateUserAssessment } from "../../store/actions/UserInfo";
import {
  setProfileAssessment,
  reloadAssessment,
} from "../../store/actions/Profile";
import ImageTools from "../../ultils/ImageTools/ImageTools.js";
import { compressedFile, message } from "../../config/config";
import Modal from "react-bootstrap/Modal";
import { read } from "fs";
import Moment from "moment";
import ModalPhotoApp from "./ModalPhotoApp";
import ModalSubmit from "./ModalSubmit";
class ModalFeedBack extends Component {
  state = {
    fileUpload: null,
    isLoading: false,
    beforeImg: null,
    afterImg: null,
    images: [],
    message: "",
    assessment: [],
    beforePicture: null,
    afterPicture: null,
    userAssessment: null,
    errorAssessment: false,
    errImg: null,
    modalShowPhotoApp: false,
    modalSubmit: false,
    images: [],
    type: null,
    imageError: false,
    longTextError: false,
    onSubmit: false,
    maxLength: 3000,
  };
  onClick = () => {
    this.props.history.push("/index");
  };
  componentDidMount = async () => {
    await this.props.getPhoto();
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userProfile !== prevProps.userProfile) {
      const { userAssessment } = this.props.userProfile;
      this.setState({ userAssessment });
    }
    if (this.props.images !== prevProps.images) {
      const { images } = this.props;
      this.setState({ images });
    }
  }
  uploadFromCamera = (event, type) => {
    console.log(".....");
    const compress = new ImageTools();
    //let file = window.location.origin + "/data/uploads/photo-1563768883620.jpg";
    this.setState({ isLoading: true });
    if (event.target.files[0]) {
      this.setState({ errorAssessment: false, imageError: false });
      compress
        .resize(event.target.files[0], {
          width: compressedFile.maxWidthOrHeight,
          height: compressedFile.maxWidthOrHeight,
        })
        .then(async (img) => {
          console.log(img);
          let baseImg = await this.getBase64(img);
          if (type === "before") {
            this.setState({ beforeImg: baseImg, beforePicture: img });
          } else {
            this.setState({ afterImg: baseImg, afterPicture: img });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  };
  uploadFromApp = async (selectedItem, type) => {
    let file = window.location.origin + selectedItem.photo;
    this.setState({ errorAssessment: false, imageError: false });
    this.setState({ isLoading: true });
    let img = await fetch(file).then((r) => r.blob());
    if (type === "before") {
      this.setState({
        beforeImg: URL.createObjectURL(img),
        beforePicture: img,
      });
    } else {
      this.setState({ afterImg: URL.createObjectURL(img), afterPicture: img });
    }
  };
  clearData = () => {
    this.setState({
      beforeImg: null,
      afterImg: null,
      beforePicture: null,
      afterPicture: null,
      message: null,
      errorAssessment: false,
      onSubmit: false,
    });
  };
  onHide = async () => {
    await this.clearData();
    this.props.onHide();
  };
  openModal = () => {
    this.setState({ modalSubmit: true });
  };
  uploadAssessment = async () => {
    const {
      beforePicture,
      afterPicture,
      message,
      userAssessment,
      onSubmit,
      beforeImg,
      afterImg,
    } = this.state;
    const { user } = this.props;
    const { username, email } = user;
    if (beforePicture && afterPicture && message.length > 0) {
      this.setState({ onSubmit: true, modalSubmit: false });
      let assessment = userAssessment - 1;
      await this.props.uploadAssessment(
        beforePicture,
        afterPicture,
        message,
        username,
        email
      );
      await this.props.updateUserAssessment(assessment);
      await this.props.setProfileAssessment(assessment);
      await this.props.reloadAssessment();
      await this.clearData();
      this.props.onHide();
    } else {
      this.setState({
        errorAssessment: true,
        imageError: true,
        modalSubmit: false,
      });
    }
  };

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
  cancelImages = (e, type) => {
    type === "before"
      ? this.setState({ beforeImg: null, beforePicture: null })
      : this.setState({ afterImg: null, afterPicture: null });
  };
  onChange = (e) => {
    // const maxLength = 5;
    const { maxLength } = this.state;
    if (e.target.value.length < maxLength) {
      console.log(e.target.value.length);
      this.setState({
        errorAssessment: false,
        longTextError: false,
      });
      this.setState({ message: e.target.value });
    } else {
      this.setState({
        errorAssessment: true,
        longTextError: true,
      });
    }
  };
  modalClosePhotoApp = () => this.setState({ modalShowPhotoApp: false });
  modalCloseSubmit = () => this.setState({ modalSubmit: false });
  render() {
    const {
      beforeImg,
      afterImg,
      errorAssessment,
      images,
      onSubmit,
      imageError,
      longTextError,
      maxLength,
    } = this.state;
    let imgList =
      images.length > 0
        ? images.reduce((r, a) => {
            r[a.date] = r[a.date] || [];
            r[a.date].push(a);
            return r;
          }, {})
        : null;
    return (
      <div>
        <Modal className="modalProfileChild modalFb" {...this.props} size="lg">
          <div className="page pageFeedBack">
            <div className="page-header">
              {/* {!onSubmit ? <Link to="#" className="icon" disabled={true} onClick={this.onHide}>
                <img src={require("../../images/back.svg")} alt="" />
              </Link> : null} */}
              <Link
                to="#"
                className="icon"
                disabled={true}
                onClick={this.onHide}
              >
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>Request Assessment</h4>
              <Link to="#" className="icon" />
            </div>

            <div className="feedBack">
              {errorAssessment ? (
                <p className="errorAssessment">
                  {imageError && (
                    <small className="text-danger">
                      Please attach before and after photos for the assessment.
                    </small>
                  )}
                  <br />
                  {longTextError && (
                    <small className="text-danger">
                      Your assessment text is too long, please keep it under{" "}
                      {maxLength} characters.
                    </small>
                  )}
                </p>
              ) : (
                <div className="tuyenmessage">
                  <p style={{ marginBottom: 15 }}>
                    Hey! I’d like to know more about your progress so I can help
                    you get better results.{" "}
                  </p>

                  <p style={{ marginBottom: 15 }}>
                    Send me your before-after progress photos, notes, or any
                    questions you have! I’ll reply you in 3-5 working days.
                  </p>

                  <p style={{ marginBottom: 15 }}>Tyen</p>
                </div>
              )}

              <div className="fbUpload">
                <div className="boxImgUpload">
                  <h4>Before</h4>
                  {beforeImg ? (
                    <div className="imgUpload classNoImgUpload">
                      <p className="icUpload">
                        <img
                          src={
                            beforeImg
                              ? beforeImg
                              : require("../../images/upload.svg")
                          }
                          alt="upload"
                        />
                        <Link
                          to="#"
                          className="cancelImages"
                          onClick={(e) => this.cancelImages(e, "before")}
                        >
                          <img
                            src={require("../../images/ic_delete_images.svg")}
                            alt="upload"
                          />
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <div className="imgUpload">
                      <div className="btnUPload">
                        <p className="fCamera">
                          <img
                            src={require("../../images/ic_camera.svg")}
                            alt="upload"
                          />
                          Upload from <br />
                          camera roll
                          <input
                            onChange={(e) => this.uploadFromCamera(e, "before")}
                            type="file"
                            className="file"
                            accept="image/*"
                          />
                        </p>
                        <Link
                          onClick={() =>
                            this.setState({
                              modalShowPhotoApp: true,
                              type: "before",
                            })
                          }
                        >
                          <img
                            src={require("../../images/icn_progress.svg")}
                            alt="upload"
                          />
                          Upload from <br />
                          app photos
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <div className="boxImgUpload">
                  <h4>After</h4>
                  {afterImg ? (
                    <div className="imgUpload classNoImgUpload">
                      <p className="icUpload">
                        <img
                          src={
                            afterImg
                              ? afterImg
                              : require("../../images/upload.svg")
                          }
                          alt="upload"
                        />
                        <Link
                          to="#"
                          className="cancelImages"
                          onClick={(e) => this.cancelImages(e, "after")}
                        >
                          <img
                            src={require("../../images/ic_delete_images.svg")}
                            alt="upload"
                          />
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <div className="imgUpload">
                      <div className="btnUPload">
                        <p className="fCamera">
                          <img
                            src={require("../../images/ic_camera.svg")}
                            alt="upload"
                          />
                          Upload from <br />
                          camera roll
                          <input
                            onChange={(e) => this.uploadFromCamera(e, "after")}
                            type="file"
                            className="file"
                            accept="image/*"
                          />
                        </p>
                        <Link
                          onClick={() =>
                            this.setState({
                              modalShowPhotoApp: true,
                              type: "after",
                            })
                          }
                        >
                          <img
                            src={require("../../images/icn_progress.svg")}
                            alt="upload"
                          />
                          Upload from <br />
                          app photos
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="boxMessge">
                <h4>Your message to Tyen</h4>
                <textarea
                  onChange={this.onChange}
                  value={this.state.message}
                  placeholder={message}
                />
              </div>
              <div className="button">
                <button
                  className={onSubmit ? "disable" : "button-w220"}
                  type="button"
                  onClick={this.openModal}
                  disabled={onSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <ModalPhotoApp
          show={this.state.modalShowPhotoApp}
          backdrop="static"
          onHide={this.modalClosePhotoApp}
          imgList={imgList}
          onItemSelected={this.uploadFromApp}
          type={this.state.type}
        />
        <ModalSubmit
          backdrop="static"
          type={this.state.type}
          onSubmit={this.uploadAssessment}
          show={this.state.modalSubmit}
          onBack={this.modalCloseSubmit}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.profileReducer,
    ...state.images,
  };
};
export default connect(mapStateToProps, {
  getPhoto,
  uploadPhoto,
  uploadAssessment,
  updateUserAssessment,
  setProfileAssessment,
  reloadAssessment,
})(ModalFeedBack);
