import React, { Component } from 'react'

export class LoadingIcon extends Component {
  render() {
    return (
      <div>
        <div className="loader_page"> </div>
        <div className="loader"></div>

      </div>


    )
  }
}

export default LoadingIcon
