import * as actionTypes from '../actions/actionTypes';

const initialState = {
    surveys: null,
    categories: null,
    isLoading: true,
    index: 0,
    workoutTypeList: []
}

const surveysReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SURVEYS:
            return {
                ...state,
                categories: action.data.categories,
                surveys: action.data.surveys,
                isLoading: false
            }
        case actionTypes.UPDATE_SURVEYS:
            return {
                ...state,
                surveys: action.surveys,
                categories: action.categories
            }

        case actionTypes.NEXT_CATEGORY:
            return {
                ...state,
                index: action.index + 1
            }
        case actionTypes.BACK_CATEGORY:
            return {
                ...state,
                index: action.index - 1
            }
        case actionTypes.GET_ALL_WORKOUT_TYPE:
            return {
                ...state,
                workoutTypeList: action.data
            }
        default:
            return state;
    }
}

export default surveysReducer
