import * as actionTypes from '../actions/actionTypes';

const initialState = {
  cardioList : [],
  cardioSession : null,
  allCardioHistory : null
}

const cardioReducer = (state = initialState , action ) => {
  switch(action.type) {
    case actionTypes.GET_ALL_CARDIO:
      return {
        ...state,
        cardioList : action.data
      }
      case actionTypes.GET_CARDIO_LEFT:
        return {
          ...state,
          cardioSession  : action.data
        }
      case actionTypes.GET_ALL_CARDIO_HISTORY:
        return {
          ...state,
          allCardioHistory : action.data
        }
    default:
      return state
  }
}

export default cardioReducer;