import React, { Component, Fragment } from "react";
import PhotoItem from "./PhotoItem";
import Moment from "moment";
import ModalwthWorkout from "../Modal/ModalwthWorkout";
class Photos extends Component {
  onClick = () => {
    console.log(".....");
  };
  render() {
    const { images, date, steps } = this.props;
    let dateFormat = Moment(date).format("DDMMYYYY");
    return (
      <Fragment>
        <h3 className="steps">
          {Moment(date).format("DD MMM YYYY")}{" "}
          <span>
            {steps && steps[dateFormat] ? ` ${steps[dateFormat]} steps` : null}
          </span>
        </h3>
        <div key={date} className="photosContainer">
          {images
            ? images.map(img =>
                img.date === date ? (
                  <PhotoItem key={img.id} onClick={this.onClick} img={img} />
                ) : null
              )
            : null}
        </div>
      </Fragment>
    );
  }
}

export default Photos;
