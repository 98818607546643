import html2canvas from "html2canvas";
import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Confetti from "react-dom-confetti";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import soundTest from "../../assets/applause.mp3";
import {
  DOMAIN_ICON,
  HTMLCANVAS_CONFIG,
  WEBVIEW_ACTION,
} from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import moment from "moment";
class ModalAchievementPopUp extends Component {
  state = {
    confet: false,
    index: 0,
    showShareScreen: false,
    enableShareButton: true,
  };
  audio = new Audio(soundTest);
  componentDidUpdate = (prevProps, prevState) => {
    if (
      (prevProps.show !== this.props.show && !this.props.stopSound) ||
      (prevState.index !== this.state.index && this.props.show)
    ) {
      console.log({ updateIndex: this.state.index });
      this.audio.pause();
      this.audio.currentTime = 0;
      this.setState({ confet: true });
      setTimeout(() => {
        this.audio.play();
      }, 200);
    } else {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  };
  onShare = async (photo) => {
    const { appVersion } = this.props;
    let canShare = parseInt(appVersion) > 25;
    if (canShare) {
      this.setState({ enableShareButton: false, showShareScreen: true }, () => {
        html2canvas(
          document.querySelector("#achievementPanel"),
          HTMLCANVAS_CONFIG
        ).then((canvas) => {
          const imgUrl = canvas.toDataURL("image/jpeg");
          sendMessage({
            type: WEBVIEW_ACTION.ON_SHARE,
            imgUrl: imgUrl,
            imgType: "BASE64",
          });
          this.setState({ showShareScreen: false, enableShareButton: true });
        });
      });
    }
  };
  onClick = () => {
    console.log("On Click Modal PopUP");
    this.setState({ confet: false });
    const { index } = this.state;
    const { achievementsList } = this.props;
    //console.log(achievementsList.length);
    let nextIndex = index + 1;
    if (nextIndex < achievementsList.length) {
      console.log("On next Index");
      this.setState({ index: nextIndex });
    } else {
      console.log("onClose");
      this.props.onClose();
    }
  };
  render() {
    const config = {
      angle: "107",
      spread: "91",
      startVelocity: 45,
      elementCount: 50,
      dragFriction: 0.1,
      duration: "3000",
      stagger: 3,
      width: "10px",
      height: "10px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    };
    const { index, showShareScreen } = this.state;
    let title, description, photo;
    const { achievementsList, achievementsListsDetail } = this.props;
    if (achievementsList.length > 0 && achievementsList[index]) {
      title = achievementsListsDetail[achievementsList[index]].title;
      description =
        achievementsListsDetail[achievementsList[index]].description;
      photo = achievementsListsDetail[achievementsList[index]].photo;
      //console.log({ title, description, photo });
    }
    console.log({ achievementsListsDetail });
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg">
          <div className="page pageFeedBack">
            <div className="page-header">
              {showShareScreen ? null : (
                <Fragment>
                  <Link to="#" className="icon" onClick={this.onClick}>
                    {/* <img src={require("../../images/back.svg")} alt="" />
                Back */}
                  </Link>
                  <h4 className="adward">
                    <img src={require("../../images/larel.png")} alt="" />
                    Achievements
                    <img
                      className="rotate"
                      src={require("../../images/larel.png")}
                      alt=""
                    />
                  </h4>
                  <Link to="#" className="icon" onClick={this.onClick}>
                    <img src={require("../../images/ic_close.svg")} alt="" />
                  </Link>
                </Fragment>
              )}
            </div>

            <div className="privacy achievements">
              <div
                className={
                  showShareScreen ? "content achivementClass" : "content"
                }
                id="achievementPanel"
              >
                {showShareScreen ? (
                  <div className="logoShare">
                    <img src={require("../../images/logo.png")} />
                    <Link>www.nosweatfitnessapp.com</Link>
                  </div>
                ) : null}

                <div className="imgLarge">
                  {photo ? (
                    <img src={`${DOMAIN_ICON}/${photo}`} alt="" />
                  ) : null}
                </div>

                <h1>{title ? title : ""}</h1>
                {/* <h3>Completed full week’s programme within one week</h3> */}
                <img
                  className="mt-0"
                  src={require("../../images/laurel_line.svg")}
                  alt=""
                />
                <p>
                  {description ? description : ""}
                  {/* <span>Number achieved: 4</span> */}
                  <span>{`Awarded on ${moment().format("DD MMM YYYY")}`}</span>
                </p>
              </div>
              <Confetti active={this.state.confet} config={config} />
              <div class="button_2">
                <button
                  className="blue"
                  type="button"
                  onClick={() => this.onShare(photo)}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.workOutList,
    ...state.images,
    ...state.profileReducer,
    ...state.sliderReducer,
    ...state.cardioReducer,
    ...state.assessmentReducer,
    ...state.eventsReducer,
    ...state.achievementsReducer,
  };
};
export default connect(mapStateToProps, {})(ModalAchievementPopUp);
