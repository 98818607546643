import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllAssessment } from "../../store/actions/Profile";
import ReadMoreAndLess from "react-read-more-less";
import ModalAssessmentItem from "./ModalAssessmentItem";
import {
  setProfileAssessment /* reloadAssessment */
} from "../../store/actions/Profile";
import Moment from "moment";
import ModalAssessmentListDetail from "./ModalAssessmentListDetail";

class ModalAssessmentList extends Component {
  state = {
    profile_picture: null
  };
  componentDidMount = async () => {
    const assessmentList = await this.props.getAllAssessment();
    this.setState({ assessmentList });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.reloadAssessment === true) {
      const assessmentList = await this.props.getAllAssessment();
      this.setState({ assessmentList });
    }
    if (this.props.userProfile !== prevProps.userProfile) {
      const { profile_picture } = this.props.userProfile;
      this.setState({ profile_picture });
    }
  };

  render() {
    const { assessmentList, profile_picture } = this.state;
    return (
      <div>
        <Modal
          className="modalProfileChild modalListAssessment"
          {...this.props}
          size="lg"
        >
          <div className="page pageFeedBack">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.props.onHide}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>Assessment List</h4>
              <Link to="#" className="icon" />
            </div>
            <div style={{ marginTop: "55px" }}>
              {assessmentList && assessmentList.length > 0 ? (
                <ul className="listAssessement">
                  {assessmentList.map((item, i) => (
                    <Fragment key={i}>
                      <ModalAssessmentItem
                        message={item.message}
                        reply={item.reply}
                        date={item.date}
                        beforePicture={item.beforePicture}
                        afterPicture={item.afterPicture}
                        profile_picture={profile_picture}
                      />
                    </Fragment>
                  ))}
                </ul>
              ) : (
                <div className="empty__images3">
                  <img
                    src={require("../../images/nolistassessment.svg")}
                    alt=""
                  />
                  <span>You have not submitted any assessments yet.</span>
                </div>
              )}
              {/* <ModalAssessmentItem /> */}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.profileReducer
  };
};
export default connect(
  mapStateToProps,
  { getAllAssessment }
)(ModalAssessmentList);
