import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button } from "react-bootstrap";
import DateTime from "react-datetime";

export class ModalScheduleEdit extends Component {
  render() {
    return (
      <div>
        <Modal className="modal-custom" {...this.props} size="lg" centered>
          <Link to="#" className="icon">
            <img
              onClick={this.props.onHide}
              src={require("../../images/ic_close_p.svg")}
              alt=""
            />
          </Link>
          <Modal.Body></Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ModalScheduleEdit;
