import React, { Component } from "react";

class ButtonNext extends Component {
  render() {
    const { disable } = this.props;
    return (
      <div>
        {(disable || disable===undefined) ? (
          <button className="disable" type="button">
            next
          </button>
        ) : (
          <button
            onClick={this.props.onClick}
            type="button"
          >
            next
          </button>
        )}
      </div>
    );
  }
}

export default ButtonNext;
