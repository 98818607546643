import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";

class ModalRating extends Component {
  render() {
    const { text } = this.props;
    return (
      <Modal className="modal-custom bg" {...this.props} size="lg" centered>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>{text}</div>
          <div className="button buttoncalendar">
            {/* <button className="buttonDisable" onClick={this.props.onCancel}>
              CANCEL
            </button> */}

            <button onClick={this.props.onHandler}>OK</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalRating;
