import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BOOL_VALUE } from "../../../config/config";
import ButtonDownload from "../ButtonDownload";

export class Chicken_tikka_with_vegetable_basmati_rice extends Component {
  render() {
    const { isOnApp } = this.props.match.params;

    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Meals
        </div>

        <div className="recipesMain">
          <div className="image">
            <img src={require("../../../images/recipes/lunch/02.jpg")} alt="" />
          </div>
          <p>
            Chicken Tikka with Vegetable Basmati Rice
            <span> Serves: 2 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            <div className="ttchild">For the chicken</div>
            300g chicken breast, cut into 2 inch cubes
            <br />
            <div style={{ lineHeight: 1.4, margin: "5px 0" }}>
              Tandoori chicken spice mix (adjust the amount according to the
              instructions, as each brand is different)
            </div>
            70g thick yogurt, strained
            <br />
            <div className="ttchild">
              DIY spice mix (if not using store-bought tandoori chicken spice
              mix)
            </div>
            2 tsp red chilli powder
            <br />
            10g grated ginger (or ginger paste)
            <br />
            10g grated garlic (or garlic paste)
            <br />
            1 tsp cumin powder
            <br />
            1.5 tsp garam masala powder
            <br />
            1 tsp coriander powder
            <br />
            1 tbsp lime juice
            <br />
            <div className="ttchild">For the rice</div>
            1 cup basmati rice
            <br />
            1 ¾ cup water
            <br />
            2 tsp turmeric powder
            <br />
            1 cinnamon stick
            <br />
            3 cloves
            <br />
            2 pods cardamom
            <br />
            Salt to taste
            <br />
            1 capsicum, cut into thin strips
            <br />
            1/2 onion (cut into medium chunks)
            <br />
            1/2 cup frozen peas and carrots (optional)
            <br />
            <h3 className="title-main">What you need</h3>
            An oven (or a toaster oven)
            <br />
            A rice cooker (or deep pot with lid)
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Marinate the chicken in the yogurt and spice mix for up to 24
                hours. Otherwise, 30 minutes is sufficient.
              </li>
              <li>
                <span>2</span>
                Prepare and chop the vegetables for the rice. You can add any
                hardy, non-leafy leftover vegetables you have around the house.
                Mix in all the ingredients, including the spices. Add water and
                give it a stir, then proceed to cook it with the rice cooker.
              </li>
              <li>
                <span>3</span>
                Pre-heat the oven to 210ºC, on the grill setting. Put the
                chicken in a tray and place it no more than 10cm away from the
                grill. Roast for 10 minutes. Then, flip the chicken pieces over
                and return to the oven for another 5 minutes at 200ºC.
              </li>
              <li>
                <span>4</span>
                Plate the dish and garnish with coriander and lime wedges
                (optional)
              </li>
            </ul>
            <div className="note">
              Notes: This dish is simple if you were to use pre-mixed spices. It
              is an impressive dish to dole out, for minimal effort! This recipe
              can easily be multiplied and the leftovers can be frozen for meal
              prep.
            </div>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Chicken_tikka_with_vegetable_basmati_rice;
