import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

class ProgressBar extends Component {
  render() {
    const{progress, i, percent, restTime} = this.props
    return (
      <Fragment>
        {
          !restTime ? 
          <div
            className={progress[i] ? "progress-bar" : "progress-bg"}
            role="progressbar"
            style={{ width: `${percent}%` }}
            aria-valuenow={percent}
            aria-valuemin="0"
            aria-valuemax="100"
        /> : null
        }
        
      </Fragment>
      
    );
  }
}

export default ProgressBar;
