import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Umami_pumpkin_mash_on_toast extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Breakfast
        </div>

        <div className="recipesMain">
          <div className="image">
            <img
              src={require("../../../images/recipes/breakfast/02.jpg")}
              alt=""
            />
          </div>
          <p>
            Umami Pumpkin Mash on Toast
            <span> Serves: 2-3 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            14 pumpkin (approx 300g skin on) <br />
            Olive oil <br />
            Bread <br />
            Chives <br />
            Sesame seeds <br />
            1 tbsp Worcester sauce <br />
            1 large tomato or 10 cherry tomatoes <br />
            Black pepper <br />
            <h3 className="title-main">What you need</h3>
            Aluminium foil <br />
            An oven (see notes below for alternatives) <br />
            Something to toast your bread - a toaster oven, oven, or non-stick
            pan <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                On a large sheet of aluminium foil, lightly drizzle some olive
                oil. Wrap the pumpkin and the tomatoes in the foil, and roast in
                the oven for 30 mins at 350ºF.
              </li>
              <li>
                <span>2</span>
                Unwrap the pumpkin and cut in half, then scoop out the seeds.
                The flesh should be soft enough to mash with a fork. If not,
                continue roasting in the oven for another 15 minutes.
              </li>
              <li>
                <span>3</span>
                Scoop out all the flesh into a large bowl and mash it with a
                fork, together with the tomato and Worcester sauce.
              </li>
              <li>
                <span>4</span>
                Drizzle some olive oil on your bread and toast it lightly.
              </li>
              <li>
                <span>5</span>
                Add your smashed pumpkin onto the toast, and finish with a
                drizzle of your sauce, black pepper, and sesame seeds.
              </li>
            </ul>
            <div className="note">
              *If you do not have an oven, de-skin and cut the pumpkin into
              smaller pieces, then follow the above step and use an air
              fryer/toaster oven and cook until it is soft enough to mash with a
              fork.
            </div>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Umami_pumpkin_mash_on_toast;
