import React, { Component } from "react";
import SurveyItem from "./SurveyItem";
class Survey extends Component {
  render() {
    const { categories, surveys, userInfo, dateErr } = this.props;

    return categories ? (
      <div className="colm_costume colm_info">
        <h3
          dangerouslySetInnerHTML={{
            __html: categories.title.replace(
              "#NAME#",
              `<span>${
                userInfo.name.value ? userInfo.name.value : "GUEST"
              }</span>`
            )
          }}
        ></h3>
        <div className="box-info-check">
          <p>{categories.description} </p>
          <ul>
            {Object.keys(surveys).map(key =>
              surveys[key].categoryId === categories.id ? (
                <SurveyItem
                  onChange={this.props.onChange}
                  onClick={this.props.onClick}
                  key={key}
                  data={surveys[key]}
                  dateErr={dateErr}
                />
              ) : null
            )}
          </ul>
        </div>
      </div>
    ) : null;
  }
}

export default Survey;
