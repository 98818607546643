import React, { Component, Fragment } from "react";
import { TYPES } from "../../config/config";
import DateTime from "react-datetime";
import "../../../node_modules/react-datetime/css/react-datetime.css";
import moment from "moment";
const SurveyItem = props => {
  return props.data.type === TYPES.DATE ? (
    <li>
      <div className="radio">
        <label
          htmlFor={props.data.id}
          className={props.dateErr ? "radio-label text-danger" : "radio-label"}
        >
          <p>{props.data.name}</p>
          {/* <DateTime
            dateFormat="DD MM YYYY"
            placeholder="dd/mm/yyyy"
            timeFormat={false}
            closeOnSelect={true}
            inputProps={{ readOnly: true, placeholder: "dd/mm/yyyy" }}
            value={props.data.value}
            onChange={e => props.onChange(props.data.id, props.data.type, e)}
          /> */}
          <Fragment>
            <input
              value={
                props.data.value
                  ? moment(props.data.value).format("YYYY-MM-DD")
                  : moment()
                      .add(30, "days")
                      .format("YYYY-MM-DD")
              }
              onChange={e => props.onChange(props.data.id, props.data.type, e)}
              type="date"
              className={
                props.data.value && props.data.value.length > 0
                  ? ""
                  : "disableInputDate"
              }
            />
          </Fragment>
        </label>
      </div>
    </li>
  ) : props.data.type === TYPES.TEXTBOX ? (
    <li>
      <textarea
        id={props.data.id}
        name={props.data.description}
        rows="4"
        placeholder=" Other reason(s): "
        value={props.data.value || ""}
        onChange={e => props.onChange(props.data.id, props.data.type, e)}
      />
    </li>
  ) : (
    <li>
      <div className="radio">
        <input
          onChange={e => props.onChange(props.data.id, props.data.type, e)}
          // onClick={e => props.onChange(props.data.id, props.data.type, e)}
          id={props.data.id}
          name={props.data.type}
          type={props.data.type}
          checked={props.data.value !== null}
        />
        <label htmlFor={props.data.id} className="radio-label">
          <p>
            {props.data.name}
            <span> {props.data.description} </span>
          </p>
          <i />
        </label>
      </div>
    </li>
  );
};

export default SurveyItem;
