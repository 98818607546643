import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAssessmentById, updateStatus } from "../../store/actions/Assessment"
import Moment from "moment";

class AssessmentMessage extends Component {
  componentDidMount = () => {
    const { assessmentId } = this.props.match.params;
    this.props.getAssessmentById(assessmentId)
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.assessment !== prevProps.assessment) {
      const { assessmentId } = this.props.match.params;
      this.props.updateStatus(assessmentId)
    }
  }
  onBack = () => {
    this.props.history.push('/index')
  }
  render() {
    const {
      assessment, user
    } = this.props;
    console.log(assessment)
    return (
      <div>
        {assessment ? <Fragment
          className="modalProfileChild modalListAssessment"
          // {...this.props}
          size="lg"
        >
          <div className="page pageFeedBack">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.onBack}>
                <img src={require("../../images/back.svg")} alt="" />
              </Link>
              <h4>{Moment(assessment.date).format("DD MMMM YYYY")}</h4>
              <Link to="#" className="icon" />
            </div>
            <div className="privacy">
              <div class="msgerChat">
                <div className="msgBubbleImage">
                  <div>
                    <h4>Before</h4>
                    <img src={assessment.beforePicture} alt="" />
                  </div>
                  <div>
                    <h4>After</h4>
                    <img src={assessment.afterPicture} alt="" />
                  </div>
                </div>
                <div class="msg msgLeft">
                  <div className="msgImage">
                    <img
                      src={
                        user.profile_picture
                          ? user.profile_picture
                          : require("../../images/user.svg")
                      }
                      alt=""
                    />
                  </div>
                  <div class="msgBubble">{assessment.message}</div>
                </div>
                {assessment.reply ? (
                  <div class="msg msgRight">
                    <div className="msgImage">
                      <img src={require("../../images/tyen.jpg")} alt="" />
                    </div>
                    <div class="msgBubble">{assessment.reply}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Fragment> : null}
      </div>
    );
  }


}


const mapStateToProps = state => {
  return {
    ...state.userInfo,
    ...state.profileReducer,
    ...state.assessmentReducer
  };
};
export default connect(
  mapStateToProps,
  { getAssessmentById, updateStatus }
)(AssessmentMessage);


