import React, { Component } from "react";
import Back from "../Button/Back";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ListWorkoutContent from "./ListWorkoutContent";
import { getVideo } from "../../store/actions/GetVideo";
import { getStartTime } from "../../store/actions/Diary";
import { sendMessage } from "../../ultils/ultils";
import {
  MAXIMUM_TARGET_WORKOUT_ALLOWED,
  MENU,
  WEBVIEW_ACTION,
} from "../../config/config";
import Moment from "moment";
import {
  getFullBodyWorkoutExerciseByCategory,
  changeFullBodyWorkoutStatus,
  changeCustomWorkoutStatus,
  getCustomWorkout,
  createCustomWorkout,
} from "../../store/actions/GetWorkOut";
import { TargetWorkoutItem } from "./TargetWorkoutItem";
import e from "cors";
const testcustomWorkoutList = [
  "Air Squats",
  "Split Squats",
  "Cobra Push Ups",
  "Crunches",
  "Jumping Jacks",
  "Split Squats",
];
class ListWorkout extends Component {
  state = {
    workOutCategory: null,
    customWorkoutList: [],
    updateCustomWorkout: false,
  };
  backToWorkout = () => {
    const { name, isFullBodyWorkout } = this.props.match.params;
    const { workOutList } = this.props;
    if (isFullBodyWorkout) {
      this.props.history.push("/challenges");
    } else {
      workOutList
        ? this.props.history.push("/workout")
        : this.props.history.push("/index");
    }
  };
  goToVideos = () => {
    this.props.history.push("/video");
  };

  getStartTime = async () => {
    let startTime = Moment().format("YYYY-MM-DD HH:mm:ss");
    const { name, isFullBodyWorkout } = this.props.match.params;
    let workoutName = name;
    const { week } = this.props;
    const { os } = this.props;
    // Update Custom Targeted Workout
    let isTargetedWorkout = parseInt(isFullBodyWorkout) > 0;
    const { customWorkoutList, updateCustomWorkout } = this.state;
    const { id } = this.props.match.params;
    let isCustomWorkout = false;
    let isAllowed = true;
    if (isTargetedWorkout && updateCustomWorkout) {
      await this.props.createCustomWorkout(id, customWorkoutList);
      this.setState({ updateCustomWorkout: false });
    }
    if (isTargetedWorkout && customWorkoutList.length > 0) {
      isCustomWorkout = true;
    }
    if (isTargetedWorkout && !customWorkoutList.length > 0) {
      isAllowed = false;
    }
    this.props.changeCustomWorkoutStatus(isCustomWorkout);
    this.props.getStartTime(startTime, workoutName, week);
    sendMessage({ type: WEBVIEW_ACTION.KEEP_AWAKE, status: true });
    const data = await this.props.getVideo(
      id,
      isTargetedWorkout,
      isCustomWorkout
    );
    //console.log({ data });
    //
    if (isAllowed) {
      setTimeout(() => {
        this.props.history.push(`/Video/${id}`);
      }, 200);
    }
  };

  alertMesssage = () => {
    setTimeout(() => {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: WEBVIEW_ACTION.ALERT_MESSAGE,
        })
      );
    }, 1);
  };
  _onMessage = (data) => {
    const { id } = this.props.match.params;
    if (data.type) {
      this.props.history.push(`/Video/${id}`);
    }
  };
  // componentDidUpdate  = () => {
  //   window.WebViewBridge = {
  //     alert_message: this._onMessage
  //   };
  //   const event = new Event("WebViewBridge");
  //   window.dispatchEvent(event);
  // }
  componentDidMount = async () => {
    /* let date = Moment(Date.now()).toISOString()
    alert(date) */
    window.WebViewBridge = {
      alert_message: this._onMessage,
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    const { id, isFullBodyWorkout } = this.props.match.params;
    let isTargetedWorkout = parseInt(isFullBodyWorkout) > 0;
    let workOutCategory = await this.props.getVideo(id, isTargetedWorkout);
    if (isTargetedWorkout) {
      const customWorkoutData = await this.props.getCustomWorkout(id);
      this.setState({ customWorkoutList: customWorkoutData });
    }
    this.props.changeFullBodyWorkoutStatus(isTargetedWorkout);
    //console.log({ isTargetedWorkout, id });
    // console.log({ workOutCategory });
    this.setState({ workOutCategory });
  };
  addTargetWorkoutItem = (item) => {
    //console.log({ item });
    const { customWorkoutList } = this.state;
    const newList = [...customWorkoutList];
    newList.push(item);
    if (newList.length <= MAXIMUM_TARGET_WORKOUT_ALLOWED) {
      this.setState({ customWorkoutList: newList, updateCustomWorkout: true });
    }
  };
  removeTargetWorkoutItem = (removeIndex) => {
    const { customWorkoutList } = this.state;
    const newList = [...customWorkoutList];
    const removedList = newList.filter((item, index) => index !== removeIndex);
    this.setState({
      customWorkoutList: removedList,
      updateCustomWorkout: true,
    });
  };
  render() {
    const { week } = this.props;

    const {
      id,
      description,
      name,
      duration,
      index,
      isFullBodyWorkout,
    } = this.props.match.params;
    const { workOutCategory, customWorkoutList } = this.state;
    let isCustomTargetWorkout = parseInt(isFullBodyWorkout) > 0;
    // console.log(workOutCategory);
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <Back onClick={this.backToWorkout} />
          {isCustomTargetWorkout ? (
            <h4>{`Custom ${name} Workout`}</h4>
          ) : (
            <h4>{week >= 1 ? `Week ${week}` : `FREE Trial Week`}</h4>
          )}

          <Link to="" className="icon" />
        </div>
        {isCustomTargetWorkout && (
          <div className="tags">
            <div className="textbellow">
              Exercises in order (select up to 6)
            </div>
            <ul>
              {customWorkoutList.length > 0 &&
                customWorkoutList.map((item, index) => (
                  <TargetWorkoutItem
                    key={index}
                    targetWorkoutName={item.name}
                    targetWorkoutIndex={index}
                    onClick={this.removeTargetWorkoutItem}
                  />
                ))}
            </ul>
            <div className="button">
              <Link
                to="#"
                onClick={
                  customWorkoutList.length > 0 ? this.getStartTime : null
                }
                className={`button-w220 ${!customWorkoutList.length > 0 &&
                  "disable"}`}
              >
                LET'S GO!
              </Link>
            </div>
          </div>
        )}
        <div className="page-content" style={{ paddingBottom: 55 }}>
          <div className="list-workout" style={{ marginTop: 0 }}>
            <h3 className="title-main" style={{ marginBottom: 4 }}>
              {isCustomTargetWorkout
                ? `${name}`
                : `Workout ${index} - ${description}`}
            </h3>
            {isCustomTargetWorkout && (
              <p className="textbellow textred">
                Tap on an exercise to add to your workout
              </p>
            )}
            {isCustomTargetWorkout ? null : (
              <p className="duration">
                {" "}
                <span>Duration</span>
                {duration} Min
              </p>
            )}

            <ul>
              {workOutCategory
                ? workOutCategory.map((item, index) => (
                    <ListWorkoutContent
                      key={item.index}
                      images="w1.jpg"
                      workoutName={item.name}
                      id={item.id}
                      reps={item.reps}
                      sets={item.sets}
                      img={item.gif}
                      workoutItem={item}
                      onClick={
                        isCustomTargetWorkout ? this.addTargetWorkoutItem : null
                      }
                    />
                  ))
                : null}
            </ul>
          </div>
        </div>
        {!isCustomTargetWorkout && (
          <div className="letgo-button">
            <div className="button">
              <Link to="#" onClick={this.getStartTime} className="button-w220">
                LET'S GO!
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.workOutList,
    ...state.videos,
  };
};
export default connect(
  mapStateToProps,
  {
    getVideo,
    getStartTime,
    getFullBodyWorkoutExerciseByCategory,
    changeFullBodyWorkoutStatus,
    changeCustomWorkoutStatus,
    getCustomWorkout,
    createCustomWorkout,
  }
)(ListWorkout);
