import React, { Component } from "react";
import Calendar from "react-calendar";
import AddToCalendar from "react-add-to-calendar";
import ModalRemoveSchedule from "../Modal/ModalSchedule";
import ModalAddSchedule from "../Modal/ModalAddSchedule";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import { connect } from "react-redux";
import { getAllSchedule, removeSchedule } from "../../store/actions/Schedule";
import moment from "moment";
import { sendMessage } from "../../ultils/ultils";
class CalendarSchedule extends Component {
  state = {
    event: {
      title: "Sample Event",
      description: "This is the sample event provided as an example only",
      location: "Portland, OR",
      startTime: "2016-09-16T20:15:00-04:00",
      endTime: "2016-09-16T21:45:00-04:00"
    },
    items: [{ apple: "iCal" }, { google: "Google" }],
    modalShowSchedule: false,
    modalAddSchedule: false,
    dateFormat: "YYYY-MM-DD",
    activeDay: null,
    scheduleList: null,
    data: [],
    date: null,
    keys: null,
    message: null,
    disable: false
  };
  componentDidMount = async () => {
    const data = await this.props.getAllSchedule();
    this.setState({ data });
  };
  _onMessage = async data => {
    const newData = await this.props.getAllSchedule();
    this.setState({ data: newData });
  };
  componentDidUpdate = async (preProps, preState) => {
    window.WebViewBridge = {
      get_schedule_list: this._onMessage
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    let { data, dateFormat } = this.state;
    if (preState.data !== this.state.data && data.length > 0) {
      const scheduleList = data.reduce((r, a) => {
        r[moment(a.date).format(dateFormat)] = {
          isWorkout: a.isWorkout,
          isCardio: a.isCardio
        };
        return r;
      }, {});
      //console.log(scheduleList);
      const keys = Object.keys(scheduleList);
      // console.log("keys", keys);
      this.setState({ keys, scheduleList });
    }
  };
  onChange = date => this.setState({ date });
  onClick = date => {
    const now = moment.now();
    const { dateFormat, scheduleList } = this.state;
    let day = moment(date).format(dateFormat);
    this.setState({ date });

    let today = moment(now).format(dateFormat);
    if (day >= today) {
      if (scheduleList && scheduleList[day]) {
        if (!scheduleList[day].isWorkout && !scheduleList[day].isCardio) {
          this.setState({ modalShowSchedule: true });
        }
      } else {
        this.setState({ modalAddSchedule: true });
      }
    }
  };
  addSchedule = async () => {
    const { date } = this.state;
    const data = {
      type: WEBVIEW_ACTION.SCHEDULE_WORKOUT,
      date: date
    }
    sendMessage(data)
    this.modalCloseAddSchedule();
  };
  removeSchedule = async () => {
    const { date } = this.state;
    this.setState({ disable: true });
    const result = await this.props.removeSchedule(date);
    if (result) {
      this.setState({ modalShowSchedule: false });
      const newData = await this.props.getAllSchedule();
      this.setState({ data: newData });
    }
  };
  modalCloseSchedule = () => this.setState({ modalShowSchedule: false });
  modalCloseAddSchedule = () => this.setState({ modalAddSchedule: false });
  getClassname = (date, view) => {
    let now = moment();
    let { dateFormat, activeDay, scheduleList, keys } = this.state;
    let day = moment(date).format(dateFormat);
    let today = now.format(dateFormat);
    let className = [];
    if (day === today) {
      className.push("activeDay");
    }
    if (keys && keys.length > 0) {
      keys.forEach(item => {
        if (day === item) {
          className.push("highline");
          let arr = this.setClassname(item);
          className = [...className, ...arr];
        }
      });
    }

    return className;
  };

  setClassname = item => {
    let arr = [];
    const { scheduleList } = this.state;
    scheduleList[item].isCardio ? arr.push("complete_cardio") : arr.push("");
    scheduleList[item].isWorkout ? arr.push("complete_workout") : arr.push("");
    return arr;
  };
  render() {
    const { dateFormat, activeDay, date } = this.state;
    return (
      <div>
        <Calendar
          //showNavigation={false}
          next2Label={null}
          prev2Label={null}
          onChange={this.onChange}
          value={this.state.date}
          onClickDay={e => this.onClick(e)}
          tileClassName={({ date, view = "year" }) =>
            this.getClassname(date, view)
          }
        />
        <ModalRemoveSchedule
          show={this.state.modalShowSchedule}
          onNo={this.modalCloseSchedule}
          onYes={this.removeSchedule}
        />
        <ModalAddSchedule
          show={this.state.modalAddSchedule}
          onNo={this.modalCloseAddSchedule}
          onYes={this.addSchedule}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { getAllSchedule, removeSchedule }
)(CalendarSchedule);
