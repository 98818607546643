import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
class MenuItem extends Component {
  render() {
    var { isActive, iconName, name, link, id } = this.props;
    return (
      <Fragment>
        <Link
          to={link}
          className={isActive}
          data-id={id}
          onClick={this.props.onClick}
        >
          <i className={iconName} />
          {name}
        </Link>
      </Fragment>
    );
  }
}

export default MenuItem;
