import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import App from "../App";
import Index from "../containers/Index";
import Info from "../containers/UserInfo/Info";
import Surveys from "../containers/Survey/Surveys";
import Loading from "../containers/Loading/Loading";
import Upgrade from "../containers/Upgrade";
import Video from "../containers/Video/Video";
import Resttime from "../containers/Resttime";
import Congratulations from "../containers/Congratulations";
import Programme from "../containers/Programme";
import Workout from "../containers/Workout/Workout";
import LinksYoutube from "../containers/LinksYoutube";
import ListWorkout from "../containers/Workout/ListWorkout";
import Progress from "../containers/Progress";
import Diary from "../containers/Diary/Diary";
import PhotoSlider from "../containers/Slider/SliderPhoto";
import SliderProgress from "../containers/Slider/SliderProgress";
import ExerciseTimer from "../containers/Excercise/ExerciseTimer";
import ExerciseReps from "../containers/Excercise/ExerciseReps";
import ExerciseRest from "../containers/Excercise/ExerciseRest";
import Thanks from "../containers/Thanks";
import ResetPassword from "../containers/ResetPassword";
import AssessmentMessage from "../containers/AssessmentMessage/AssessmentMessage";
import Privacy from "../containers/Privacy";
import Terms from "../containers/Terms";
import EditProfile from "../containers/EditProfile";
import ChangePassword from "../containers/ChangePassword";
import ThanksSubscription from "../containers/ThanksSubscription";
import Cardio from "../containers/Cardio";
import DemoWorkout from "../containers/Workout/DemoWorkout";
import DemoVideo from "../containers/Video/DemoVideo";
import DemoExerciseReps from "../containers/Excercise/DemoExerciseReps";
import DemoExerciseRest from "../containers/Excercise/DemoExerciseRest";
import DemoExercise from "../containers/Excercise/DemoExercise";
import DemoExerciseDetail from "../containers/Excercise/DemoExerciseDetail";
import DemoAchievement from "../containers/DemoAchievement";
import IntroVideo from "../containers/Video/IntroVideo";
import Recipes from "../containers/Recipes/Recipes";

import Umami_pumpkin_mash_on_toast from "../containers/Recipes/Breakfast/Umami_pumpkin_mash_on_toast";
import Microwave_steamed_egg_recipe from "../containers/Recipes/Breakfast/Microwave_steamed_egg_recipe";
import Spinach_and_shiitake_fritata from "../containers/Recipes/Breakfast/Spinach_and_shiitake_fritata";
import Siracha_avocado_toast from "../containers/Recipes/Breakfast/Siracha_avocado_toast";

import Tomato_egg_drop_noodle_soup from "../containers/Recipes/Lunch/Tomato_egg_drop_noodle_soup";
import Chicken_tikka_with_vegetable_basmati_rice from "../containers/Recipes/Lunch/Chicken_tikka_with_vegetable_basmati_rice";
import Lemongrass_chicken_salad from "../containers/Recipes/Lunch/Lemongrass_chicken_salad ";
import Mushroom_barley_risotto from "../containers/Recipes/Lunch/Mushroom_barley_risotto";
import Japanese_tempeh_curry from "../containers/Recipes/Lunch/Japanese_tempeh_curry";
import Mis_glazed_salmon_with_soba_noodles from "../containers/Recipes/Lunch/Mis_glazed_salmon_with_soba_noodles";
import Thai_style_lettuce_quorn_cups from "../containers/Recipes/Lunch/Thai_style_lettuce_quorn_cups";
// import Lunch08 from "../containers/Recipes/Lunch/Lunch08";

import Vegetable_platter_with_Asian_dips from "../containers/Recipes/Snacks/Vegetable_platter_with_Asian_dips";
import Mini_bang_bang_chicken_salad from "../containers/Recipes/Snacks/Mini_bang_bang_chicken_salad";
import Grilled_Okra from "../containers/Recipes/Snacks/Grilled_Okra";
import Okonomiyaki from "../containers/Recipes/Snacks/Okonomiyaki";
import Miso_glazed_popcorn from "../containers/Recipes/Snacks/Miso_glazed_popcorn";
import Challenges from "../containers/Challenges/Challenges";
import Challenge from "../containers/Challenges/Challenge";
import ChallengeProgramme from "../containers/Challenges/ChallengeProgramme";
import ChallengeWorkout from "../containers/Challenges/ChallengeWorkout";
import ChallengeExercise from "../containers/Challenges/ChallengeExercise";
import ChallengeVideo from "../containers/Challenges/ChallengeVideo";

export default (
  <Router>
    <div>
      <Route exact path="/" component={App} />
      <Route path="/Index/:type?" component={Index} />
      <Route path="/Info" component={Info} />
      <Route path="/Surveys" component={Surveys} />
      <Route path="/Loading" component={Loading} />
      <Route path="/Upgrade/:type?" component={Upgrade} />
      <Route path="/Video/:id/:isFullBodyWorkout?" component={Video} />
      <Route path="/DemoVideo/:id" component={DemoVideo} />
      <Route path="/Resttime" component={Resttime} />
      <Route path="/Congratulations/:id" component={Congratulations} />
      <Route path="/Programme" component={Programme} />
      <Route path="/Workout" component={Workout} />
      <Route path="/youtube" component={LinksYoutube} />
      <Route path="/intro" component={IntroVideo} />
      {/* <Route path="/ListWorkout" component={ListWorkout} /> */}
      <Route
        path="/ListWorkout/:id/:description/:name/:duration?/:index?/:isFullBodyWorkout?"
        component={ListWorkout}
      />
      <Route
        path="/DemoWorkout/:id/:week/:description/:name/:duration?/:index?"
        component={DemoWorkout}
      />
      <Route path="/DemoAchievement" component={DemoAchievement} />
      <Route path="/Progress" component={Progress} />
      <Route path="/Diary" component={Diary} />
      <Route path="/PhotoSlider" component={PhotoSlider} />
      <Route path="/SliderProgress/:id" component={SliderProgress} />
      <Route path="/ExerciseTimer" component={ExerciseTimer} />
      <Route path="/ExerciseReps" component={ExerciseReps} />
      <Route path="/ExerciseRest" component={ExerciseRest} />
      <Route path="/DemoExercise/:id" component={DemoExercise} />
      <Route path="/DemoExerciseReps/:link?" component={DemoExerciseReps} />
      <Route path="/DemoExerciseRest/:link?" component={DemoExerciseRest} />
      <Route path="/DemoExerciseDetail" component={DemoExerciseDetail} />
      {/* <Route
        path="/DemoExerciseDetailRest"
        component={DemoExerciseDetailRest}
      /> */}
      <Route path="/Thanks" component={Thanks} />
      <Route path="/ResetPassword/:token" component={ResetPassword} />
      <Route path="/Privacy" component={Privacy} />
      <Route path="/Terms" component={Terms} />
      <Route path="/EditProfile" component={EditProfile} />
      <Route path="/ChangePassword" component={ChangePassword} />
      <Route path="/ThanksSubscription" component={ThanksSubscription} />
      <Route path="/Cardio/:week" component={Cardio} />
      <Route path="/Message/:assessmentId" component={AssessmentMessage} />
      <Route path="/Challenges" component={Challenges} />
      <Route path="/ChallengeDetail" component={Challenge} />
      <Route path="/ChallengeProgramme" component={ChallengeProgramme} />
      <Route path="/ChallengeWorkout" component={ChallengeWorkout} />
      <Route path="/ChallengeExercise/:id" component={ChallengeExercise} />
      <Route path="/ChallengeVideo/:id" component={ChallengeVideo} />
      <Route
        path="/Recipes/:isUserExpired?/:isSubscriptionUser?/:os?/:appVersion?"
        component={Recipes}
      />

      <Route
        path="/Microwave_steamed_egg_recipe/:isOnApp?"
        component={Microwave_steamed_egg_recipe}
      />
      <Route
        path="/Umami_pumpkin_mash_on_toast/:isOnApp?"
        component={Umami_pumpkin_mash_on_toast}
      />
      <Route
        path="/Spinach_and_shiitake_fritata/:isOnApp?"
        component={Spinach_and_shiitake_fritata}
      />
      <Route
        path="/Siracha_avocado_toast/:isOnApp?"
        component={Siracha_avocado_toast}
      />

      <Route
        path="/Tomato_egg_drop_noodle_soup/:isOnApp?"
        component={Tomato_egg_drop_noodle_soup}
      />
      <Route
        path="/Chicken_tikka_with_vegetable_basmati_rice/:isOnApp?"
        component={Chicken_tikka_with_vegetable_basmati_rice}
      />
      <Route
        path="/Lemongrass_chicken_salad/:isOnApp?"
        component={Lemongrass_chicken_salad}
      />
      <Route
        path="/Mushroom_barley_risotto/:isOnApp?"
        component={Mushroom_barley_risotto}
      />
      <Route
        path="/Japanese_tempeh_curry/:isOnApp?"
        component={Japanese_tempeh_curry}
      />
      <Route
        path="/Mis_glazed_salmon_with_soba_noodles/:isOnApp?"
        component={Mis_glazed_salmon_with_soba_noodles}
      />
      <Route
        path="/Thai_style_lettuce_quorn_cups/:isOnApp?"
        component={Thai_style_lettuce_quorn_cups}
      />

      <Route
        path="/Vegetable_platter_with_Asian_dips/:isOnApp?"
        component={Vegetable_platter_with_Asian_dips}
      />
      <Route
        path="/Mini_bang_bang_chicken_salad/:isOnApp?"
        component={Mini_bang_bang_chicken_salad}
      />
      <Route path="/Grilled_Okra/:isOnApp?" component={Grilled_Okra} />
      <Route path="/Okonomiyaki/:isOnApp?" component={Okonomiyaki} />
      <Route
        path="/Miso_glazed_popcorn/:isOnApp?"
        component={Miso_glazed_popcorn}
      />
    </div>
  </Router>
);
