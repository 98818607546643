import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ModalPhotoList from "./ModalPhotoList";
export class ModalPhotoApp extends Component {
  state = {
    selectedItem: null
  };
  selectItemHandler = () => {
    const { selectedItem } = this.state;
    this.props.onItemSelected(selectedItem, this.props.type);
    this.props.onHide();
  };
  render() {
    const { imgList } = this.props;
    const { selectedItem } = this.state;
    return (
      <div>
        <Modal
          className="modalProfileChild modalListAssessment"
          {...this.props}
          size="lg"
        >
          <div className="page pageFeedBack">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.props.onHide}>
                <img src={require("../../images/back.svg")} alt="" />
              </Link>
              <h4>Photos</h4>
              <Link
                to="#"
                className="icon textUse"
                onClick={this.selectItemHandler}
              >
                {selectedItem ? "Use" : null}
              </Link>
            </div>
            <div className="photosApp">
              {imgList ? (
                Object.keys(imgList).map(key => {
                  return (
                    <ModalPhotoList
                    key={key}
                      date={key}
                      images={imgList[key]}
                      selectedId={selectedItem == null ? null : selectedItem.id}
                      onItemClick={img => {
                        this.setState({ selectedItem: img });
                      }}
                    />
                  );
                })
              ) : (
                <div className="empty__images">
                  {" "}
                  <img
                    src={require("../../images/emptyphoto.svg")}
                    alt=""
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalPhotoApp;
