import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";


class ModalSubmit extends Component {

  render() {
    return (
      <Modal className="modal-custom bg" {...this.props} size="lg" centered>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            Are you sure? You will not be able to edit it once you’ve submitted.
            </div>
          <div className="button buttoncalendar">
            <button className="buttonDisable" onClick={this.props.onBack}>
              BACK
              </button>

            <button onClick={this.props.onSubmit}>SUBMIT</button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalSubmit