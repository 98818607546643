import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { nextExercise } from "../../store/actions/Exercise";
import { connect } from "react-redux";
class ModalwithTimer extends Component {
  render() {
    return (
      <Modal
        className="content-popup modal-custom timerPopup"
        {...this.props}
        size="lg"
        centered
      >
        <Link to="#" className="icon" onClick={this.props.onHide}>
          {/* <img src={require("../../images/ic_close_p.svg")} alt="" /> */}
          Back
        </Link>

        <Modal.Body className="withWorkout">
          <h3>Are you sure you want to skip this exercise?</h3>
        </Modal.Body>
        <div className="modal-footer">
          <button onClick={this.props.onSkip} type="button">
            YES
          </button>
          <button type="button" onClick={this.props.onHide}>
            NO
          </button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.exerciseReducer,
    ...state.videosReducer
  };
};
export default connect(
  mapStateToProps,
  { nextExercise }
)(ModalwithTimer);
