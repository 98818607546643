import * as actionTypes from "../actions/actionTypes";
import { TYPES } from "../../config/config";

const initialState = {
  userInfo: {
    name: {
      label: "Your Name",
      placeholder: "__",
      type: TYPES.TEXTBOX,
      error: "",
      value: "",
      required: true
    },
    date: {
      label: "DOB",
      type: TYPES.DATE,
      placeholder: "dd/mm/yyyy",
      value: "",
      error: "",
      required: true
    },
    gender: {
      label: "Gender",
      type: TYPES.DROPDOWN,
      value: "",
      error: "",
      options: [
        { key: "__", value: "", hidden: true },
        { key: "Male", value: "M" },
        { key: "Female", value: "F" },
        { key: "Prefer not to say", value: "P" }
      ],
      required: true
    }
  },
  info: {
    name: "",
    year: "",
    sex: ""
  },
  user: {},
  isExpired: null,
  userExpired: null
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERNAME:
      return {
        ...state,
        user: action.data,
        userInfo: {
          ...state.userInfo,
          name: {
            ...state.userInfo.name,
            value: action.data.profile_name
          },
          date: {
            ...state.userInfo.date
          },
          gender: {
            ...state.userInfo.gender
          }
        }
      };
    case actionTypes.CHECK_EXPIRED:
      return {
        ...state,
        isExpired: action.data
      }
    case actionTypes.SET_USER_EXPIRED:
      return {
        ...state,
        userExpired: action.data
      }
    default:
      return state;
  }
};

export default infoReducer;
