import React, { Component } from "react";
import { connect } from "react-redux";
import { togglePlayAudio } from "../../store/actions/Profile";
export class Toggle extends Component {
  state = {
    playAudio: null,
  };
  componentDidMount = () => {
    const playAudio = localStorage.getItem("playAudio");
    this.setState({ playAudio: playAudio === "true" });
  };
  setToggleAudio = () => {
    const playAudio = localStorage.getItem("playAudio");
    console.log(playAudio);
    switch (playAudio) {
      case "true":
        this.setState({ playAudio: false });
        localStorage.setItem("playAudio", false);
        break;
      case "false":
        this.setState({ playAudio: true });
        localStorage.setItem("playAudio", true);
    }
  };
  render() {
    return (
      <>
        <div className="custom-switch custom-swicth-modal">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitchesChecked"
            checked={this.state.playAudio}
            onClick={this.setToggleAudio}
          />
          <label
            className="custom-control-label"
            htmlFor="customSwitchesChecked"
          >
            Play Audio Countdown Prompts (will stop background music)
          </label>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.profileReducer,
  };
};
export default connect(mapStateToProps, { togglePlayAudio })(Toggle);
