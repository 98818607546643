import * as actionTypes from "../actions/actionTypes";

const initialState = {
  images: []
};

const photoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PHOTO:
      return {
        ...state,
        images: action.images
      };
    case actionTypes.UPDATE_PHOTO:
      return {
        ...state,
        images: state.images.filter(img => img.id !== action.id)
      };
    case actionTypes.UPLOAD_PHOTO:
      return {
        ...state,
        images:
          state.images.length > 0
            ? [action.data,...state.images]
            : [action.data]
      };
    default:
      return state;
  }
};

export default photoReducer;
