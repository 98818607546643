import React, { Component, Fragment } from "react";
import ButtonNext from "../Button/ButtonNext";
import { TYPES, ERRORS } from "../../config/config";
import { connect } from "react-redux";
import { postUserInfo, getUser } from "../../store/actions/UserInfo";

import { getAllSurveys } from "../../store/actions/UserSurveys";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import Back from "../Button/Back";
import UserInfo from "./UserInfo";
import Moment from "moment";
import { updateProfile } from "../../store/actions/Profile";

class Info extends Component {
  state = {
    disable: true,
    profile_name: null,
    os: null
  };
  nextToSurvey = () => {
    this.props.history.push("/surveys");
  };
  isValid = date => {
    let validDate = Moment().subtract(0, "years");
    let dob = Moment(date);
    if (validDate.isAfter(dob)) {
      return true;
    } else {
      return false;
    }
  };
  isEmpty = userInfo => {
    return Object.keys(userInfo)
      .map(key => userInfo[key])
      .filter(item => !item.value);
  };
  capitalizeString = string => {
    return string
      .toLowerCase()
      .split(" ")
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };
  onChangeHandler = (key, type, e) => {
    let { userInfo } = this.props;
    if (type === TYPES.DATE) {
      userInfo[key].value = e.target.value;
    } else {
      userInfo[key].value = e.target.value;
    }

    switch (type) {
      case TYPES.TEXTBOX:
        userInfo[key].error = "";
        break;
      case TYPES.DATE:
        userInfo[key].error = "";
        break;
      case TYPES.DROPDOWN:
        userInfo[key].error = "";
        break;
      default:
        break;
    }

    this.setState({ userInfo }, this.checkUserInfo);
  };
  checkUserInfo = () => {
    this.setState({ disable: true });
    let { userInfo } = this.props;
    const isEmpty = this.isEmpty(userInfo);
    console.log(isEmpty);
    isEmpty.forEach(item => {
      Object.keys(userInfo).map(key => {
        if (item.type === userInfo[key].type) {
          userInfo[key].error = ERRORS.isEmpty;
        }
      });
    });
    console.log(userInfo);
    if (isEmpty.length === 0) {
      const { userInfo } = this.state;
      let dob = userInfo.date.value;
      const isValid = this.isValid(dob);
      if (isValid) {
        this.setState({ disable: false });
      } else {
        userInfo.date.error = ERRORS.isEmpty;
      }
    }
    this.setState({ userInfo });
  };
  componentDidMount = async () => {
    let { user } = this.props;
    if (!user) {
      user = await this.props.getUser();
    }
    console.log({ user });
    const { surveys } = this.props;
    if (surveys) {
      this.setState({ disable: false });
    } else {
      this.props.getAllSurveys();
    }
    window.WebViewBridge = {
      get_data: this._onMessage
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    const endDate = Moment().utc();
    const startDate = Moment(endDate).subtract(90, "days");
    this.sendDate(startDate, endDate);
  };
  sendDate = (startDate, endDate) => {
    const data = {
      type: WEBVIEW_ACTION.GET_DATA,
      startDate: startDate,
      endDate: endDate
    };
    sendMessage(data);
  };
  _onMessage = data => {
    this.setState({
      os: data.os
    });
    // this.props.getHeartRateWeek(this.state.heartRateWeek);
    // this.props.setProductList(this.state.productList);
    // this.props.setOS(this.state.os);
  };
  onBack = () => {
    sendMessage({ type: WEBVIEW_ACTION.LOG_OUT });
  };
  updateUser = async () => {
    const { userInfo } = this.state;
    let userProfile = {
      username: userInfo.name.value,
      date: userInfo.date.value,
      gender: userInfo.gender.value
    };
    console.log(userProfile);
    const result = await this.props.updateProfile(userProfile);
    if (result) {
      this.props.history.push("/index");
    }
  };
  render() {
    const { userInfo, user } = this.props;
    const goSurvey = user && !user.workoutType ? true : false;
    const { os } = this.state;
    return (
      <div className="colm_costume colm_info_user backInfo">
        <Back onClick={this.onBack} />
        <h3>Hey! We’d like to know a bit more about you!</h3>
        <form className="box_user">
          <UserInfo os={os} data={userInfo} onChange={this.onChangeHandler} />
          {/* {Object.keys(userInfo).map(key =>
            userInfo[key].type === TYPES.TEXTBOX ? (
              <div key={key} className="form-group">
                <label className={userInfo[key].error ? "text-danger" : ""}>
                  {userInfo[key].label}
                </label>

                <input
                  type={userInfo[key].type}
                  placeholder={userInfo[key].placeholder}
                  value={userInfo[key].value}
                  autoCapitalize={os === "android" ? "words" : "off"}
                  onChange={e =>
                    this.onChangeHandler(key, userInfo[key].type, e)
                  }
                />
              </div>
            ) : userInfo[key].type === TYPES.DATE ? (
              <div key={key} className="form-group">
                <label className={userInfo[key].error ? "text-danger" : ""}>
                  {userInfo[key].label}
                </label>
                {
                  // <DateTime
                  //   dateFormat="DD-MM-YYYY"
                  //   timeFormat={false}
                  //   closeOnSelect={true}
                  //   inputProps={{ readOnly: true, placeholder: userInfo[key].placeholder }}
                  //   value={userInfo[key].value}
                  //   onChange={e => this.onChangeHandler(key, userInfo[key].type, e)}
                  // />
                  <Fragment>
                    <input
                      value={
                        userInfo[key].value.length > 0
                          ? Moment(userInfo[key].value).format("YYYY-MM-DD")
                          : Moment("2000-01-01").format("YYYY-MM-DD")
                      }
                      onChange={e =>
                        this.onChangeHandler(key, userInfo[key].type, e)
                      }
                      type="date"
                    />
                  </Fragment>
                }
              </div>
            ) : userInfo[key].type === TYPES.DROPDOWN ? (
              <div key={key} className="form-group select_custom">
                <label className={userInfo[key].error ? "text-danger" : ""}>
                  {userInfo[key].label}
                </label>

                <select
                  required
                  type={userInfo[key].type}
                  placeholder={userInfo[key].placeholder}
                  value={userInfo[key].value}
                  onChange={e =>
                    this.onChangeHandler(key, userInfo[key].type, e)
                  }
                >
                  {userInfo[key].options.map(option =>
                    option.hidden ? (
                      <option
                        key={option.value}
                        value={option.value}
                        disabled
                        hidden
                      >
                        {option.key}
                      </option>
                    ) : (
                      <option key={option.value} value={option.value}>
                        {option.key}
                      </option>
                    )
                  )}
                </select>
              </div>
            ) : null
          )} */}
        </form>
        <div className="button">
          <ButtonNext
            disable={this.state.disable}
            onClick={goSurvey ? this.nextToSurvey : this.updateUser}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.userInfo,
    ...state.userSurveys,
    ...state.workOutList
  };
};
export default connect(mapStateToProps, {
  postUserInfo,
  getAllSurveys,
  getUser,
  updateProfile
})(Info);
