import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getWorkOutId } from "../../store/actions/GetWorkOut";

class ChallengeSliderWorkout extends Component {
  onClick = async (workOutId) => {
    await this.props.getWorkOutId(workOutId);
  };
  render() {
    const settings = {
      className: "slider variable-width",
      variableWidth: true,
      infinite: false,
      slidesToShow: 1,
      speed: 500,
    };
    const { week, workoutList } = this.props;
    return (
      <div>
        <Slider {...settings} className="workout_slider">
          {workoutList.map((item, index) => (
            <div key={index}>
              <Link
                to={{
                  pathname: `/ChallengeExercise/${item.id}`,
                  state: { workoutItem: item, week, workoutList },
                }}
                className="item"
              >
                <h4>
                  <img src={item.thumbnail} alt="" />
                  <span className={item.historyId ? "tick_done" : ""} />
                </h4>
                <p>
                  Workout {index + 1} <br />
                  {item.description}
                  <span>{item.duration} min</span>
                </p>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
export default connect(
  null,
  { getWorkOutId }
)(ChallengeSliderWorkout);
