import * as actionTypes from "./actionTypes";
import { headers, uploadHeaders } from "../../ultils/helpers";
import { API } from "../../config/config";
export const getPhoto = () => (dispatch) => {
  fetch(API.GET_PHOTO_API, {
    headers: headers,
  })
    .then((res) => res.json())
    .then((images) =>
      dispatch({
        type: actionTypes.GET_PHOTO,
        images: images,
      })
    )
    .catch((err) => console.log(err));
};

export const deletePhoto = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.DELETE_PHOTO_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ id }),
    })
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_PHOTO,
          id: id,
        });
        resolve();
      })
      .catch((err) => reject(err));
  });
};

export const uploadPhoto = (file) => (dispatch) => {
  console.log("uploadPhoto");
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("photo", file);
    fetch(API.UPLOAD_PHOTO_API, {
      method: "POST",
      headers: uploadHeaders,
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: actionTypes.UPLOAD_PHOTO,
          data: data,
        });
        resolve(data);
      })
      .then((err) => reject(err));
  });
};

export const uploadAssessment = (
  beforePicture,
  afterPicture,
  message,
  username,
  email,
  beforeImg,
  afterImg
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("beforePicture", beforePicture);
    formData.append("afterPicture", afterPicture);
    formData.append("message", message);
    formData.append("username", username);
    formData.append("email", email);
    fetch(API.UPLOAD_ASSESSMENT_API, {
      method: "POST",
      headers: uploadHeaders,
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .then((err) => reject(err));
  });
};

export const uploadProfilePicture = (file) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("profilePicture", file);
    fetch(API.UPDATE_PROFILE_PICTURE_API, {
      method: "POST",
      headers: uploadHeaders,
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        // dispatch({
        //   type: actionTypes.UPLOAD_PHOTO,
        //   data: data
        // });
        resolve(data);
      })
      .then((err) => reject(err));
  });
};
