import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button } from "react-bootstrap";
import DateTime from "react-datetime";
import AddToCalendar from "react-add-to-calendar";
export class ModalRemoveSchedule extends Component {
  state = {
    event: {
      title: "Sample Event",
      description: "This is the sample event provided as an example only",
      location: "Portland, OR",
      startTime: "2016-09-16T20:15:00-04:00",
      endTime: "2016-09-16T21:45:00-04:00"
    }
  };
  render() {
    return (
      <div>
        <Modal className="modal-custom" {...this.props} size="lg" centered>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              Would you like to cancel this scheduled workout?
            </div>
            <div className="button buttoncalendar">
              <button className="buttonDisable" onClick={this.props.onYes}>
                YES
              </button>

              <button onClick={this.props.onNo}>NO</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ModalRemoveSchedule;
