import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import Moment from 'moment';
import DiaryChartWeekItem from './DiaryChartWeekItem'
class DiaryChartWeek extends Component {
  render() {
    const { heartRateList, maxHeartRate } = this.props

    return (
      <div className={heartRateList.length === 7 ? "weeksChart" : "testClass"}>
        {heartRateList.map(item => <DiaryChartWeekItem heartRate={item.value} date={item.id} maxHeartRate={maxHeartRate} />)}
      </div>
    )
  }
}

export default DiaryChartWeek;