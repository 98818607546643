import * as actionTypes from '../actions/actionTypes';
const initialState = {
  exerciseReps: null,
  index: null,
  progressStatus: null,
  progress: [],
  isBack: null
}
const exerciseReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.GET_EXCERCISE_REPS:
      return {
        ...state,
        exerciseReps: action.data
      }
    case actionTypes.NEXT_EXCERCISE:
      return {
        ...state,
        index: action.data
      }
    case actionTypes.COUNT_PROGRESS:
      return {
        ...state,
        progressStatus: action.data,
        progress: state.progress.length > 0 ? [...state.progress, action.data] : [action.data]
      }
    case actionTypes.SWITCH_STATUS:
      return {
        ...state,
        isBack: action.data
      }
    case actionTypes.ON_BACK:
      return {
        ...state,
        progress: state.progress.length > 0 ? state.progress.slice(0, -1) : state.progress
      }
    case actionTypes.CLEAR_PROGRESS:
      return {
        ...state,
        progress: []
      }
    default:
      return state;
  }
}

export default exerciseReducer;