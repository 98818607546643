import React, { Component } from 'react'
import { Link } from "react-router-dom";
import RecipesSliderItem from './RecipesSliderItem';

export class RecipesSliderSnack extends Component {
    render() {
        const Image = [
            require("../../images/recipes/snacks/01.jpg"),
            require("../../images/recipes/snacks/02.jpg"),
            require("../../images/recipes/snacks/03.jpg"),
            require("../../images/recipes/snacks/04.jpg"),
        ];
        return (
            <div>
                <div className="title-main" style={{ margin: '10px 16px 12px' }}>Snack</div>
                <div className="sliderRecipes">
                    <div className="item">
                        <Link to="/Vegetable_platter_with_Asian_dips">                            
                           <RecipesSliderItem 
                                image = {Image[0]}
                                titlecontent="Vegetable Platter with Asian Dips"                           
                           />
                        </Link>                      
                    </div>
                    <div className="item">
                        <Link to="/Mini_bang_bang_chicken_salad">                            
                           <RecipesSliderItem 
                                image = {Image[1]}
                                titlecontent="Mini Bang Bang Chicken Salad"                           
                           />
                        </Link>                      
                    </div>
                    {/* <div className="item">
                        <Link to="/Snack03">                            
                           <RecipesSliderItem 
                                image = {Image[2]}
                                titlecontent="Grilled Okra"                           
                           />
                        </Link>                      
                    </div> */}
                    <div className="item">
                        <Link to="/Okonomiyaki">                            
                           <RecipesSliderItem 
                                image = {Image[3]}
                                titlecontent="Okonomiyaki"                           
                           />
                        </Link>                      
                    </div>
                    {/* <div className="item">
                        <Link to="/Snack05">                            
                           <RecipesSliderItem 
                                image = {Image[0]}
                                titlecontent="Miso-glazed popcorn"                           
                           />
                        </Link>                      
                    </div> */}
                </div>
            </div>
        )
    }
}

export default RecipesSliderSnack
