import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import SliderProgrammeContent from "./SliderProgrammeContent";
import {
  getCurrentWorkOut,
  getWeekWorkOut,
  getWorkOut,
  setWorkoutDone,
  setOS,
  getAllMessage
} from "../../store/actions/GetWorkOut";
//import DateTime from "react-datetime";
//import moment from 'moment';
class SliderProgramme extends Component {
  state = {
    reg_date: null,
    pay_date: null,
    pay_date1: null
  };
  componentDidMount = async () => {
    const { currentWorkOut } = this.props;
    if (!currentWorkOut) {
      await this.props.getCurrentWorkOut();
    } else {
    }

    const { week, latestWeek } = this.props.currentWorkOut;
    let slickWeek;
    console.log({ week, latestWeek });
    if (latestWeek > parseInt(week)) {
      slickWeek = parseInt(week);
    } else {
      slickWeek = latestWeek;
    }
    //const slickWeek = parseInt(week);
    this.slider.slickGoTo(slickWeek);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.currentWorkOut !== prevProps.currentWorkOut) {
      // const { week } = this.props.currentWorkOut;
      // const slickWeek = parseInt(week)
      // this.slider.slickGoTo(slickWeek);
    }
  };
  onChange = (e, type) => {
    //let { exp_date, reg_date, pay_date } = this.state;
    this.setState({ [type]: e.format("YYYY MM DD") });
  };
  onClick = e => {
    this.setState({ [e.target.dataset.id]: null });
  };
  onUpgrade = () => {
    this.props.history.push("/index");
  };
  render() {
    const {
      workOutList,
      programmeStatus,
      user,
      cardioList,
      currentWorkOut,
      percent,
      allCardioHistory
    } = this.props;
    let listCardioHistory = allCardioHistory
      ? allCardioHistory.reduce((r, a) => {
          r[a.week] = {
            totalCardio: a.session,
            id: a.id,
            duration: a.duration,
            cardioSession: a.cardioSession
          };
          return r;
        }, {})
      : null;
    // if (listCardioHistory) {
    //   console.log({ listCardioHistory })
    // }
    if (!currentWorkOut) {
      return null;
    }
    let { week } = currentWorkOut;
    if (programmeStatus.length > 0) {
      console.log(programmeStatus);
    }

    //let week = 2
    //console.log('week + 1', parseInt(week) + 1)
    let totalDays = user.total_days_purchase;
    //let isAccess = totalDays && !isExpired;
    //let now = moment()
    let weeks = totalDays ? Math.round(totalDays / 7) : 1;
    let weeksDisplay = {};
    for (let i = 0; i <= weeks; i++) {
      if (workOutList[i] && i <= parseInt(week) + 1) {
        weeksDisplay[i] = workOutList[i];
      }
    }

    //console.log(weeksDisplay)
    let newCardioList = cardioList.reduce((r, a) => {
      r[a.week] = { session: a.session, id: a.id, duration: a.duration };
      return r;
    }, {});

    const settings = {
      className: "center",
      centerMode: true,
      infinite: false,
      beforeChange: (current, changedIndex) => {
        this.props.onChange(changedIndex);
      },

      centerPadding: "40px",
      slidesToShow: 1,
      speed: 500
    };
    return (
      <div>
        {/* <div>
          <p>REG_DATE : {this.state.reg_date}</p>
          <p>PAY_DATE: {this.state.pay_date}</p>
          <p>EXP_DATE: {exp_date ? moment(exp_date).format("YYYY MM DD") : null}</p>
        </div>
        <div>
          <p>DATE PICK</p>
          <label>REG_DATE</label>
          
          <DateTime
            dateFormat="YYYY MM DD"
            timeFormat={false}
            closeOnSelect={true}
            inputProps={{ readOnly: true }}
            value={this.state.reg_time}
            onChange={(e, type) => this.onChange(e, type = "reg_date")}
          />
        </div>
        <div>
          <label>PAY_DATE</label>
          <button data-id="pay_date" onClick={this.onClick}>SET NULL</button>
          <DateTime
            dateFormat="YYYY MM DD"
            timeFormat={false}
            closeOnSelect={true}
            inputProps={{ readOnly: true }}
            value={this.state.pay_date}
            onChange={(e, type) => this.onChange(e, type = "pay_date")}
          />
        </div>
        <div>
          <label>ANOTHER PAY_DATE</label>
          <button data-id="pay_date1" onClick={this.onClick}>SET NULL</button>
          <DateTime
            dateFormat="YYYY MM DD"
            timeFormat={false}
            closeOnSelect={true}
            inputProps={{ readOnly: true }}
            value={this.state.pay_date1}
            onChange={(e, type) => this.onChange(e, type = "pay_date1")}
          />
        </div> */}
        {/* <div>
          <label>EXP_DATE</label>
          <button data-id="exp_date" onClick={this.onClick}>SET NULL</button>
          <DateTime
            dateFormat="YYYY MM DD"
            timeFormat={false}
            closeOnSelect={true}
            inputProps={{ readOnly: true }}
            value={this.state.exp_date}
            onChange={(e, type) => this.onChange(e, type = "exp_date")}
          />
        </div> */}
        <div className="programme_slider">
          <Slider ref={slider => (this.slider = slider)} {...settings}>
            {/* {Object.keys(workOutList).map((key, index) => (
              <SliderProgrammeContent
                key={key}
                workoutItem={workOutList[key]}
                week={key}
                onClick={this.props.onClick}
                enable={
                  programmeStatus.length > 0
                    ? programmeStatus[index].enable
                    : false
                }
              />
            ))} */}
            {/* {Object.keys(weeksDisplay).map((key, index) =>
              workOutList[key] && cardioList[key] ? (
                <SliderProgrammeContent
                  key={key}
                  workoutItem={workOutList[key]}
                  cardioItem={cardioList[key]}
                  week={key}
                  onClick={this.props.onClick}
                  onUpgrade={this.props.onUpgrade}
                  enable={
                    programmeStatus.length > 0 && programmeStatus[index]
                      ? programmeStatus[index].enable
                      : false
                  }
                />
              ) : null
            )} */}
            {Object.keys(weeksDisplay).map((key, index) =>
              weeksDisplay[key] ? (
                <SliderProgrammeContent
                  key={key}
                  percent={percent}
                  isEnable={index === 0 || index <= parseInt(week)}
                  workoutItem={weeksDisplay[key]}
                  cardioItem={newCardioList[key] ? newCardioList[key] : null}
                  listCardio={listCardioHistory ? listCardioHistory[key] : null}
                  //cardioSession={listCardioHistory[key] ? listCardioHistory[key] : null}
                  //cardioSession={listCardioHistory ? listCardioHistory[key] : null}
                  week={parseInt(key)}
                  totalDays={totalDays}
                  onClick={this.props.onClick}
                  onUpgrade={this.props.onUpgrade}
                  enable={
                    programmeStatus.length > 0 && programmeStatus[index]
                      ? programmeStatus[index].enable
                      : false
                  }
                  percent={
                    programmeStatus.length > 0 && programmeStatus[index]
                      ? programmeStatus[index].percent
                      : 0
                  }
                />
              ) : null
            )}
          </Slider>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.userInfo,
    ...state.cardioReducer,
    ...state.workOutList
  };
};
export default connect(mapStateToProps, { getCurrentWorkOut })(SliderProgramme);
