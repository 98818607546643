import React, { Component } from 'react'

export class Loading extends Component {
  state = {
    width: 20
  }
  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }
  componentDidMount() {
    const progressInterval = setInterval(() => {
      let width = this.state.width + this.getRandomInt(10);
      if (width > 100) {
        width = 100;
      }
      if (width === 100) {
        clearInterval(progressInterval);
        this.props.history.push('/Upgrade')
      }
      this.setState({ width: width })

    }, 100);
  }
  
  render() {
    return (
      <div>
        <div className="colm_costume colm_costume_loading fadeIn">
          <div></div>

          <div className="loading">
            <p>Creating  programme  for </p>
            <h4>Strength</h4>
            <h4>Weight Loss</h4>
          </div>
          <a className="loading_progress">
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{ width: `${this.state.width}%` }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">{this.state.width}%</div>
            </div>
          </a>
        </div>
      </div>
    )
  }
}

export default Loading
