import * as actionTypes from './actionTypes';
import { API } from '../../config/config';
import { headers } from '../../ultils/helpers';


export const getAllCardio = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_CARDIO_API, {
      headers : headers
    })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type : actionTypes.GET_ALL_CARDIO,
        data : data
      })
      resolve(data)
    })
    .catch(err => reject(err))
  })
}

export const getCardioHistory = (cardioId) => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_CARDIO_HISTORY_API, {
      method : 'POST',
      headers: headers,
      body: JSON.stringify({cardioId})
    })
    .then(res => res.json())
    .then(data => resolve(data))
    .catch(err => reject(err))
  })
}

export const getAllCardioHistory = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_CARDIO_HISTORY_API, {
      method : 'GET',
      headers : headers
    })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: actionTypes.GET_ALL_CARDIO_HISTORY,
        data : data
      })
      resolve(data)
    })
    .catch(err => reject(err))
  })
  
}
export const getCardioLeft = (data) => dispatch => {
  return dispatch({
    type : actionTypes.GET_CARDIO_LEFT,
    data : data
  })
}

export const updateCardioHistory = (cardioId) =>  dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_CARDIO_HISTORY_API, {
      method : "POST",
      headers : headers,
      body: JSON.stringify({cardioId})
    })
    .then(res => res.json())
    .then(data => resolve(data))
    .catch(err => reject(err))
  })
}