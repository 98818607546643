import * as actionTypes from '../actions/actionTypes';

const initialState = {
  liveEvents: null
}


const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_EVENTS:
      return {
        ...state,
        liveEvents: action.data
      }

    default:
      return state;
  }
}
export default eventsReducer