import React, { Component } from 'react'
import { Link } from "react-router-dom";
import RecipesSliderItem from './RecipesSliderItem';

export class RecipesSliderLunch extends Component {
    render() {
        const Image = [
            require("../../images/recipes/lunch/01.jpg"),
            require("../../images/recipes/lunch/02.jpg"),
            require("../../images/recipes/lunch/03.jpg"),
            require("../../images/recipes/lunch/04.jpg"),
            require("../../images/recipes/lunch/05.jpg"),
            require("../../images/recipes/lunch/06.jpg"),
            require("../../images/recipes/lunch/07.jpg"),
            require("../../images/recipes/lunch/08.jpg"),
        ];
        return (
            <div>
                <div className="title-main" style={{ margin: '10px 16px 12px' }}>Lunch - Dinner</div>
                <div className="sliderRecipes">
                    <div className="item">
                        <Link to="/Tomato_egg_drop_noodle_soup">                            
                           <RecipesSliderItem 
                                image = {Image[0]}
                                titlecontent="Tomato Egg Drop Noodle Soup"                           
                           />
                        </Link>                      
                    </div>
                    <div className="item">
                    <Link to="/Chicken_tikka_with_vegetable_basmati_rice">                             
                           <RecipesSliderItem 
                                image = {Image[1]}
                                titlecontent="Chicken Tikka with Vegetable Basmati Rice"                           
                           />
                        </Link>                      
                    </div>
                    <div className="item">
                    <Link to="/Lemongrass_chicken_salad ">                          
                           <RecipesSliderItem 
                                image = {Image[2]}
                                titlecontent="Lemongrass Chicken Salad "                           
                           />
                        </Link>                      
                    </div>
                    <div className="item">
                    <Link to="/Mushroom_barley_risotto ">                         
                           <RecipesSliderItem 
                                image = {Image[3]}
                                titlecontent="Mushroom Barley Risotto"                           
                           />
                        </Link>                      
                    </div>
                    <div className="item">
                    <Link to="/Japanese_tempeh_curry">                              
                           <RecipesSliderItem 
                                image = {Image[4]}
                                titlecontent="Japanese Tempeh Curry"                           
                           />
                        </Link>                      
                    </div>
                    <div className="item">
                    <Link to="/Mis_glazed_salmon_with_soba_noodles">                     
                           <RecipesSliderItem 
                                image = {Image[5]}
                                titlecontent="Miso-Glazed Salmon with Soba Noodles"                           
                           />
                        </Link>                      
                    </div>
                    {/* <div className="item">
                        <Link>                            
                           <RecipesSliderItem 
                                image = {Image[6]}
                                titlecontent="The _anything goes_ Okonomiyaki"                           
                           />
                        </Link>                      
                    </div> */}
                    <div className="item">
                        <Link to="/Thai_style_lettuce_quorn_cups">                            
                           <RecipesSliderItem 
                                image = {Image[7]}
                                titlecontent="Thai-Style Lettuce Quorn Cups (Vegan) "                           
                           />
                        </Link>                      
                    </div>
                </div>
            </div>
        )
    }
}

export default RecipesSliderLunch
