import * as actionTypes from "../actions/actionTypes";
import { API } from "../../config/config";
import { headers, uploadHeaders } from "../../ultils/helpers";

export const getAllSchedule = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_SCHEDULE_API, {
      method: "GET",
      headers: headers
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};
export const removeSchedule = date => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.REMOVE_SCHEDULE_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ date })
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};

export const updateScheduleWorkout = date => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_SCHEDULE_WORKOUT_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ date })
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};

export const updateScheduleCardio = date => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.UPDATE_SCHEDULE_CARDIO_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ date })
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};
