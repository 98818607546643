import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getExerciseReps,
  nextExercise,
  clearProgress,
} from "../../store/actions/Exercise";
export class ModalwthWorkout extends Component {
  render() {
    return (
      <Modal className="modal-custom" {...this.props} size="lg" centered>
        <Link to="#" className="icon" onClick={this.props.onHide}>
          <img src={require("../../images/ic_close_p.svg")} alt="" />
        </Link>

        <Modal.Body className="withWorkout">
          <h4>Quit Workout </h4>
          <p>You’re doing great! Don’t stop now.</p>
          <div className="chooseWith">
            <Link to="#" onClick={this.props.onHide}>
              Continue
            </Link>
            <Link to={this.props.challenge ? "/challenges" : "/programme"}>
              I’ll continue later!
            </Link>
            <Link to="/index" onClick={this.props.onClick}>
              {" "}
              Back to the home page
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalwthWorkout;
