import React, { Component } from "react";
import Survey from "./Survey";
import Back from "../Button/Back";
import ButtonNext from "../Button/ButtonNext";
import { TYPES, API } from "../../config/config";
import LoadingIcon from "../Loading/LoadingIcon";
import { connect } from "react-redux";
import {
  getAllSurveys,
  updateSurvey,
  nextCategory,
  backCategory,
  postSurvey
} from "../../store/actions/UserSurveys";
import Moment from "moment";
class Surveys extends Component {
  state = {
    dateErr: false
  };
  componentDidMount() {
    const { surveys } = this.props;
    if (!surveys) {
      this.props.getAllSurveys();
    }
    console.log({ surveys });
  }
  nextSurvey = () => {
    let { index, categories, surveys } = this.props;
    let filteredSurveys = this.filteredSurveys(categories, index, surveys);

    const isSelected = filteredSurveys.some(
      item => item.value !== null && item.value !== ""
    );
    isSelected
      ? index < categories.length - 1
        ? this.props.nextCategory(index)
        : this.postSurvey()
      : (categories[index].disable = false);
  };

  backSurvey = () => {
    let { index } = this.props;
    index > 0 ? this.props.backCategory(index) : this.backToInfo();
  };

  backToInfo = () => {
    this.props.history.push("/info");
  };
  isValid = date => {
    let validDate = Moment().add(7, "days");
    let timeSurvey = Moment(date);
    if (validDate.isBefore(timeSurvey)) {
      return true;
    } else {
      return false;
    }
  };
  onChange = (id, type, e) => {
    let { surveys, categories, index } = this.props;
    this.setState({ dateErr: false });
    let filteredSurveys = this.filteredSurveys(categories, index, surveys);
    categories[index].disable = false;
    filteredSurveys.forEach(item => {
      if (surveys[item.id].type === TYPES.CHECKBOX) {
        surveys[item.id].value = null;
      } else {
        surveys[item.id].value = "";
      }
    });
    if (typeof e === "object") {
      // surveys[id].value =
      //   type === TYPES.DATE
      //     ? e.format("DD MMM YYYY")
      //     : (type === TYPES.CHECKBOX)
      //       ? 1
      //       : type === TYPES.TEXTBOX
      //         ? e.target.value.trim()
      //         : null;
      if (type === TYPES.DATE) {
        let isValid = this.isValid(e.target.value);
        surveys[id].value = e.target.value;
        if (isValid) {
          //surveys[id].value = e.target.value;
        } else {
          this.setState({ dateErr: true });
        }
      } else if (type === TYPES.CHECKBOX) {
        surveys[id].value = 1;
      } else if (type === TYPES.TEXTBOX) {
        surveys[id].value = e.target.value;
      } else {
        surveys[id].value = null;
      }
    }
    let surveyData = Object.keys(surveys).map(key => {
      return {
        id: surveys[key].id,
        value:
          surveys[key].type === TYPES.TEXTBOX
            ? surveys[key].value
            : surveys[key].value,
        type: surveys[key].type
      };
    });
    console.log(surveyData);
    categories[index].disable = surveys[id].value === "" ? "" : false;
    console.log(surveys[id].value);
    this.props.updateSurvey(surveys, categories);
  };
  postSurvey = () => {
    let { surveys, userInfo } = this.props;

    let surveyData = Object.keys(surveys).map(key => {
      return {
        id: surveys[key].id,
        value:
          surveys[key].type === TYPES.TEXTBOX
            ? surveys[key].value.trim()
            : surveys[key].value,
        type: surveys[key].type
      };
    });
    console.log(surveyData);
    let userData = {
      name: userInfo.name.value,
      year: userInfo.date.value,
      sex: userInfo.gender.value
    };

    const submitData = { user: userData, surveys: surveyData };
    this.props.postSurvey(submitData);
    this.props.history.push("/Loading");
  };

  filteredSurveys = (categories, index, surveys) => {
    let categoryId = categories[index].id;
    let filteredSurveys = Object.keys(surveys)
      .map(key => surveys[key])
      .filter(item => item.categoryId === categoryId);
    return filteredSurveys;
  };
  render() {
    const { categories, surveys, isLoading, index, userInfo } = this.props;
    console.log(userInfo);
    const { dateErr } = this.state;
    if (isLoading) {
      return <LoadingIcon />;
    }
    return (
      <div>
        <div className="container_costume">
          {/* {index > 0 ? <Back onClick={this.backSurvey} /> : null} */}
          <Back index={index} isSurveys={true} onClick={this.backSurvey} />
          <Survey
            categories={categories ? categories[index] : null}
            surveys={surveys ? surveys : null}
            userInfo={userInfo}
            onChange={this.onChange}
            dateErr={dateErr}
            // onClick={this.onClickHandler}
          />
          <div className="button">
            <ButtonNext
              disable={dateErr ? dateErr : categories[index].disable}
              onClick={this.nextSurvey}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.userSurveys,
    ...state.userInfo
  };
};

export default connect(mapStateToProps, {
  getAllSurveys,
  updateSurvey,
  nextCategory,
  backCategory,
  postSurvey
})(Surveys);
