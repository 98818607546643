import * as actionTypes from "./actionTypes";
import { headers } from "../../ultils/helpers";
import { API } from "../../config/config";
export const getAllChallenges = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_CHALLENGES, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: actionTypes.GET_ALL_CHALLENGES,
          data: data,
        });
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};
export const getChallengeWorkoutExercises = (workoutId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_CHALLENGE_WORKOUT_EXERCISES, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ workoutId }),
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: actionTypes.GET_VIDEOS,
          data: data,
        });
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};
export const setSelectedChallenge = (selectedChallenge) => (dispatch) => {
  console.log("SelectedChallenge", selectedChallenge);
  dispatch({
    type: actionTypes.SET_SELECTED_CHALLENGE,
    data: selectedChallenge,
  });
};
