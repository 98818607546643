import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { nextExercise, countProgress } from "../../store/actions/Exercise";
import Resttime from "../Resttime";
import ProgressBar from "../ProgressBar";
class DemoExerciseReps extends Component {
  state = {
    percent: 0
  };
  onClick = async () => {
    const { id, link } = this.props.match.params;
    let { exerciseReps, index, videos } = this.props;
    this.props.history.push(`/DemoVideo/${exerciseReps.workoutId}`);
  };
  isCounting = (index, length) => {
    if (index <= length) {
      return Math.floor((index / length) * 100);
    }
  };
  componentDidMount = async () => {
    let { exerciseReps, index, videos } = this.props;
    index = exerciseReps.currentIndex + 1;
    await this.props.nextExercise(index);
    let { percent } = this.state;
    percent = (1 / videos.length) * 100;
    this.setState({ percent: percent });
  };
  render() {
    const { exerciseReps } = this.props;
    const { progress, videos } = this.props;
    const { percent } = this.state;
    return (
      <div className="exerciseTimer">
        <div className="progress prgess-complete">
          {videos.length > 0
            ? videos.map((v, i) => {
                return (
                  <Fragment key={i}>
                    <ProgressBar i={i} percent={percent} progress={progress} />
                  </Fragment>
                );
                {
                  /* <div
                  i={i}
                  className={progress[i] ? "progress-bar" : "progress-bg"}
                  role="progressbar"
                  style={{ width: `${percent}%` }}
                  aria-valuenow={percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                /> */
                }
              })
            : null}
        </div>
        <div className="imgReps">
          <img src={exerciseReps.gif} alt="" />
        </div>
        <div className="exercise_name">
          <div className="name_workout fadeIn">
            <p> {exerciseReps.name} </p>
            <span> {exerciseReps.reps} </span>
            <span> {exerciseReps.sets} Sets</span>
            <div className="desc">{exerciseReps.description}</div>
          </div>
          <div className="button">
            <button onClick={this.onClick} type="button">
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.exerciseReducer,
    ...state.videosReducer
  };
};
export default connect(mapStateToProps, { nextExercise, countProgress })(
  DemoExerciseReps
);
