import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { nextExercise, countProgress } from "../../store/actions/Exercise";
import Resttime from "../Resttime";
import ProgressBar from "../ProgressBar";
import ProgressCountdown from "react-bootstrap/ProgressBar";
import sound from "../../assets/sound-timer.mp4";
import countDownSound from "../../assets/countdown-sound-timer.m4a";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
//import "react-circular-progressbar/dist/styles.css";

class ExerciseRest extends Component {
  state = {
    percent: 0,
    repsArr: [],
    repsIndex: 0,
    point: null,
    next: false,
    onProgress: false,
    isDone: false,
    count: null,
    secondCount: 0,
    onWorkout: false,
    playAudio: null,
  };
  audio;
  audioCountDown;
  interval;
  onDone = async () => {
    let { exerciseReps, index, videos } = this.props;
    if (this.interval) {
      console.log("clear", { interval: this.interval });
      this.stopSound();
      clearInterval(this.interval);
    }
    this.props.history.push(`/video/${exerciseReps.workoutId}`);
  };
  isCounting = (index, length) => {
    if (index <= length) {
      return Math.floor((index / length) * 100);
    }
  };
  componentDidMount = async () => {
    let { exerciseReps, index, videos, os } = this.props;
    const playAudio = localStorage.getItem("playAudio");
    this.setState({ playAudio: playAudio === "true" });
    //os = "android";
    if (os == "android") {
      this.audio = new Audio(sound);
      this.audioCountDown = new Audio(countDownSound);
    } else {
      if (playAudio === "true") {
        this.audio = new Audio(sound);
        this.audioCountDown = new Audio(countDownSound);
      }
    }
    index = exerciseReps.currentIndex + 1;
    await this.props.nextExercise(index);
    let { percent } = this.state;
    percent = (1 / videos.length) * 100;
    this.setState({ percent: percent });
    const str = exerciseReps.reps;
    const strSplit = str.split(" ");
    let reps = exerciseReps.sets,
      repsArr = [],
      valueArr = [];
    strSplit.forEach((item) => {
      let value = parseInt(item);
      if (value) {
        valueArr.push(value);
      }
    });
    for (let i = 0; i < reps; i++) {
      valueArr.forEach((item) => {
        repsArr.push(item);
      });
    }
    console.log(repsArr);
    this.setState({ repsArr });
  };
  componentDidUpdate = (prevProps, prevState) => {
    // if (prevState.onWorkout !== this.state.onWorkout && this.state.onWorkout) {
    //   this.audio.play();
    // }
  };
  playSound = () => {
    let { os } = this.props;
    let { playAudio } = this.state;
    //os = "android";
    if (os === "android") {
      console.log("play sound");
      this.audio.play();
    } else {
      if (playAudio) {
        this.audio.play();
      }
    }
  };
  stopSound = () => {
    let { os } = this.props;
    let { playAudio } = this.state;
    //os = "android";
    if (os === "android") {
      this.audio.pause();
      this.audio.currentTime = 0;
    } else {
      if (playAudio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    }
  };
  onCountDown = () => {
    let { os } = this.props;
    let { playAudio } = this.state;
    //os = "android";
    if (true) {
      if (os === "android") {
        console.log("Playing...");
        this.audioCountDown.play();
      } else {
        if (playAudio && this.audioCountDown) {
          this.audioCountDown.play();
        }
      }
      this.setState({ onWorkout: true });
      setTimeout(() => {
        this.onClick();
        this.setState({ onWorkout: false });
      }, 4000);
    }
  };
  onClick = () => {
    let { repsArr, repsIndex, next, point, onProgress } = this.state;
    console.log(onProgress);
    let length = repsArr.length;
    let index = repsIndex;
    let value = repsArr[index];
    let count = repsArr[index];
    this.setState({ count: count });
    let gaps = 100 / value;
    value = Math.ceil(value * gaps);
    let test = false;

    this.setState({ onProgress: true });
    if (index < length) {
      this.interval = setInterval(() => {
        value -= gaps;
        count--;
        console.log(value);
        if (count === 6) {
          setTimeout(() => {
            this.playSound();
          }, 500);
        }
        this.setState({ point: value, count });
        // value < 8
        if (value < 0) {
          console.log("clear interval", { interval: this.interval });
          this.setState({ repsIndex: repsIndex + 1 });
          this.stopSound();
          clearInterval(this.interval);
          this.onClick();
        }
      }, 1000);
    } else {
      this.setState({ isDone: true });
    }
  };
  render() {
    const { exerciseReps } = this.props;
    const { progress, videos } = this.props;
    const {
      percent,
      repsArr,
      repsIndex,
      point,
      onProgress,
      isDone,
      count,
      onWorkout,
    } = this.state;
    let index = repsIndex;
    let startValue = repsArr[repsIndex];
    let gaps = 100 / startValue;
    startValue = Math.ceil(startValue * gaps);
    return (
      <div className="exerciseTimer">
        <div className="progress prgess-complete">
          {videos.length > 0
            ? videos.map((v, i) => {
                return (
                  <Fragment key={i}>
                    <ProgressBar i={i} percent={percent} progress={progress} />
                  </Fragment>
                );
              })
            : null}
        </div>
        {/* <div className="ProgressBarRest">
          {!isDone ? <ProgressCountdown
            variant={index > 0 && index % 2 !== 0 ? "danger" : "success"}
            now={point !== null && point >= 0 ? point : startValue}
          /> : null}
        </div> */}

        <div className="imgReps">
          <img
            src={
              index > 0 && index % 2 !== 0
                ? require("../../images/RestingPosition.jpg")
                : onProgress && !isDone
                ? exerciseReps.gif
                : require("../../images/RestingPosition.jpg")
            }
            alt=""
          />

          {onWorkout ? (
            <div className="zoomInAnimated">
              <span>3</span>
              <span>2</span>
              <span>1</span>
              <span>GO!</span>
            </div>
          ) : (
            <div className="coutDown">
              <div label="Background">
                <CircularProgressbar
                  value={point && point >= 0 ? point : isDone ? 0 : 100}
                  strokeWidth={50}
                  text={count || count == 0 ? count : repsArr[index]}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                  })}
                  counterClockwise={true}
                />
              </div>
            </div>
          )}
        </div>
        <div className="exercise_name">
          <div className="name_workout fadeIn">
            <p> {exerciseReps.name} </p>
            <span> {exerciseReps.reps} </span>
            <span>
              {" "}
              {exerciseReps.sets} {exerciseReps.sets > 1 ? "Sets" : "Set"}
            </span>
            {index > 0 && index % 2 !== 0 ? (
              <div class="center-on-page">
                <div class="heart pulse">REST</div>
              </div>
            ) : (
              <div className="desc">{exerciseReps.description}</div>
            )}
          </div>

          <div className="button ">
            <button
              className="button-timer"
              onClick={onProgress ? this.onDone : this.onCountDown}
              type="button"
              disabled={onWorkout}
            >
              {isDone ? "DONE" : onProgress ? "DONE" : "START TIMER"}
            </button>
          </div>
        </div>
        {/* {
          index > 0 && index % 2 !== 0 ? <div class="center-on-page">

            <div class="heart pulse">REST</div>

          </div> : null
        } */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.exerciseReducer,
    ...state.videosReducer,
    ...state.workOutList,
    ...state.profileReducer,
  };
};
export default connect(
  mapStateToProps,
  { nextExercise, countProgress }
)(ExerciseRest);
