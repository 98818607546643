import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button } from "react-bootstrap";
import DateTime from "react-datetime";

class ModalAddSchedule extends Component {
  render() {
    return (
      <div>
        <Modal className="modal-custom" {...this.props} size="lg" centered>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              Would you like to add this to your calendar?
            </div>
            <div className="button buttoncalendar">
              <button onClick={this.props.onYes}>YES</button>
              <button className="buttonDisable" onClick={this.props.onNo}>
                NO
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ModalAddSchedule;
