import React, { Component } from 'react'

export class Close extends Component {
  render() {
    return (
     
         <a className="icon icon_close"><img src={require('../../images/ic_close_bold.svg')} alt="close"/></a>
    
    )
  }
}

export default Close
