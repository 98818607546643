import React, { Component, Fragment } from "react";
import { sliderType } from "../../config/config";
import YoutubeWorkout from "../Workout/YoutubeWorkout";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
class SliderItem extends Component {
  state = {
    hideBanner: false
  };
  playVideo = () => {
    this.videoObj.playVideo();
  };
  onReady = e => {
    //alert('ready');
    this.videoObj = e.target;
  };
  onClickVideo = () => {
    this.setState({ hideBanner: true });
    this.videoObj.playVideo();
    // console.log(this.videoObj)
    // this.videoObj.playVideo();
  };
  pauseVideo = async () => {
    this.videoObj.pauseVideo();
    //this.setState({ hideBanner: false });
  };
  onEndVideo = () =>{
    console.log('on End Video')
    this.setState({ hideBanner: false });
  }
  sendAddress = e => {
    let link = e.currentTarget.dataset.id;
    sendMessage({ type: WEBVIEW_ACTION.SEND_URL, url: { link: link } })
  };
  render() {
    const { data } = this.props;
    const { isExpired, sliders } = this.props;
    const { hideBanner } = this.state;
    const settings3 = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: false
    };
    const opts = {
      // height: '390',
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        playsinline: 1,
        autoplay: 0,
        controls: 1,
        rel: 0,
        modestbranding: 0,
        showinfo: 0,
        autohide: 1,
        enablejsapi: 1,
        html5: 1,
        ecver : 2
      }
    };
    return (
      <Fragment>
        {data.type === sliderType.IMAGE ? (
          <div>
            <div
              onClick={this.sendAddress}
              data-id={data.link}
              className="main-slider"
              style={{ backgroundImage: `url(${data.image})` }}
            ></div>
          </div>
        ) : (
            <div>
              <div className="main-slider">
                <YouTube
                  opts={opts}
                  videoId={data.link}
                  onReady={this.onReady}
                  onPause={this.pauseVideo}
                  onEnd={this.onEndVideo}
                  onPlay={this.onClickVideo}
                />
                <div
                  className="img_slider"
                  onClick={this.onClickVideo}
                  style={
                    !hideBanner
                      ? { backgroundImage: `url(${data.image})` }
                      : { display: "none" }
                  }
                >
                  <img src={require("../../images/playf2.svg")} alt="back" />
                </div>
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}

export default SliderItem;
