import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { PRIVACY, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import { connect } from "react-redux";
import {
  getAllWorkoutType,
  updateWorkoutType
} from "../../store/actions/UserSurveys";

class ModalChangeWorkoutFocus extends Component {
  state = {
    workoutTypeList: [],
    data: [],
    id: null,
    isSubmit: false,
    oldId: null
  };
  componentDidMount = async () => {
    await this.props.getAllWorkoutType();
  };
  componentDidUpdate = (prevState, prevProps) => {
    const { workoutTypeList } = this.props;
    let { id, oldId } = this.state;
    if (workoutTypeList !== prevProps.workoutTypeList) {
      //const { user } = this.props;
      //console.log('focus', user)
      workoutTypeList.map(item => {
        if (item.radio) {
          id = item.id;
          oldId = item.id;
        }
      });
      this.setState({ workoutTypeList, oldId, id });
    }
  };
  componentWillUnmount = () => {
    console.log("unmounted");
  };
  onBack = async () => {
    let { workoutTypeList, oldId, id } = this.state;
    workoutTypeList.forEach(item => {
      if (item.id === oldId) {
        item.radio = 1;
        id = item.id;
      } else {
        item.radio = null;
      }
    });
    this.setState({ workoutTypeList, id });
    // console.log('onBack')
    this.props.onHide();
  };
  onChange = e => {
    let { workoutTypeList } = this.state;
    workoutTypeList.forEach(item => {
      if (item.id != e.target.value) {
        item.radio = null;
      } else {
        item.radio = 1;
      }
    });

    this.setState({ workoutTypeList, id: e.target.value });
  };
  onClick = async () => {
    this.setState({ isSubmit: true });
    const { id } = this.state;
    const { user } = this.props;
    const { email, password } = user;
    console.log(id);
    const result = await this.props.updateWorkoutType(id);
    if (result) {
      //console.log(result)
      this.setState({ isSubmit: false });
      const data = {
        type: WEBVIEW_ACTION.AUTO_LOGIN,
        email: email,
        password: password
      };
      sendMessage(data);
    }
  };
  render() {
    const { workoutTypeList, isSubmit } = this.state;
    //console.log('type list', workoutTypeList)
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg">
          <div className="page pageFeedBack">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.onBack}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>Change Workout Focus</h4>
              <Link to="#" className="icon" />
            </div>

            <div className="privacy changeWorkout">
              <div className="box-info-check">
                <ul>
                  {workoutTypeList.length > 0
                    ? workoutTypeList.map((item, key) => (
                        <Fragment key={key}>
                          <li>
                            <div class="radio">
                              <input
                                id={item.id}
                                value={item.id}
                                checked={item.radio !== null}
                                onChange={e => this.onChange(e)}
                                name="radio"
                                type="radio"
                              />
                              <label for={item.id} class="radio-label">
                                <p>
                                  {item.name}
                                  <span>{item.description} </span>
                                </p>
                                <i></i>
                              </label>
                            </div>
                          </li>
                        </Fragment>
                      ))
                    : null}

                  {/* <li>
                    <div class="radio">
                      <input id="2" name="radio" type="radio" />
                      <label for="2" class="radio-label">
                        <p>Lose Weight<span> Reduce body fat  </span></p>
                        <i></i>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="radio">
                      <input id="3" name="radio" type="radio" />
                      <label for="3" class="radio-label">
                        <p>Lose Weight<span> Reduce body fat  </span></p>
                        <i></i>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="radio">
                      <input id="4" name="radio" type="radio" />
                      <label for="4" class="radio-label">
                        <p>Lose Weight<span> Reduce body fat  </span></p>
                        <i></i>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="radio">
                      <input id="4" name="radio" type="radio" />
                      <label for="4" class="radio-label">
                        <p>Lose Weight<span> Reduce body fat  </span></p>
                        <i></i>
                      </label>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div class="button">
                <div class="button">
                  <button
                    /* disabled={isSubmit} className={isSubmit ? "disable" : null} */ onClick={
                      this.onClick
                    }
                    to="#"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.userSurveys,
    ...state.userInfo
  };
};
export default connect(mapStateToProps, {
  getAllWorkoutType,
  updateWorkoutType
})(ModalChangeWorkoutFocus);
