import React, { Fragment } from "react";
import { TYPES } from "../../config/config";
import DateTime from "react-datetime";
import moment from "moment";
const UserInfo = props => {
  const { data, os } = props;
  //console.log(props);
  return Object.keys(data).map(key =>
    data[key].type === TYPES.TEXTBOX ? (
      <div key={key} className="form-group">
        <label className={data[key].error ? "text-danger" : ""}>
          {data[key].label}
        </label>

        <input
          type={data[key].type}
          placeholder={data[key].placeholder}
          value={data[key].value}
          autoCapitalize={os === "android" ? "words" : "off"}
          onChange={e => props.onChange(key, data[key].type, e)}
        />
      </div>
    ) : data[key].type === TYPES.DATE ? (
      <div key={key} className="form-group">
        <label className={data[key].error ? "text-danger" : ""}>
          {data[key].label}
        </label>
        {
          // <DateTime
          //   dateFormat="DD-MM-YYYY"
          //   timeFormat={false}
          //   closeOnSelect={true}
          //   inputProps={{ readOnly: true, placeholder: data[key].placeholder }}
          //   value={data[key].value}
          //   onChange={e => props.onChange(key, data[key].type, e)}
          // />
          <Fragment>
            <input
              value={
                data[key].value.length > 0
                  ? moment(data[key].value).format("YYYY-MM-DD")
                  : moment("2000-01-01").format("YYYY-MM-DD")
              }
              onChange={e => props.onChange(key, data[key].type, e)}
              type="date"
            />
          </Fragment>
        }
      </div>
    ) : data[key].type === TYPES.DROPDOWN ? (
      <div key={key} className="form-group select_custom">
        <label className={data[key].error ? "text-danger" : ""}>
          {data[key].label}
        </label>

        <select
          required
          type={data[key].type}
          placeholder={data[key].placeholder}
          value={data[key].value}
          onChange={e => props.onChange(key, data[key].type, e)}
        >
          {data[key].options.map(option =>
            option.hidden ? (
              <option key={option.value} value={option.value} disabled hidden>
                {option.key}
              </option>
            ) : (
              <option key={option.value} value={option.value}>
                {option.key}
              </option>
            )
          )}
        </select>
      </div>
    ) : null
  );
};

export default UserInfo;
