import React, { Component } from "react";

class Back extends Component {
  render() {
    const { index, isSurveys } = this.props;
    return (
      <div>
        {isSurveys ? (
          <div className="back">
            <a onClick={this.props.onClick} className="icon">
              {index > 0 ? "Back" : ""}
            </a>
          </div>
        ) : (
          <div className="back">
            <a onClick={this.props.onClick} className="icon">
              Back
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default Back;
