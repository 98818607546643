import * as actionTypes from '../actions/actionTypes';
import { API } from "../../config/config";

const initialState = {
  productList: null
}
const upgradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.data
      }
    default:
      return state;
  }
}

export default upgradeReducer;