import React, { Component } from "react";
import Back from "../Button/Back";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ListWorkoutContent from "./ListWorkoutContent";
import { getVideo, setDemoWorkoutURL } from "../../store/actions/GetVideo";
import { getStartTime } from "../../store/actions/Diary";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import Moment from "moment";
class DemoWorkout extends Component {
  state = {
    workOutCategory: null
  };
  backToWorkout = () => {
    const { workOutList } = this.props;
    workOutList
      ? this.props.history.push("/workout")
      : this.props.history.push("/index");
  };
  goToVideos = () => {
    this.props.history.push("/DemoVideo");
  };

  getStartTime = async () => {
    let startTime = Moment().format("YYYY-MM-DD HH:mm:ss");
    // `/ListWorkout/${item.id}/${item.description}/${
    //   item.name
    // }/${item.duration}/${index + 1}`
    const {
      name,
      description,
      index,
      duration,
      week
    } = this.props.match.params;

    let workoutName = name;
    //const { week } = this.props;
    const { os } = this.props;
    //let startTime = Moment(now).subtract(15, "m")
    this.props.getStartTime(startTime, workoutName, week);
    const { id } = this.props.match.params;
    const link = window.location.pathname;
    console.log(link);
    // console.log(
    //   `DemoWorkout/${id}/${week}/${description}/${name}/${duration}/${index}`
    // );
    //this.alertMesssage();
    await this.props.setDemoWorkoutURL(link);
    this.props.history.push(`/DemoVideo/${id}`);
  };

  alertMesssage = () => {
    setTimeout(() => {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: WEBVIEW_ACTION.ALERT_MESSAGE
        })
      );
    }, 1);
  };
  _onMessage = data => {
    const { id } = this.props.match.params;
    if (data.type) {
      this.props.history.push(`/Video/${id}`);
    }
  };
  // componentDidUpdate  = () => {
  //   window.WebViewBridge = {
  //     alert_message: this._onMessage
  //   };
  //   const event = new Event("WebViewBridge");
  //   window.dispatchEvent(event);
  // }
  componentDidMount = async () => {
    /* let date = Moment(Date.now()).toISOString()
    alert(date) */
    window.WebViewBridge = {
      alert_message: this._onMessage
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    const { id } = this.props.match.params;
    console.log(id);
    const workOutCategory = await this.props.getVideo(id);
    console.log(id);
    this.setState({ workOutCategory });
  };
  render() {
    //const { week } = this.props;

    const {
      id,
      description,
      name,
      duration,
      index,
      week
    } = this.props.match.params;
    console.log({ id, index, week, duration, name, description });
    const { workOutCategory } = this.state;
    console.log(workOutCategory);
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <div>
            <div className="back" style={{ width: 55 }}></div>
          </div>
          <h4>{week >= 1 ? `Week ${week}` : `Trial Week`}</h4>
          <Link to="" className="icon" />
        </div>
        <div className="page-content">
          <div className="list-workout">
            <h3 className="title-main">
              Workout {index} - {description}{" "}
            </h3>
            <p className="duration">
              {" "}
              <span>Duration</span>
              {duration} Min
            </p>
            <ul>
              {workOutCategory
                ? workOutCategory.map(item => (
                    <ListWorkoutContent
                      key={item.id}
                      images="w1.jpg"
                      workoutName={item.name}
                      reps={item.reps}
                      sets={item.sets}
                      img={item.gif}
                    />
                  ))
                : null}
            </ul>
          </div>
        </div>
        <div className="letgo-button">
          <div className="button">
            <Link to="#" onClick={this.getStartTime} className="button-w220">
              LET'S GO!
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.workOutList,
    ...state.videos
  };
};
export default connect(mapStateToProps, {
  getVideo,
  getStartTime,
  setDemoWorkoutURL
})(DemoWorkout);
