import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Mis_glazed_salmon_with_soba_noodles extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
         Meals
        </div>

        <div className="recipesMain">
          <div className="image">
            <img src={require("../../../images/recipes/lunch/06.jpg")} alt="" />
          </div>
          <p>
          Miso-Glazed Salmon with Soba Noodles
            <span> Serves: 2 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            2 bunches of soba noodles <br />
            1 tbsp of soba sauce
            <br />
            2 salmon fillets
            <br />
            100g Purple cabbage
            <br />
            6 Cherry tomatoes, halved.
            <br />
            <div className="ttchild">For the marinade</div>
            1 tbsp red miso
            <br />
            0.5 tbsp honey
            <br />
            0.5 tbsp sesame oil
            <br />
            0.5 tbsp grated ginger
            <br />
            <div className="ttchild">Salad Dressing</div>
            1 tbsp red miso
            <br />
            0.5 tbsp rice vinegar
            <br />
            0.5 tbsp sesame oil
            <br />
            sesame seeds
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Combine all the ingredients of the marinade with the salmon and
                refrigerate for 20 minutes
              </li>
              <li>
                <span>2</span>
                Cook the soba noodles and reserve 1 cup of the liquid. Run under
                cold water and chill the noodles in the fridge for another 20
                minutes or until cold.{" "}
              </li>
              <li>
                <span>3</span>
                Thinly slice the purple cabbage and halve the cherry tomatoes,
                then toss with the salad dressing.
              </li>
              <li>
                <span>4</span>
                If using a toaster oven, preheat and grill the salmon, skin side
                down for 7-8 minutes. If you use an airfryer, check the
                suggested timings as it differs between models. If using a
                non-stick pan, cook the salmon skinside down with some olive
                oil, keeping the lid on for 5 minutes on medium heat, then on
                high heat for 1-2 minutes to create a nice crispy skin.
              </li>
              <li>
                <span>5</span>
                Combine the soba sauce with the reserved soba water and taste
                it, adjusting till the level of salt is just right for you! This
                is dependent on the brand you are using and your personal taste.{" "}
              </li>
            </ul>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Mis_glazed_salmon_with_soba_noodles;
