import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Back from "./Button/Back";
import { connect } from "react-redux";
import { showProfile } from "../store/actions/Profile";
import Textarea from "react-textarea-autosize";

export class Privacy extends Component {
  onClick = () => {
    let showProfile = true;
    this.props.showProfile(showProfile);
    this.props.history.push("/index");
  };
  render() {
    return (
      <div className="page pageFeedBack fadeIn">
        <div className="page-header">
          <Back onClick={this.onClick} />
          <h4>Privacy Policy</h4>
          <Link to="" className="icon" />
        </div>

        <div className="privacy">
          <p>
            We use technologies like cookies (small files stored on your
            browser), web beacons, or unique device identifiers to identify your
            computer or device so we can deliver a better experience. Our
            systems also log information like your browser, operating system and
            IP address.{" "}
          </p>

          <p>
            We also may collect personally identifiable information that you
            provide to us, such as your name, address, phone number or email
            address. With your permission, we may also access other personal
            information on your device, such as your phone book, calendar or
            messages, in order to provide services to you. If authorized by you,
            we may also access profile and other information from services like
            Facebook.
          </p>

          <p>
            Our systems may associate this personal information with your
            activities in the course of providing service to you (such as pages
            you view or things you click on or search for).
          </p>

          <p>We do not use or collect your precise geographic location.</p>

          <p>
            You can sign into your account to see any personally identifiable
            information we have stored, such as your name, email, address or
            phone number. You can also contact us by email to request to see
            this information.
          </p>

          <p>We may keep data indefinitely.</p>

          <p>
            We do not share personally identifiable information (such as name,
            address, email or phone) with other companies.
          </p>

          <p>
            We do not allow advertising companies to collect data through our
            service for ad targeting.
          </p>

          <p>
            If you have any questions or concerns about our privacy policies,
            please contact us{" "}
            <Link to="mailto:nosweat@munkysuperstar.com">
              nosweat@munkysuperstar.com
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { showProfile }
)(Privacy);
