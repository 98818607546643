import * as actionTypes from '../actions/actionTypes';

const initialState = {
  assessment : null,
  repliedAssessment : null
}

const assessmentReducer = (state = initialState , action ) => {
  switch(action.type) {
    case actionTypes.GET_ASSESSMENT_BY_ID:
      return {
        ...state,
        assessment : action.data
      }
      case actionTypes.GET_REPLIED_ASSESSMENT:
        return {
          ...state,
          repliedAssessment  : action.data
        }
    default:
      return state
  }
}

export default assessmentReducer;