import React, { Component, Fragment } from "react";
import MenuItem from "./MenuItem";
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
class Menus extends Component {
  navigateTo = (e, url) => {
    e.preventDefault();
    window.location = url;
  };

  sendAddress = (e) => {
    let url, id = e.currentTarget.dataset.id;
    switch (id) {
      case MENU.MERCH:
        url = MENU.MERCH_URL;
        break;
      case MENU.COMMUNITY:
        url = MENU.COMMUNITY_URL;
        break;
      default:
        break;
    }
    const data = {type: WEBVIEW_ACTION.SEND_URL, url: url}
    sendMessage(data)
  };
  render() {
    const { isActive } = this.props;
    return (
      <Fragment>
        <MenuItem
          link="/Index"
          isActive={isActive === MENU.INDEX ? "active" : ""}
          name="Home"
          iconName="home"
        />
        <MenuItem
          link="/Programme"
          isActive={isActive === MENU.PROGRAMME ? "active" : ""}
          name="Programme"
          iconName="programme"
        />
        <MenuItem
          link="/Progress"
          name="Progress"
          isActive={isActive === MENU.PROGRESS ? "active" : ""}
          iconName="progress"
        />
        <MenuItem
          link="#"
          name="Merch"
          isActive={isActive === MENU.MERCH ? "active" : ""}
          iconName="merch"
          id={MENU.MERCH}
          onClick={this.sendAddress}
        />
        <MenuItem
          link="#"
          name="Community"
          isActive={isActive === MENU.COMMUNITY ? "active" : ""}
          iconName="community"
          id={MENU.COMMUNITY}
          onClick={this.sendAddress}
        />
      </Fragment>
    );
  }
}
export default Menus;
