import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BOOL_VALUE } from "../../config/config";
import RecipesSliderBreakfast from "./RecipesSliderBreakfast";
import RecipesSliderLunch from "./RecipesSliderLunch";
import RecipesSliderSnack from "./RecipesSliderSnack";
import { connect } from "react-redux";
import {
  BREAK_FAST_RECIPES,
  LUNCH_RECIPES,
  RECIPES_CATEGORIES,
  SNACK_RECIPES,
  MEAL_PLANS_RECIPES,
} from "../../config/recipesConfig";
import RecipesSlider from "./RecipesSlider";
const BREAK_FAST_IMGS = [
  require("../../images/recipes/breakfast/01.jpg"),
  require("../../images/recipes/breakfast/02.jpg"),
  require("../../images/recipes/breakfast/03.jpg"),
  require("../../images/recipes/breakfast/04.jpg"),
  require("../../images/recipes/breakfast/05.jpg"),
];
const LUNCH_IMGS = [
  require("../../images/recipes/lunch/01.jpg"),
  require("../../images/recipes/lunch/02.jpg"),
  require("../../images/recipes/lunch/03.jpg"),
  require("../../images/recipes/lunch/04.jpg"),
  require("../../images/recipes/lunch/05.jpg"),
  require("../../images/recipes/lunch/06.jpg"),
  require("../../images/recipes/lunch/07.jpg"),
  require("../../images/recipes/lunch/08.jpg"),
];
const SNACK_IMGS = [
  require("../../images/recipes/snacks/01.jpg"),
  require("../../images/recipes/snacks/02.jpg"),
  require("../../images/recipes/snacks/03.jpg"),
  require("../../images/recipes/snacks/04.jpg"),
];
const MEAL_PLANS = [
  require("../../images/recipes/meal_plans/28_Day_Weight_Loss.jpg"),
  require("../../images/recipes/meal_plans/28_Day_Weight_Loss_Vegetarian.jpg"),
  require("../../images/recipes/meal_plans/5_Day_Meal_Prep.jpg"),
];
export class Recipes extends Component {
  goToUpgrade = () => {
    this.props.history.push("/Index");
  };
  render() {
    const {
      isUserExpired,
      isSubscriptionUser,
      os,
      appVersion,
    } = this.props.match.params;
    const isUserExpiredValue = isUserExpired === BOOL_VALUE.TRUE;
    const isSubscriptionUserValue = isSubscriptionUser === BOOL_VALUE.TRUE;
    const isOnAppValue = os !== BOOL_VALUE.NULL;
    // console.log({ isUserExpiredValue, isSubscriptionUserValue });
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        {/*  <div className="recipesMain" style={{ paddingBottom: 0 }}>
          <Link to="/Vegetable_platter_with_Asian_dips">
                        <div className="image">
                            <img src={require("../../images/recipes/snacks/01.jpg")} alt="" />
                        </div>
                        <p>
                        Vegetable platter with Asian dips
                            <span> Serves: 2-3 </span>
                        </p>
                    </Link> 
        </div>*/}

        <RecipesSlider
          isUserExpired={isUserExpiredValue}
          isSubscriptionUser={isSubscriptionUserValue}
          isOnApp={isOnAppValue}
          recipesData={BREAK_FAST_RECIPES}
          imgData={BREAK_FAST_IMGS}
          category={RECIPES_CATEGORIES.BREAKFAST}
        />
        <RecipesSlider
          isUserExpired={isUserExpiredValue}
          isSubscriptionUser={isSubscriptionUserValue}
          isOnApp={isOnAppValue}
          recipesData={LUNCH_RECIPES}
          imgData={LUNCH_IMGS}
          category={RECIPES_CATEGORIES.LUNCH}
        />
        <RecipesSlider
          isUserExpired={isUserExpiredValue}
          isSubscriptionUser={isSubscriptionUserValue}
          isOnApp={isOnAppValue}
          recipesData={SNACK_RECIPES}
          imgData={SNACK_IMGS}
          category={RECIPES_CATEGORIES.SNACK}
        />
        {os && appVersion >= 33 && (
          <RecipesSlider
            isUserExpired={isUserExpiredValue}
            isSubscriptionUser={isSubscriptionUserValue}
            isOnApp={isOnAppValue}
            recipesData={MEAL_PLANS_RECIPES}
            imgData={MEAL_PLANS}
            category={RECIPES_CATEGORIES.MEAL_PLANS}
          />
        )}

        {/* <RecipesSliderBreakfast

        /> */}
        {/* <RecipesSliderLunch />
                <RecipesSliderSnack /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.workOutList,
  };
};
export default connect(
  mapStateToProps,
  {}
)(Recipes);
