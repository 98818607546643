import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Vegetable_platter_with_Asian_dips extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Snacks
        </div>

        <div className="recipesMain">
          <div className="image">
            <img
              src={require("../../../images/recipes/snacks/01.jpg")}
              alt=""
            />
          </div>
          <p>
          Vegetable Platter with Asian Dips
            <span> Serves: 2-3 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            1 medium carrot (or 2 if smaller) <br />
            1 Japanese cucumber (or 2 if smaller) <br />
            2 stalks of celery, trimmed <br />
            1 red capsicum <br />
            1 yellow capsicum <br />
            1 green capsicum <br />
            7-8 Okra (ladyfingers), steamed <br />
            10 baby corn, steamed <br />
            10 cherry tomatoes <br />
            3 hard boiled eggs <br />
            <h3 className="ttchild">For the spicy yogurt dip</h3>
            5 tbsp plain yogurt
            <br />
            1 tbsp gochujang
            <br />
            1 tsp sesame oil
            <br />
            0.5 tsp grated garlic (or garlic paste)
            <br />
            1 tsp grated ginger (or ginger paste)
            <br />
            <h3 className="ttchild">For the peanut sauce dip</h3>
            2 tbsp crunch peanut butter
            <br />
            1 tsp miced ginger (or ginger paste)
            <br />
            2 tsp coriander powder
            <br />
            2 tbsp sweet chilli sauce (or siracha)
            <br />
            1 tbsp honey (use 1.5tbsp honey if using siracha)
            <br />2 tbsp water
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>Steam the okra and the baby corn together for 9
                minutes. While waiting, cut the cherry tomatoes into half, and
                the eggs into quarters.
              </li>
              <li>
                <span>2</span>
                For the remaining vegetables, cut them into evenly shaped long
                thin strips. However, make sure that they are not too thin as
                they should be easily picked up and have a crunchy texture.
                about 5mm thick is a good guide to follow.
              </li>
              <li>
                <span>3</span>
                Mix all the items listed for the two sauces separately, and
                place in a small cup or bowl of your choice.
              </li>
              <li>
                <span>4</span>
                Plate all cut the vegetables and the egg around the condiments
                and you're good to go!
              </li>
            </ul>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Vegetable_platter_with_Asian_dips;
