import React, { Component } from "react";
import Menu from "./Menu/Menu";
import { MENU } from "../config/config";
import { connect } from "react-redux";
import { getWorkOut, getWeekWorkOut, getCurrentWorkOut } from "../store/actions/GetWorkOut";
import { getAllCardioHistory } from "../store/actions/Cardio"
import SliderProgramme from "./Slider/SliderProgramme";
import LoadingIcon from "./Loading/LoadingIcon";
import Completed from "./Completed";

class Programme extends Component {
  state = {
    isActive: MENU.PROGRAMME,
    programmeStatus: [],
    currentIndex: 0,
    initialIndex: 0,
    isExpired: null
  };
  onClick = async week => {
    await this.props.getWeekWorkOut(week);
    this.props.history.push("/Workout");
  };
  onUpgrade = () => {
    this.props.history.push("/Upgrade");
  }
  onChange = id => {
    this.setState({ currentIndex: id });
  };
  componentDidMount = async () => {
    await this.props.getWorkOut();
    //await this.props.getAllCardioHistory();

  };
  componentDidUpdate = async (prevProps, prevState) => {
    const { workOutList, cardioSession, cardioList } = this.props;
    // let listCardioHistory = allCardioHistory ? await allCardioHistory.reduce((r, a) => {
    //   r[a.week] = { totalCardio: a.session, id: a.id, duration: a.duration, cardioSession: a.cardioSession };
    //   return r;
    // }, {}) : null;
    if (prevProps.workOutList !== workOutList) {
      await this.props.getAllCardioHistory();
      const { allCardioHistory } = this.props;
      let listCardioHistory = allCardioHistory ? await allCardioHistory.reduce((r, a) => {
        r[a.week] = { totalCardio: a.session, id: a.id, duration: a.duration, cardioSession: a.cardioSession };
        return r;
      }, {}) : null;
      //console.log({ allCardioHistory, listCardioHistory })
      //console.log('allCardioHistory', allCardioHistory)
      if (listCardioHistory) {
        //console.log('allCardioHistory', allCardioHistory)
        const programmeStatus = Object.keys(workOutList)
          .map(key => workOutList[key])
          .reduce((acc, value) => {
            const keys = value.reduce((output, input) => {
              output.week = parseInt(input.week)
              return output
            }, {})
            //console.log('keys', keys)

            let totalCardio = 0, cardioSession = 0, cardioleft = 0;
            if (listCardioHistory[keys.week]) {
              totalCardio = listCardioHistory[keys.week].totalCardio
              cardioSession = listCardioHistory[keys.week].cardioSession ? listCardioHistory[keys.week].cardioSession : 0
            }
            //console.log('listCardio', listCardioHistory)
            const count = value.filter(item => item.historyId).length;
            const percent = ((count + cardioSession) / (value.length + totalCardio)) * 100;
            const enable =
              acc.length === 0 || acc[acc.length - 1].percent === 100;
            acc.push({ percent, enable });
            return acc;
          }, []);
        //console.log('programmeStatus', programmeStatus)
        for (let i = 0; i < programmeStatus.length; i++) {
          if (programmeStatus[i].percent !== 100) {
            this.setState({ initialIndex: i });
            break;
          }
        }
        this.setState({ programmeStatus: programmeStatus });
      }

    }
  }
  render() {
    const { workOutList, isLoading, isExpired, user, currentWorkOut } = this.props;
    const { workoutTypeName } = currentWorkOut
    if (isLoading) {
      return <LoadingIcon />;
    } else {
      return (
        <div className="fadeIn">
          <div className="page">
            <div className="page-content page-content-programme">
              <Completed
                value={
                  this.state.programmeStatus[this.state.currentIndex]
                    ? this.state.programmeStatus[this.state.currentIndex]
                      .percent
                    : 0
                }
              />
              <div className="curentfocus">
                <span>Current Workout Focus</span>
                <p>{workoutTypeName}</p>
              </div>

              <div className="width_programme_slider">
                <SliderProgramme
                  workOutList={workOutList}
                  onClick={this.onClick}
                  onUpgrade={this.onUpgrade}
                  onChange={this.onChange}
                  programmeStatus={this.state.programmeStatus ? this.state.programmeStatus : []}
                  index={this.state.initialIndex}
                  user={user}
                />
              </div>
            </div>
            <div className="page-footer">
              <div className="menu">
                <Menu isActive={this.state.isActive} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    ...state.workOutList,
    ...state.userInfo,
    ...state.profileReducer,
    ...state.cardioReducer
  };
};
export default connect(
  mapStateToProps,
  { getWorkOut, getWeekWorkOut, getAllCardioHistory, getCurrentWorkOut }
)(Programme);
