import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ModalAchievements from "./ModalAchievements ";
import { connect } from "react-redux";
import { DOMAIN_ICON } from "../../config/config";
export class ModalAchievementsList extends Component {
  state = {
    modalShowAchievements: false,
    item: null,
  };
  onClick = (item) => {
    this.setState({ item: item });
    this.setState({ modalShowAchievements: true });
  };
  modalCloseAchievements = () =>
    this.setState({ modalShowAchievements: false });
  render() {
    const {
      achievementsData,
      countedAchievement,
      dateAchievement,
    } = this.props;

    //console.log({ countedAchievement, dateAchievement });
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg">
          <div className="page pageFeedBack">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.props.onHide}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4 className="adward">
                <img src={require("../../images/larel.png")} alt="" />
                Achievements
                <img
                  className="rotate"
                  src={require("../../images/larel.png")}
                  alt=""
                />
              </h4>
              <Link to="#" className="icon" />
            </div>

            <div className="privacy achievementsList">
              <h4>Weekly Badges</h4>
              <ul>
                {achievementsData.map((item) => {
                  if (item.multiple) {
                    return (
                      <Fragment key={item.id}>
                        <li
                          className={`${
                            countedAchievement[item.code] &&
                            countedAchievement[item.code] > 0
                              ? ""
                              : "disable"
                          }`}
                        >
                          <Link
                            to="#"
                            onClick={
                              countedAchievement[item.code] > 0
                                ? () => this.onClick(item)
                                : null
                            }
                          >
                            <div className="image">
                              <img
                                src={`${DOMAIN_ICON}/${item.photo}`}
                                alt=""
                              />
                            </div>
                            <h5>{item.name}</h5>
                            <p>{item.message}</p>
                            {countedAchievement[item.code] > 0 ? (
                              <div className="score">
                                {countedAchievement[item.code]}
                              </div>
                            ) : (
                              <div />
                            )}
                          </Link>
                        </li>
                      </Fragment>
                    );
                  }
                })}
              </ul>

              <h4 className="mt-5">Programme Badges</h4>
              <ul>
                {achievementsData.map((item) => {
                  if (!item.multiple) {
                    return (
                      <Fragment key={item.id}>
                        <li
                          className={`${
                            countedAchievement[item.code] &&
                            countedAchievement[item.code] > 0
                              ? ""
                              : "disable"
                          }`}
                        >
                          <Link
                            to="#"
                            onClick={
                              countedAchievement[item.code] > 0
                                ? () => this.onClick(item)
                                : null
                            }
                          >
                            <div className="image">
                              <img
                                src={`${DOMAIN_ICON}/${item.photo}`}
                                alt=""
                              />
                            </div>
                            <h5>{item.name}</h5>
                            <p>{item.message}</p>
                            {/* {countedAchievement[item.code] > 0 ? (
                              <div className="score">
                                {countedAchievement[item.code]}
                              </div>
                            ) : (
                              <div></div>
                            )} */}
                          </Link>
                        </li>
                      </Fragment>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </Modal>
        <ModalAchievements
          show={this.state.modalShowAchievements}
          backdrop="static"
          item={this.state.item}
          dateAchievement={dateAchievement}
          countedAchievement={countedAchievement}
          onHide={this.modalCloseAchievements}
        />
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(ModalAchievementsList);
